import React, { forwardRef } from 'react';
import { ALIGNMENT, FLOW_DIRECTION } from '@pedidosya/order-status-components';
import BaseContainer from './BaseContainer';

const HorizontalStack = forwardRef((props, ref) => {
  return (
    <BaseContainer
      {...props}
      ref={ref}
      direction={FLOW_DIRECTION.ROW}
      hAlignment={ALIGNMENT.START}
      defaultPadding={[0]}
      spaceBetweenChild
    />
  );
});

HorizontalStack.displayName = 'HorizontalStack';

export default HorizontalStack;
