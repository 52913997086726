import ROUTES from '@app/constants/routes';
import { goToUrl as redirectToUrl } from '@app/utils/url';
import { getDefaultPlatformEventContext } from './utils';

class WebEvents {
  context = {};

  setContext(contextData = getDefaultPlatformEventContext()) {
    this.context = contextData;
  }

  closePage() {
    redirectToUrl(ROUTES.HOME);
  }

  goToUrl(url, eventName) {
    redirectToUrl(url);
  }
}

export default WebEvents;
