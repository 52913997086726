export const ANDROID = 'Android';
export const IOS = 'iOS';
export const UNKNOWN = 'unknown';

export function isIOS(os) {
  return os === IOS;
}

export function isAndroid(os) {
  return os === ANDROID;
}

export function isMobileOS(OS) {
  if (/android|huawei|iPad|iPhone|iPod/i.test(OS)) {
    return true;
  }
  return false;
}

function getMobileOS(userAgent) {
  if (/android|huawei/i.test(userAgent)) {
    return ANDROID;
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return IOS;
  }

  return UNKNOWN;
}

export function getMobileOperatingSystem() {
  if (typeof window === 'undefined') {
    return UNKNOWN;
  }
  return getMobileOS(navigator?.userAgent || navigator?.vendor);
}

export function getServerMobileOperatingSystem(req) {
  return getMobileOS(req.get('User-Agent'));
}
