import React, { useEffect } from 'react';
import trackerInterceptor from './utils/tracker-interceptor';
import AlchemistComponentData from './utils/context-data/alchemist-component-data';
import { TRACKING_TRIGGERS } from '@app/tracking';
import { getLogger } from '@app/logger';
import { METRICS, sendMetricEvent } from '@app/services/metrics-service';
import AlchemistErrorBoundary from './AlchemistErrorBoundary';

const noopTracker = trackerInterceptor({
  track: () => {
    console.warn('using noop tracker');
  },
});
const defaultValues = {
  Component: null,
  fallback: () => {},
  globals: {},
  ssr: false,
  publicEnv: {},
  alchemistProps: {},
  containerStyle: {},
  componentProps: {},
  children: null,
};

const AlchemistComponent = ({
  Component,
  fallback,
  globals,
  publicEnv,
  alchemistProps,
  containerStyle,
  componentProps,
  children,
} = defaultValues) => {
  const componentName = Component?.displayName || Component?.name;
  const mockedPublicEnv = { ...publicEnv };
  // overrides alchemist children if passed
  let _alchemistProps = {
    ...alchemistProps,
    children: alchemistProps?.children || children || null,
  };
  let tracker = publicEnv?.externalServices?.tracker || noopTracker;
  const componentData = new AlchemistComponentData(_alchemistProps, tracker);

  if (!mockedPublicEnv?.externalServices) {
    mockedPublicEnv.externalServices = {
      fwf: {},
      tracker,
    };
  }

  let _componentProps = {
    ..._alchemistProps,
    globals,
    componentData,
    tracker,
    publicEnv,
    ...componentProps,
  };

  useEffect(() => {
    componentData.getTracking().markTriggerToBeTrackedOnce(TRACKING_TRIGGERS.LOADED);
  }, []);

  const fallBackRenderer =
    typeof fallback === 'function' ? () => fallback(_componentProps) : () => null;

  const WrappedComponent = (props) => {
    try {
      if (typeof Component === 'object') {
        return <Component {...props} />;
      }
      const _Comp = Component(props);
      return _Comp;
    } catch (e) {
      getLogger().warn(
        ` AlchemistComponent - error rendering '${props?.componentId}' component: ${e.message}. Using fallback instead`,
      );
      sendMetricEvent(METRICS.FALLBACK_COMPONENT_SHOWN, true);
      return props.fallback(props);
    }
  };

  const id = `${alchemistProps?.id || 'unknownId'}-${componentName || 'unknownName'}`;
  return (
    <AlchemistErrorBoundary id={id} fallback={fallback} style={containerStyle}>
      <WrappedComponent componentId={id} fallback={fallBackRenderer} {..._componentProps} />
    </AlchemistErrorBoundary>
  );
};

export default AlchemistComponent;
