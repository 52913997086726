import { useState } from 'react';
import PropTypes from 'prop-types';
import { initSSRMicrositeData } from '@app/components/Microsite/utils/microsite-data';
import initLogger from '@app/components/Microsite/utils/microsite-data/init-logger';
import MICROSITE_STATE from './components/Microsite/constants/microsite-state';
import { setupSentry } from './utils/sentry';

const getErrorState = (serverException, dataResponse) => {
  if (serverException) {
    const { httpCode } = serverException;
    if (httpCode === 401 || httpCode === 403) {
      return MICROSITE_STATE.UNAUTHORIZED;
    } else if (httpCode === 404) {
      return MICROSITE_STATE.DATA_NOT_FOUND;
    } else {
      return MICROSITE_STATE.ERROR;
    }
  } else if (dataResponse?.status) {
    return dataResponse?.error;
  }
  return null;
};

const RootSSR = ({ children, serverSideProps }) => {
  const dataResponse = initSSRMicrositeData(serverSideProps);
  const [data] = useState(dataResponse.data);
  const serverException = serverSideProps?.serverException;

  const [error] = useState(getErrorState(serverException, dataResponse));

  initLogger(data?.context);
  setupSentry(data?.context.environment);
  return children({ data, error, loading: false });
};

RootSSR.propTypes = {
  serverSideProps: PropTypes.object.isRequired,
  children: PropTypes.func.isRequired,
};

export default RootSSR;
