import React from 'react';
import PropTypes from 'prop-types';
import {
  ScreenSizeProvider,
  ModalProvider,
  DEVICE,
  useSSR,
} from '@pedidosya/order-status-components';
import { PublicEnvProvider } from '@app/providers/PublicEnv';
import ExternalServiceProvider from '@app/providers/ExternalServiceProvider';

function RootProviders({ children, publicEnv }) {
  const { ssr } = useSSR();
  const isMobile = publicEnv?.context?.isNative;

  return (
    <PublicEnvProvider debugMode={!publicEnv.debugMode} ssr={ssr} context={publicEnv?.context}>
      <ExternalServiceProvider externalServices={publicEnv?.externalServices}>
        <ScreenSizeProvider defaultDevice={isMobile ? DEVICE.MOBILE_PHONE : DEVICE.DESKTOP}>
          <ModalProvider>{children}</ModalProvider>
        </ScreenSizeProvider>
      </ExternalServiceProvider>
    </PublicEnvProvider>
  );
}

RootProviders.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RootProviders;
