import React from 'react';
import AlchemistContentComponent from '@app/alchemist/AlchemistContentComponent';
import { ClickableElement } from '@pedidosya/order-status-components';
import { ACTION_TYPE } from '@app/alchemist/utils/actions';
import crossPlatformEvents from '@app/services/event/platform/cross-platform-events';
import InfoArrowComponent from './InfoArrowComponent';
import PaymentInfoComponent from './PaymentInfoComponent';
import RowContainerComponent from './RowContainerComponent';
import { getLogger } from '@app/logger';
import { goToTargetUrl } from '@app/utils/url';

function SimpleDetailComponent(props) {
  const navigateAction = props.componentData.getAction(ACTION_TYPE.NAVIGATE);

  const onClick = () => {
    getLogger().warn(`From SimpleDetailComponent ${navigateAction.targetUrl}`);
    goToTargetUrl(navigateAction, crossPlatformEvents.goToUrl);
  };

  return (
    <ClickableElement data-testid="clickable-detail" onClick={onClick} enabled overShadow={false}>
      <AlchemistContentComponent
        Component={RowContainerComponent}
        parentProps={props}
        childNode={
          <InfoArrowComponent {...props}>
            <AlchemistContentComponent Component={PaymentInfoComponent} parentProps={props} />
          </InfoArrowComponent>
        }
      />
    </ClickableElement>
  );
}

export default SimpleDetailComponent;
