import { isEmpty } from './string';
import { getMobileOperatingSystem, isAndroid, isIOS } from './userAgent/getMobileOS';

export const DEVICE_STRING = {
  ANDROID: 'android',
  IOS: 'ios',
  HUAWEI: 'huawei',
};

export const isAndroidPlatform = (platformKey) => platformKey.includes(DEVICE_STRING.ANDROID);
export const isIOSPlatform = (platformKey) => platformKey.includes(DEVICE_STRING.IOS);
export const isHuaweiPlatform = (platformKey) => platformKey.includes(DEVICE_STRING.HUAWEI);

export const isWebviewPlatform = (platformKey) => {
  if (isEmpty(platformKey)) {
    // if there is no platofrm provided, then it will
    // infer if it is webview using the agent.
    const deviceOS = getMobileOperatingSystem();
    return isAndroid(deviceOS) || isIOS(deviceOS);
  }
  return (
    isAndroidPlatform(platformKey) || isIOSPlatform(platformKey) || isHuaweiPlatform(platformKey)
  );
};
