import isString from '@commons/utils/string/isString';

const isBoolean = (x) => typeof x === 'boolean';

const isObject = (obj) => typeof obj === 'object';

export default function isVariation(flagValue) {
  const variation = flagValue?.variation || false;

  if (isBoolean(variation)) {
    return variation;
  }

  if (isString(variation)) {
    return variation.includes('Variation');
  }

  if (isObject(variation)) {
    return variation;
  }

  return false;
}
