import React from 'react';
import CourierPillButton from './CourierPillButton';
import DefaultPillButton from './DefaultPillButton';

const PillButton = (props) => {
  if (props?.id?.startsWith('courier')) {
    return <CourierPillButton {...props} />;
  }
  return <DefaultPillButton {...props} />;
};

PillButton.propTypes = {};

export default PillButton;
