import querystring from 'query-string';
import { QUERY_PARAM } from '@commons/constants';
import { ignore } from '@app/utils/object';

import { isEmpty } from '@app/utils/string';
import { callInBrowserContext } from '@pedidosya/order-status-components';

/**
 * Función para usar en componentes Alchemist para ir a una URL
 * Como no tenemos certeza de cuales componentes vienen con `target_url` o `targetUrl`, chequeamos ambos y tomamos uno de los dos para ser resilientes.
 * @param {Object | undefined} action
 * @param {Function?} handler
 */
export function goToTargetUrl(action, handler = goToUrl) {
  const targetUrl = action?.target_url || action?.targetUrl;

  if (targetUrl) {
    handler(targetUrl);
  }
}

export const goToUrl = (url) => {
  if (!isEmpty(url)) {
    callInBrowserContext(({ ssrWindow }) => (ssrWindow.location.href = url));
  }
};

export function generateURL({ pathname, search }) {
  let query = search || '';
  if (query && !query.startsWith('?')) {
    query = `?${query}`;
  }
  return `${pathname}${query}`;
}

export function reloadPage() {
  callInBrowserContext(({ ssrWindow }) => ssrWindow.location.reload());
}

/**
 * @param {string} route full route ex: /restaurantes/montevideo?p=123
 * @param {string} params
 */
export function removeQueryParamsFromURL(route, ...params) {
  const [pathname, query] = route.split('?');
  let search = '';

  if (query && params.length > 0) {
    const searchObj = querystring.parse(`?${query}`);

    params.forEach((p) => {
      delete searchObj[p];
    });

    search = querystring.stringify(searchObj);
  }

  return generateURL({ pathname, search });
}
