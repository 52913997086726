import React, { useRef } from 'react';
import {
  ALIGNMENT,
  CollapsibleContainer,
  CollapsibleContent,
  Container,
  Text,
} from '@pedidosya/order-status-components';
import ReactiveEvent from '@app/services/event/reactive-events';
import IconButton from '../IconButton';

const CollapsibleVertical = (props) => {
  const contentRef = useRef(null);
  const titleProps = props.componentData.getTextProps('title_label');
  const collapseState = props.componentData.getContentProp('expand_trigger') ? true : false;
  const styles = props.componentData.getStyles();
  const contentPadding = styles.getPadding([0, 20, 4, 20]);

  return (
    <CollapsibleContainer
      style={{
        position: 'relative',
        paddingBottom: '10px',
      }}
      initialState={collapseState}
    >
      {({ toggleState, state }) => {
        const toggle = () => {
          if (contentRef?.current) {
            ReactiveEvent.send(props?.componentData.getId(), {
              open: !state.open,
              contentHeight: contentRef?.current.offsetHeight,
              height: contentRef?.current.offsetHeight,
            });

            toggleState();
          } else {
            toggleState();
          }
        };

        const containerMargin = [0, 4, 0, 0];
        return (
          <>
            <Container
              bgColor="transparent"
              expanded
              vAlignment={ALIGNMENT.CENTER}
              fitContent
              padding={[0, 16, 6, 16]}
            >
              {titleProps?.text && (
                <Container fillSpace margin={containerMargin}>
                  <Text {...titleProps} expanded>
                    {titleProps?.text}
                  </Text>
                </Container>
              )}
              {state.open ? (
                <IconButton {...props} contentPropName="expand_icon_button" onClick={toggle} />
              ) : (
                <IconButton {...props} contentPropName="collapse_icon_button" onClick={toggle} />
              )}
            </Container>
            <CollapsibleContent id="floating-eta-issues">
              <Container padding={contentPadding} ref={contentRef}>
                {props.children}
              </Container>
            </CollapsibleContent>
          </>
        );
      }}
    </CollapsibleContainer>
  );
};

export default CollapsibleVertical;
