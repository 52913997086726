import React from 'react';
import PropTypes from 'prop-types';
import getPlatformName from '@app/utils/platform/getPlaformName';
import { PLATFORM_KEY } from '@commons/constants/platforms';
import AlchemistContext from '@app/alchemist/AlchemistContext';
import getRegistry from './alchemist-component-registry';
import OrderState from '@app/components/ui/organisms/OrderState/OrderState';
import { TagV2, useScreenSize } from '@pedidosya/order-status-components';

function OrderStatusViewAlchemist({
  alchemistContext,
  countryCode,
  countryId,
  onHistoryBack,
  publicEnv,
  tracker,
  onDetailClicked,
  onPhoneClicked,
  hasNotch,
  viewType,
  isSSR,
}) {
  const { isDesktop, isTablet, isMobile } = useScreenSize();
  const platformName = getPlatformName(PLATFORM_KEY.PEDIDOS_YA);

  const globalAlchemistContext = () => ({
    onPhoneClicked,
    onDetailClicked,
    platformName,
    alchemistContext,
    lottieVersion: alchemistContext.isFloatingETAActive() ? 'v2' : 'v1',
    tracker: tracker,
    isNative: publicEnv?.context?.isNative,
    isDesktop: isDesktop(),
    isTablet: isTablet(),
    isMobile: isMobile(),
    platformKey: publicEnv?.context?.app?.platformKey,
    countryId: publicEnv?.context?.app?.country?.id,
  });

  const registry = getRegistry({ publicEnv, globals: globalAlchemistContext() });

  return (
    <>
      <AlchemistContext registry={registry} tracker={tracker} globals={globalAlchemistContext}>
        <OrderState
          alchemistContext={alchemistContext}
          isNative={publicEnv?.context?.isNative}
          platformName={platformName}
          countryCode={countryCode}
          countryId={countryId}
          onHistoryBack={onHistoryBack}
          showMap={alchemistContext.isMapActive()}
          hasNotch={hasNotch}
          viewType={viewType}
          isSSR={isSSR}
        />
      </AlchemistContext>
    </>
  );
}

OrderStatusViewAlchemist.propTypes = {
  alchemistContext: PropTypes.object.isRequired,
  countryId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onHistoryBack: PropTypes.func.isRequired,
  showAlchemistVersion: PropTypes.bool,
  viewType: PropTypes.string,
  isSSR: PropTypes.bool,
};

OrderStatusViewAlchemist.defaultProps = {
  showAlchemistVersion: false,
};

export default React.memo(OrderStatusViewAlchemist);
