import React from 'react';
import { Container, FLOW_DIRECTION, Skeleton } from '@pedidosya/order-status-components';
const ProgressBarLottiePlaceholder = () => {
  const timeLineMargin = [5, 0];
  const timeLineItemsMargin = [0, 8];
  const width = '33%';
  const height = '6px';
  return (
    <Container direction={FLOW_DIRECTION.ROW} margin={timeLineMargin} fitContent expanded>
      <Skeleton height={height} width={width} />
      <Container style={{ width }} margin={timeLineItemsMargin}>
        <Skeleton height={height} width="100%" />
      </Container>
      <Skeleton height={height} width={width} />
    </Container>
  );
};

export default ProgressBarLottiePlaceholder;
