import isString from '@commons/utils/string/isString';

export const INTERNAL_ROUTES = {
  DEFAULT: '/order-state',
};

export const EXTERNAL_ROUTES = {
  DESKTOP_LOGIN: '/login',
  WEBVIEW_LOGIN: 'https://www.pedidosya.com/user-account/integrated-register-login',
  DESKTOP_OD_MICROSITE: '/order-details?orderId={0}&origin=order_status',
  WEBVIEW_OD_MICROSITE: 'pedidosya://os/orderDetails/orderId={0}',
};

// Parent routes that user must be logged in to access them.
const PROTECTED_ROUTES = [];

export function isRouteProtected(route) {
  return isString(route) ? PROTECTED_ROUTES.some((r) => route.startsWith(r)) : false;
}

// Only backend
export function isDefault(route) {
  return route === INTERNAL_ROUTES.DEFAULT;
}
