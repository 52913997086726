import React, { useRef } from 'react';
import {
  ALIGNMENT,
  Button,
  CollapsibleContainer,
  CollapsibleContent,
  Container,
  Image,
  Text,
  ChevronDown,
  ChevronUp,
} from '@pedidosya/order-status-components';
import ReactiveEvent from '@app/services/event/reactive-events';

const CollapsibleVertical = (props) => {
  const contentRef = useRef(null);
  const titleProps = props.componentData.getTextProps('title');
  const imgProps = {
    height: 6.5,
    width: 12,
  };
  const callapsedData = props.componentData.getImageData('collapsed_image');
  const callapsedImage = callapsedData?.hasImages() ? callapsedData.getFirstImage() : null;
  const expandedData = props.componentData.getImageData('expanded_image');
  const expandedImage = expandedData?.hasImages() ? expandedData.getFirstImage() : null;
  const collapseState = props.componentData.getContentProp('expand_trigger') ? true : false;

  const imageStyles = { margin: [0, 0, 0, 4] };
  return (
    <>
      <CollapsibleContainer
        style={{
          position: 'relative',
          paddingBottom: '14px',
        }}
        initialState={collapseState}
      >
        {({ toggleState, state }) => {
          const toggle = () => {
            if (contentRef?.current) {
              ReactiveEvent.send(props?.componentData.getId(), {
                open: !state.open,
                contentHeight: contentRef?.current.offsetHeight,
                height: contentRef?.current.offsetHeight,
              });

              toggleState();
            } else {
              toggleState();
            }
          };

          return (
            <>
              <Button
                bgColor="transparent"
                onClick={toggle}
                expanded
                vAlignment={ALIGNMENT.CENTER}
                fitContent
                padding={[0, 20]}
              >
                {titleProps?.text && (
                  <Container fillSpace>
                    <Text {...titleProps} expanded>
                      {titleProps?.text}
                    </Text>
                  </Container>
                )}
                <Image
                  src={state.open ? expandedImage.url : callapsedImage.url}
                  width={imgProps.width}
                  style={{
                    maxHeight: 12,
                  }}
                  fallbackRenderer={() =>
                    state.open ? (
                      <ChevronDown style={{ width: imgProps.width, height: 12 }} />
                    ) : (
                      <ChevronUp style={{ width: imgProps.width, height: 12 }} />
                    )
                  }
                  margin={imageStyles.margin}
                />
              </Button>
              <CollapsibleContent id="floating-eta-issues">
                <Container style={{ display: 'contents' }} ref={contentRef}>
                  {props.componentData.getChildren()}
                </Container>
              </CollapsibleContent>
            </>
          );
        }}
      </CollapsibleContainer>
    </>
  );
};

export default CollapsibleVertical;
