import { tipsURL } from '@commons/services/urls/serverServicesURLs';
import format from '@commons/utils/string/format';
import getInstance from '@app/services/axios';
import { authHandler } from './utils';

function isValidStatus(status) {
  return status >= 200 && status < 300; // default
}
export async function createTips({ orderId, amount, configurationId, countryId, headers = {} }) {
  const axios = await getInstance();
  const url = format(tipsURL, countryId, orderId);
  return axios
    .post(url, {
      headers,
      data: {
        orderId,
        amount,
        configurationId,
      },
    })
    .then(authHandler)
    .then((response) => {
      if (isValidStatus(response.status)) {
        return response;
      }
      throw new Error(
        `${response?.statusText || 'Error'}: ${response?.data?.message || 'Unknown error'}`,
      );
    });
}

export async function updateTips({ orderId, amount, configurationId, countryId, headers = {} }) {
  const axios = await getInstance();
  const url = format(tipsURL, countryId, orderId);
  return axios
    .put(url, {
      headers,
      data: {
        orderId,
        amount,
        configurationId,
      },
    })
    .then(authHandler)
    .then((response) => {
      if (isValidStatus(response.status)) {
        return response;
      }
      throw new Error(
        `${response?.statusText || 'Error'}: ${response?.data?.message || 'Unknown error'}`,
      );
    });
}
