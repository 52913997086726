import {
  ALIGNMENT,
  Container,
  FLOW_DIRECTION,
  TagV2,
  Text,
} from '@pedidosya/order-status-components';
import React from 'react';
import Lottie from '../../Lottie/Lottie';
import ProgressBarLottiePlaceholder from '../../Lottie/placeholder/ProgressBarLottiePlaceholder';
import RoundedIconPlaceholder from '../../Lottie/placeholder/RoundedIconPlaceholder';
import AlchemistComponent from '@app/alchemist/AlchemistComponent';

const FloatingProgressBar = (props) => {
  const textProps = {
    title: props.componentData.getTextProps('title_label'),
    tag: props.componentData.getContentProp('message_tag'),
  };

  const etaProps = props.componentData.getTextProps('estimated_time_label');
  const iconLottieProps = props.componentData.getLottieData('icon_animation_source');
  const barLottieProps = props.componentData.getLottieData('bar_animation_source');

  const constainerStyles = {
    width: '100%',
  };

  return (
    <Container
      direction={FLOW_DIRECTION.COLUMN}
      hAlignment={ALIGNMENT.START}
      padding={props.componentData.getStyles().getPadding()}
      expanded
      style={constainerStyles}
    >
      <Container
        direction={FLOW_DIRECTION.ROW}
        hAlignment={ALIGNMENT.CENTER}
        vAlignment={ALIGNMENT.BASE}
        fitContent
      >
        <Text {...textProps.title} textAlign={ALIGNMENT.CENTER} padding={[0, 4, 0, 0]}>
          {textProps.title?.text}
        </Text>
        {textProps.tag && (
          <TagV2
            label={textProps.tag?.text}
            category={textProps.tag?.category}
            size={textProps.tag?.size}
            borderRadius={textProps.tag?.border_radius}
            icon={textProps.tag?.icon}
          />
        )}
      </Container>

      <Container direction={FLOW_DIRECTION.ROW} vAlignment={ALIGNMENT.CENTER} expanded>
        <Container direction={FLOW_DIRECTION.ROW} fillSpace>
          <Container
            direction={FLOW_DIRECTION.ROW}
            hAlignment={ALIGNMENT.CENTER}
            vAlignment={ALIGNMENT.BASE}
            fitContent
          >
            <Text {...etaProps} textAlign={ALIGNMENT.CENTER}>
              {etaProps?.text}
            </Text>
          </Container>
        </Container>
        <Container direction={FLOW_DIRECTION.ROW}>
          <AlchemistComponent
            publicEnv={props?.publicEnv}
            globals={props?.globals}
            ssr={props?.publicEnv?.ssr}
            Component={Lottie}
            alchemistProps={iconLottieProps}
            style={{
              position: 'absolute',
              top: '-7px',
            }}
            componentProps={{
              width: 48,
              height: 48,
              customStyles: {
                position: 'absolute',
                top: '-7px',
              },
              version: props?.globals?.lottieVersion,
              Placeholder: RoundedIconPlaceholder,
            }}
          />
        </Container>
      </Container>
      <Container direction={FLOW_DIRECTION.ROW} expanded style={{ height: '16px' }}>
        <AlchemistComponent
          publicEnv={props?.publicEnv}
          globals={props?.globals}
          ssr={props?.publicEnv?.ssr}
          Component={Lottie}
          alchemistProps={barLottieProps}
          style={{
            position: 'absolute',
            top: '-7px',
          }}
          componentProps={{
            width: '100%',
            customStyles: {
              position: 'absolute',
              top: '-7px',
            },
            version: props?.globals?.lottieVersion,
            Placeholder: ProgressBarLottiePlaceholder,
          }}
          containerStyle={{
            width: '100%',
          }}
        />
      </Container>
    </Container>
  );
};

export default FloatingProgressBar;
