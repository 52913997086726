function AxiosHTTPAdapter() {
  const getResponseHttpCode = (response) => {
    return response?.response?.status || response?.status;
  };

  const getResponseData = (response) => {
    return response?.data;
  };
  const getResponseError = (error) => {
    if (error?.response && error.response?.data) {
      return error.response.data;
    } else if (error?.request) {
      return error.request;
    } else {
      return error?.message;
    }
  };

  return {
    getResponseHttpCode,
    getResponseData,
    getResponseError,
  };
}

export default AxiosHTTPAdapter;
