import useIsFirstRender from '@app/hooks/useIsFirstRender';
import { Spinner } from '@pedidosya/order-status-components';
import React from 'react';

const DeferredComponent = React.lazy(() => import('./MicrositeErrorOrderNotFound'));
const MicrositeErrorNotFoundLoader = (props) => {
  const { isFirstRender } = useIsFirstRender();
  return !isFirstRender ? (
    <React.Suspense fallback={<Spinner />}>
      <DeferredComponent {...props} />
    </React.Suspense>
  ) : (
    <Spinner />
  );
};

export default MicrositeErrorNotFoundLoader;
