import React from 'react';
import PropTypes from 'prop-types';
import {
  Text,
  Container,
  Tag,
  ALIGNMENT,
  FLOW_DIRECTION,
} from '@pedidosya/order-status-components';

function getTagData(alchemistProps) {
  const tagData = alchemistProps.componentData.getContentProp('tag');
  if (!tagData) {
    return null;
  }
  let tag = alchemistProps.componentData.getTextPropsFrom(tagData, 'text');
  tag.margin = [0, 0, 0, 8];
  tag.bgColor = tagData?.background_color;

  return tag;
}
function CenteredLabelTag(props) {
  const textElement = props.componentData.getTextProps('text');

  if (!textElement) {
    return null;
  }

  const tag = getTagData(props);
  const padding = props.componentData.getStyles().getPadding();

  return (
    <Container
      direction={FLOW_DIRECTION.ROW}
      hAlignment={ALIGNMENT.CENTER}
      vAlignment={ALIGNMENT.BASE}
      padding={padding}
      fitContent
    >
      <Text {...textElement} textAlign={ALIGNMENT.CENTER}>
        {textElement?.text}
      </Text>
      {tag && (
        <Tag
          margin={tag?.margin}
          font={tag?.font}
          fontVariation={tag?.fontVariation}
          bgColor={tag?.bgColor}
        >
          {tag?.text}
        </Tag>
      )}
    </Container>
  );
}

CenteredLabelTag.propTypes = {
  text: PropTypes.object.isRequired,
};

export default CenteredLabelTag;
