import React from 'react';
import { ALIGNMENT, Container, ContainerV2 } from '@pedidosya/order-status-components';
import Divider from '../Divider';

function RowContainerComponent(props) {
  const styles = props.componentData.getStyles();
  const mainContainerPadding = styles.getPadding([16, 16]);
  const dividers = styles.getRawProp('dividers');

  return (
    <>
      {dividers.top && <Divider {...props} />}
      <Container
        id={props?.containerId}
        data-testid={props?.testId}
        padding={mainContainerPadding}
        vAlignment={ALIGNMENT.CENTER}
        expanded
      >
        <ContainerV2
          vAlignment={ALIGNMENT.CENTER}
          paddingTokens={['spacing-component-large', 'spacing-component-none']}
          expanded
        >
          {props?.children}
        </ContainerV2>
      </Container>
      {dividers.bottom && <Divider {...props} />}
    </>
  );
}

RowContainerComponent.defaultProps = {
  testId: undefined,
  containerId: undefined,
};

export default RowContainerComponent;
