import getClientQueryParamsObject from '@app/utils/url/parse-query-params';
import allowedMicrositeParamList from '@commons/constants/allowedMicrositeParamList';
import axios from 'axios';

let instance = null;

export function initialize(props) {
  const config = {
    validateStatus: function (status) {
      return status < 500;
    },
    ...props,
  };

  const params = getClientQueryParamsObject(allowedMicrositeParamList);

  if (params?.sharedId) {
    axios.defaults.headers['x-order-shared-id'] = params.sharedId;
  }
  if (params?.c) {
    axios.defaults.headers['x-order-country'] = params.c;
  }
  if (params?.code) {
    axios.defaults.headers['x-order-code'] = params.code;
  }

  instance = axios.create(config);

  return instance;
}

const getInstance = (props) => instance || initialize(props);

export default getInstance;
