import React from 'react';
import PropTypes from 'prop-types';
import { ThumbnailImageV2 } from '@pedidosya/order-status-components';
const ThumbnailImage = (props) => {
  const elementProps = props.componentData.getContentProp(props?.contentPropName, null);
  if (!elementProps) {
    return null;
  }
  return (
    <>
      <ThumbnailImageV2
        alt={props?.alt}
        src={elementProps?.image?.url}
        size={elementProps?.size}
        stroke={elementProps?.useStroke}
        shape={elementProps?.shape}
      />
    </>
  );
};

ThumbnailImage.propTypes = {
  contentPropName: PropTypes.string.isRequired,
  alt: PropTypes.string,
};

ThumbnailImage.defaultProps = {
  alt: 'pedidosYa',
};

export default ThumbnailImage;
