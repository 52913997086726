import { ALIGNMENT, Container, FLOW_DIRECTION } from '@pedidosya/order-status-components';
import React from 'react';
import CenteredLabelTag from '../CenteredLabelTag/CenteredLabelTag';
import Lottie from '../Lottie/Lottie';
import ProgressBarLottiePlaceholder from '../Lottie/placeholder/ProgressBarLottiePlaceholder';
import RoundedIconPlaceholder from '../Lottie/placeholder/RoundedIconPlaceholder';
import AlchemistComponent from '@app/alchemist/AlchemistComponent';
import AlchemistComponentData from '@app/alchemist/utils/context-data/alchemist-component-data';

const FloatingProgressBar = (props) => {
  const titleProps = {
    text: props.componentData.getContentProp('title'),
    tag: props.componentData.getContentProp('tag'),
  };
  const etaProps = { text: props.componentData.getContentProp('estimated_time') };
  const iconLottieProps = props.componentData.getLottieData('icon_animation_source');
  const barLottieProps = props.componentData.getLottieData('bar_animation_source');

  const constainerStyles = {
    width: '100%',
  };

  return (
    <Container
      direction={FLOW_DIRECTION.COLUMN}
      hAlignment={ALIGNMENT.START}
      padding={props.componentData.getStyles().getPadding()}
      expanded
      style={constainerStyles}
    >
      <AlchemistComponent
        publicEnv={props?.publicEnv}
        globals={props?.globals}
        ssr={props?.publicEnv?.ssr}
        Component={CenteredLabelTag}
        alchemistProps={titleProps}
      />
      <Container direction={FLOW_DIRECTION.ROW} vAlignment={ALIGNMENT.CENTER} expanded>
        <Container direction={FLOW_DIRECTION.ROW} fillSpace>
          <AlchemistComponent
            publicEnv={props?.publicEnv}
            globals={props?.globals}
            ssr={props?.publicEnv?.ssr}
            Component={CenteredLabelTag}
            alchemistProps={etaProps}
          />
        </Container>
        <Container direction={FLOW_DIRECTION.ROW}>
          <AlchemistComponent
            publicEnv={props?.publicEnv}
            globals={props?.globals}
            ssr={props?.publicEnv?.ssr}
            Component={Lottie}
            alchemistProps={iconLottieProps}
            style={{
              position: 'absolute',
              top: '-7px',
            }}
            componentProps={{
              width: 48,
              height: 48,
              customStyles: {
                position: 'absolute',
                top: '-7px',
              },
              version: props?.globals?.lottieVersion,
              Placeholder: RoundedIconPlaceholder,
            }}
          />
        </Container>
      </Container>
      <Container direction={FLOW_DIRECTION.ROW} expanded style={{ height: '16px' }}>
        <AlchemistComponent
          publicEnv={props?.publicEnv}
          globals={props?.globals}
          ssr={props?.publicEnv?.ssr}
          Component={Lottie}
          alchemistProps={barLottieProps}
          style={{
            position: 'absolute',
            top: '-7px',
          }}
          componentProps={{
            width: '100%',
            customStyles: {
              position: 'absolute',
              top: '-7px',
            },
            version: props?.globals?.lottieVersion,
            Placeholder: ProgressBarLottiePlaceholder,
          }}
          containerStyle={{
            width: '100%',
          }}
        />
      </Container>
    </Container>
  );
};

export default FloatingProgressBar;
