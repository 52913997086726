import React from 'react';
import { Transmute } from '@pedidosya/web-alchemist';

const Renderer = ({ component, fallbackComponent = null }) => {
  if (!component) {
    return fallbackComponent || null;
  }

  return <Transmute json={component} />;
};

export default Renderer;
