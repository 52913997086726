import { getAlchemistOrderURL } from '@commons/services/urls/serverServicesURLs';
import format from '@commons/utils/string/format';
import getInstance from '@app/services/axios';
import { authHandler } from './utils';

export async function fetchAlchemistOrder({ orderId, countryId, headers = {} }) {
  const axios = await getInstance();
  const url = format(getAlchemistOrderURL, countryId, orderId);
  return axios
    .get(url, {
      headers,
    })
    .then(authHandler);
}
