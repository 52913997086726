import { createLoggerInstance } from '@app/logger';

function initLogger(context) {
  let loggerContext = {};
  if (context) {
    loggerContext = {
      environment: context?.environment,
      tokenType: context?.app?.auth?.tokenType,
      countryId: context?.app?.country?.id,
      countryShortName: context?.app?.country?.shortName,
      origin: context?.app?.origin,
      appVersion: context?.app?.appVersion,
      platformKey: context?.app?.platformKey,
      locale: context?.locale,
      device: context?.device,
    };
  }

  // Client logger initialization
  if (typeof document !== 'undefined') {
    createLoggerInstance(loggerContext);
  }
}

export default initLogger;
