import React from 'react';
import riderAvatar from '@app/assets/icons/order-status/rider/riderAvatar.png';
import phone from '@app/assets/icons/order-status/phone.svg';
import peyaShipping from '@app/assets/icons/order-status/peya_envios.png';
import itemPlaceholder from '@app/assets/icons/order-status/item-placeholder.svg';
import businessPlaceholder from '@app/assets/icons/order-status/business.svg';
import address from '@app/assets/icons/order-status/address.png';
import bannerFallback from '@app/assets/icons/order-status/banner-placeholder.svg';
import locationFallback from '@app/assets/icons/order-status/location-icon.svg';
import pinModalFallback from '@app/assets/icons/order-status/pin-modal.svg';
import isObject from '@commons/utils/object/isObject';
import {
  Container,
  Image,
  ArrowRight,
  Information,
  HelpSupport,
  ChevronDown,
  ChevronUp,
} from '@pedidosya/order-status-components';
import styled from 'styled-components';
import { getContentProp } from './content';
import { getTextElementPropsByKey } from './typography';
import { getLogger } from '@app/logger';

const DEFAULT_IMAGE_SIZE = 24;
const DEFAULT_IMAGE_DPI = 2;
const DEFAULT_PLACEHOLDER =
  'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzYiIGhlaWdodD0iMzYiIHZpZXdCb3g9IjAgMCAzNiAzNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KCTxyZWN0IHdpZHRoPSIzNiIgaGVpZ2h0PSIzNiIgcng9IjQiIGZpbGw9IiNGOUY2RjQiIC8+Cgk8cGF0aCBkPSJNNiAxSDMwVi0xSDZWMVpNMzUgNlYzMEgzN1Y2SDM1Wk0zMCAzNUg2VjM3SDMwVjM1Wk0xIDMwVjZILTFWMzBIMVpNNiAzNUMzLjIzODU4IDM1IDEgMzIuNzYxNCAxIDMwSC0xQy0xIDMzLjg2NiAyLjEzNDAxIDM3IDYgMzdWMzVaTTM1IDMwQzM1IDMyLjc2MTQgMzIuNzYxNCAzNSAzMCAzNVYzN0MzMy44NjYgMzcgMzcgMzMuODY2IDM3IDMwSDM1Wk0zMCAxQzMyLjc2MTQgMSAzNSAzLjIzODU4IDM1IDZIMzdDMzcgMi4xMzQwMSAzMy44NjYgLTEgMzAgLTFWMVpNNiAtMUMyLjEzNDAxIC0xIC0xIDIuMTM0MDEgLTEgNkgxQzEgMy4yMzg1OCAzLjIzODU4IDEgNiAxVi0xWiIgZmlsbD0iI0VGRURGMCIgLz4KCTxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMjAuNTYyNCAxMS4wMDc0TDE5LjgxNjQgMTFIMTEuMjQ1MkMxMS4xMDk4IDExIDExIDExLjEwOTEgMTEgMTEuMjQzOFYxMi41OTQyQzExIDE0LjA3NTMgMTIuMDUxOCAxNC44OTA5IDEzLjk2MTkgMTQuODkwOUwyMC43MjcxIDE0LjkwMzZDMjEuNDQyMSAxNC45MDM2IDIyLjAyMTcgMTUuNDc5NiAyMi4wMjE3IDE2LjE5MDFDMjIuMDIxNyAxNi45MDA1IDIxLjQ0MjEgMTcuNDc2NCAyMC43MjcxIDE3LjQ3NjRIMTMuMTQwNEMxMy4wMjk5IDE3LjQ3NjQgMTIuOTMzMyAxNy41NTExIDEyLjkwNjMgMTcuNjU3N0wxMS4xMTE2IDI0LjcwMTJDMTEuMDkzIDI0Ljc3MjggMTEuMTA5MiAyNC44NDkgMTEuMTU1IDI0LjkwNzRDMTEuMjAwOCAyNC45NjU3IDExLjI3MTEgMjUgMTEuMzQ1NyAyNUgxMy43NTFDMTQuNjUyMiAyNSAxNS4wODUxIDI0LjI1NjcgMTUuMTg3MSAyMy44NjY5TDE1Ljg5NTUgMjEuMzA2NUgyMC43MjcxQzIzLjU4NDkgMjEuMzA2NSAyNS45MSAxOC45OTY2IDI1LjkxIDE2LjE1NjlDMjUuOTEgMTMuMzE3MyAyMy41ODQ5IDExLjAwNzQgMjAuNzI3MSAxMS4wMDc0SDIwLjU2MjRaIiBmaWxsPSIjRUFFM0UzIiAvPgo8L3N2Zz4K';

const SvgFallback = styled(Container).attrs(() => ({ className: 'fallback-svg' }))`
  position: relative;
  svg {
    width: ${({ width }) => width || DEFAULT_IMAGE_SIZE}px;
    height: ${({ height }) => height || DEFAULT_IMAGE_SIZE}px;
    position: absolute;
    left: 0;
    top: 0;
  }
`;

const imageComponentMap = {
  // chatFallback: dummyImage,
  // fallBackNotes: dummyImage,
  fallBackStacking: {
    placeholderSrc: locationFallback,
    errorFallback: (props) => (
      <Image {...props} src={locationFallback} placeholder={locationFallback} />
    ),
  },
  phoneFallback: {
    placeholderSrc: phone,
    errorFallback: (props) => (
      <Image {...props} height={36} width={36} src={phone} placeholder={phone} />
    ),
  },
  fallBackBusiness: {
    placeholderSrc: businessPlaceholder,
    errorFallback: (props) => (
      <Image
        {...props}
        height={36}
        width={36}
        src={businessPlaceholder}
        placeholder={businessPlaceholder}
      />
    ),
  },
  PedidosYa: {
    placeholderSrc: peyaShipping,
    errorFallback: (props) => <Image {...props} src={peyaShipping} placeholder={peyaShipping} />,
  },
  addressFallback: {
    placeholderSrc: address,
    errorFallback: (props) => <Image {...props} src={address} placeholder={address} />,
  },
  fallBackRiderAvatar: {
    placeholderSrc: riderAvatar,
    errorFallback: (props) => <Image {...props} src={riderAvatar} placeholder={riderAvatar} />,
  },
  fallBackChevronDown: {
    placeholderSrc: null,
    errorFallback: (props) => (
      <SvgFallback {...props}>
        <ChevronDown />
      </SvgFallback>
    ),
  },
  fallBackChevronUp: {
    placeholderSrc: null,
    errorFallback: (props) => (
      <SvgFallback {...props}>
        <ChevronUp />
      </SvgFallback>
    ),
  },
  helpFallback: {
    placeholderSrc: null,
    errorFallback: (props) => (
      <SvgFallback {...props}>
        <HelpSupport />
        {/* <Headset size={SIZE.MEDIUM} /> */}
      </SvgFallback>
    ),
  },
  fallBackPinInfo: {
    placeholderSrc: null,
    errorFallback: (props) => {
      return (
        <SvgFallback {...props}>
          <Information />
        </SvgFallback>
      );
    },
  },
  fallBackPinModal: {
    placeholderSrc: pinModalFallback,
    errorFallback: (props) => (
      <Image {...props} src={pinModalFallback} placeholder={pinModalFallback} />
    ),
  },
  arrowRight: {
    placeholderSrc: null,
    errorFallback: (props) => (
      <SvgFallback {...props}>
        <ArrowRight />
        {/* <ArrowRight size={SIZE.SMALL} /> */}
      </SvgFallback>
    ),
  },
  fallBackBanner: {
    placeholderSrc: null,
    errorFallback: (props) => (
      <Image {...props} src={bannerFallback} placeholder={bannerFallback} />
    ),
  },
};

/**
 * Función para armar la URL con los queryparams
 * Si la URL es vacía por alguna razón (el restaurante no tiene foto, o el plato no tiene foto),
 * devuelve el defaultPlaceholder
 * @param {string} url
 * @param {number} size
 * @param {number} dpi
 * @returns Retorna la URL de la imagen con los queryparams o el placeholder
 */
function buildImageUrl(url, size, dpi = 2) {
  if (url === '') {
    return DEFAULT_PLACEHOLDER;
  }

  if (typeof url === 'string') {
    return `${url}${size ? `${url.includes('?') ? '&' : '?'}width=${size}&dpi=${dpi}` : ''}`;
  }
  return DEFAULT_PLACEHOLDER;
}

function mapImageData(id, imageData, imageContainerData) {
  const imgFallback = imageComponentMap[imageData?.fall_back_name];

  const size = imageContainerData?.size || DEFAULT_IMAGE_SIZE;
  const width = imageContainerData?.width || size || DEFAULT_IMAGE_SIZE;
  const height = imageContainerData?.height || size || DEFAULT_IMAGE_SIZE;
  let fallbackSrc = undefined;
  let fallbackRenderer = undefined;
  if (imgFallback?.placeholderSrc) {
    fallbackSrc = imgFallback?.placeholderSrc;
  } else if (imgFallback?.errorFallback) {
    fallbackRenderer = imgFallback?.errorFallback;
  } else {
    fallbackSrc = itemPlaceholder;
  }
  return {
    id,
    url: buildImageUrl(imageData?.url, width || size, imageContainerData?.dpi),
    fallbackComponent: fallbackRenderer,
    fallbackSrc: fallbackSrc,
    size,
    width,
    height,
    rounded: imageContainerData?.is_circle || false,
    alt: imageContainerData.place_holder_name || '',
  };
}

const mergeExtraImageData = (imageData, imagesKey, imageList) => {
  const tmpImageData = { ...imageData, imageList };
  if (tmpImageData[imagesKey]) {
    delete tmpImageData[imagesKey];
  }
  if ('is_circle' in tmpImageData) {
    delete tmpImageData['is_circle'];
  }
  if ('size' in tmpImageData) {
    delete tmpImageData['size'];
  }

  return tmpImageData;
};

export const getImageData = (alchemistProps, key = 'image_container', options) => {
  const imageList = getImagesList(alchemistProps, key, options);
  return imageList;
};

/**
 * Devuelve el listado de imagenes, con una lógica dudosa
 * @param {object} alchemistProps
 * @param {string} key
 * @param {object} options
 * @returns {array<object> | null} Un array con `imageList` o `null`
 */
const getImagesList = (alchemistProps, key, options) => {
  if (alchemistProps && isObject(alchemistProps)) {
    let containerData = alchemistProps[key]
      ? alchemistProps[key]
      : isObject(alchemistProps?.content) && alchemistProps?.content[key]
      ? alchemistProps?.content[key]
      : {};
    let containerKey = null;

    //this normalizes the possible data model that can have images.
    if (containerData?.image) {
      containerKey = 'image';
    } else if (containerData?.images) {
      containerKey = 'images';
    } else if (containerData?.url) {
      containerKey = 'image';
      containerData = {
        image: containerData,
      };
    }

    if (!containerKey) {
      return null;
    }

    //defaults
    containerData.size = containerData?.size || options?.size || DEFAULT_IMAGE_SIZE;
    containerData.is_circle = containerData?.is_circle || options?.rounded || false;
    const imgConfig = {
      size: containerData.size,
      width: options?.width || containerData.size,
      height: options?.height || containerData.size,
      is_circle: containerData?.is_circle || options?.rounded || false,
      dpi: containerData?.dpi || options?.dpi || DEFAULT_IMAGE_DPI,
      place_holder_name: containerData?.place_holder_name || '',
    };
    if (Array.isArray(containerData[containerKey])) {
      return mergeExtraImageData(
        containerData,
        containerKey,
        containerData[containerKey]?.length > 0
          ? containerData[containerKey].map((img, index) => mapImageData(index, img, imgConfig))
          : [],
      );
    } else if (isObject(containerData[containerKey])) {
      return mergeExtraImageData(containerData, containerKey, [
        mapImageData(0, containerData[containerKey], imgConfig),
      ]);
    }

    return null;
  }

  return null;
};

export const extractImageData = (alchemistProps, key = 'image_container', options) => {
  const imageData = getImageData(alchemistProps, key, options);
  return {
    hasImages: () => imageData?.imageList?.length > 0,
    getImageList: () => imageData?.imageList,
    getFirstImage: () => imageData?.imageList[0],
    getImageContainerProps: (property, defaultValue) =>
      getContentProp(imageData, property, defaultValue),
    getImageContainerTextProps: (property) => getTextElementPropsByKey(imageData, property),
  };
};
