const { default: PLATFORMS } = require('@commons/constants/platforms');
const { isEmpty } = require('@app/utils/string');

function getPlatformName(platformKey) {
  if (!platformKey && isEmpty(platformKey)) {
    return null;
  }

  const platform = PLATFORMS[platformKey];

  return platform ? platform?.name : null;
}

export default getPlatformName;
