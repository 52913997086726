import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from '@app/utils/string';
import { goToUrl } from '@app/utils/url';
import ROUTES from '@app/constants/routes';
import OrderStatusViewAlchemist from './OrderStatusViewAlchemist';
import { useExternalServices } from '@app/providers/ExternalServiceProvider/ExternalServiceProvider';
import { getLogger } from '@app/logger';
import LoginLoader from '@app/components/Login/LoginLoader';
import { useRoutes } from '@app/providers/CrossDeviceRoutesProvider';
import format from '@commons/utils/string/format';
import OrderStatusData from './OrderStatusData';
import { Image, isWindowObjectDefined } from '@pedidosya/order-status-components';
import ErrorPage from '@app/components/ui/organisms/ErrorPage/ErrorPageLoader';
import errorImage from '@app/assets/icons/error.svg';
import messages from '../../messages';
import MicrositeLoader from '@app/components/Microsite/MicrositeLoader';
import crossPlatformEvents from '@app/services/event/platform/cross-platform-events';
import {
  getAlchemistContextData,
  updateAlchemistContextData,
} from '@app/alchemist/utils/context-data/alchemist-context-data';
import MicrositeErrorLoader from '@app/components/Microsite/MicrositeErrorLoader';

function isValidInitialData(orderCode, countryId) {
  return typeof orderCode === 'string' && orderCode.length > 0 && typeof countryId === 'number';
}

function getOrderIdentifier(orderCode, orderId) {
  if (orderId) return orderId;
  return orderCode;
}

function OrderStatusPage({
  shouldRedirectToOrderCompleted,
  alchemistContext,
  publicEnv,
  queryParams,
  hasNotch,
  viewType,
  ssr,
}) {
  const PLATFORM_ROUTES = useRoutes();
  const { tracker } = useExternalServices();
  const { code: orderCode, orderId, c: countryCode } = queryParams;
  const countryId = publicEnv?.context?.app?.country?.id;

  // this is a temporary workaround and it will
  // always delete the status of the floating components
  // on startup.
  if (isWindowObjectDefined()) {
    sessionStorage.removeItem('CollapsibleContainer-collapsibleOrderInformation');
  }

  const onOrderCompleted = () => {
    const url = getAlchemistContextData().getPageUrl();

    if (!url) {
      return false;
    }
    crossPlatformEvents.goToUrl(url, true);
    return true;
  };

  const onOrderCancelled = () => {
    const url = shouldRedirectToOrderCompleted
      ? getAlchemistContextData().getPageUrl()
      : ROUTES.HOME;

    if (!url) {
      return false;
    }

    crossPlatformEvents.goToUrl(url, true);

    return true;
  };

  const onGoback = () => {
    crossPlatformEvents.closePage();
  };

  const onPhoneClicked = () => {};

  const onDetailClicked = () => {
    goToUrl(format(PLATFORM_ROUTES.OD_MICROSITE, getAlchemistContextData().getOrderId()));
  };

  const identifier = getOrderIdentifier(orderCode, orderId);
  if (isValidInitialData(identifier, countryId)) {
    return (
      <OrderStatusData
        initialOrderData={alchemistContext.getOrder()}
        orderId={identifier}
        countryId={countryId}
      >
        {({ error, orderData, httpCode }) => {
          try {
            updateAlchemistContextData(orderData);
          } catch (e) {
            getLogger().error(`Error trying to update an AlchemistContextData: ${e.message}`);
          }

          if (error || !orderData) {
            if (httpCode === 401 || httpCode === 403) {
              return <LoginLoader />;
            }

            if (httpCode !== 404) {
              getLogger().error(
                `Error getting order data for order '${getAlchemistContextData().getOrderId()}' and country '${countryId}' error: '${JSON.stringify(
                  error,
                )}', status: ${httpCode} data: ${JSON.stringify(orderData)}`,
              );
            }
            return (
              <ErrorPage
                anchoredToParent
                title={messages.micrositeErrorTitle.defaultMessage}
                description={messages.micrositeErrorDescription.defaultMessage}
                IconComponent={
                  <Image src={errorImage} alt={messages.micrositeErrorTitle.defaultMessage} />
                }
              ></ErrorPage>
            );
          }

          if (getAlchemistContextData().isOrderCompletedPageType()) {
            const redirected = onOrderCompleted();
            // will show the loader while redirecting.
            return redirected ? (
              <MicrositeLoader />
            ) : (
              <MicrositeErrorLoader
                title={messages.micrositeCompletedTitle.defaultMessage}
                description={messages.micrositeCompletedDescription.defaultMessage}
              />
            );
          }

          if (getAlchemistContextData().isOrderCancelledPageType()) {
            const redirected = onOrderCancelled();
            // will show the loader while redirecting.
            return redirected ? (
              <MicrositeLoader />
            ) : (
              <MicrositeErrorLoader
                title={messages.micrositeCancellationTitle.defaultMessage}
                description={messages.micrositeCancellationDescription.defaultMessage}
              />
            );
          }

          return (
            <OrderStatusViewAlchemist
              alchemistContext={getAlchemistContextData()}
              publicEnv={publicEnv}
              routes={PLATFORM_ROUTES}
              tracker={tracker}
              countryCode={countryCode}
              countryId={publicEnv?.context?.app?.country?.id}
              onHistoryBack={onGoback}
              onPhoneClicked={onPhoneClicked}
              onDetailClicked={onDetailClicked}
              hasNotch={hasNotch}
              viewType={viewType}
              isSSR={ssr}
            />
          );
        }}
      </OrderStatusData>
    );
  } else {
    getLogger().error(`Invalid initialData [orderCode:'${orderCode}', countryId '${countryId}']`);
    return (
      <ErrorPage
        anchoredToParent
        title={messages.micrositeErrorTitle.defaultMessage}
        description={messages.micrositeErrorDescription.defaultMessage}
        IconComponent={<Image src={errorImage} alt={messages.micrositeErrorTitle.defaultMessage} />}
      ></ErrorPage>
    );
  }
}

OrderStatusPage.propTypes = {
  shouldRedirectToOrderCompleted: PropTypes.bool,
  alchemistContext: PropTypes.object.isRequired,
  queryParams: PropTypes.object.isRequired,
  publicEnv: PropTypes.object.isRequired,
  userAddresses: PropTypes.array,
  hasNotch: PropTypes.bool,
  ssr: PropTypes.bool,
  viewType: PropTypes.string,
};

export default React.memo(OrderStatusPage);
