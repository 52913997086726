import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  Spinner,
  FLOW_DIRECTION,
  ALIGNMENT,
  useOnElementResize,
  useScreenSize,
} from '@pedidosya/order-status-components';
import { getLogger } from '@app/logger';
import ComponentGroupRenderer from '@app/alchemist/ComponentGroupRenderer';
import MapSection from '../../sections/MapSection';
import MicrositeErrorLoader from '@app/components/Microsite/MicrositeErrorLoader';
import { MAP_COVERED_ZONE_PERCENTAGE, limitNumberRange } from '../../utils';
import { BottomSheetFloatingComponentsContainer, OSDesktopLayout } from './OSDesktopLayout';
import useIsFirstRender from '@app/hooks/useIsFirstRender';
import { getAlchemistContextData } from '@app/alchemist/utils/context-data/alchemist-context-data';

const BS_MIN_HEIGHT = 430;
const BS_MAX_HEIGHT = 500;

const MapComponent = React.lazy(() => {
  return import('@app/components/ui/organisms/OrderMap/OrderMap');
});

const OSDesktopTrackingOrderView = ({
  pageDescriptor,
  bsSnapPoints,
  defaultSnapPoint,
  alchemistContext,
  platformName,
  countryId,
  isMapEnabled,
  hasNotch,
  onLayoutChange,
  isNative,
  onBSSnapPointChanged,
}) => {
  const { isFirstRender } = useIsFirstRender();
  const { windowHeight } = useScreenSize();
  const bottomsheetDockedComponentsRef = useRef(null);
  const bottomsheetFloatingComponentsRef = useRef(null);
  const layoutRef = useRef(null);

  const { height: BSFloatingComponentsHeight } = useOnElementResize(
    bottomsheetFloatingComponentsRef,
  );

  const hasTopFloatingComponents =
    pageDescriptor?.bottomSheet?.topFloatingComponents &&
    pageDescriptor?.bottomSheet?.topFloatingComponents?.length > 0;
  // because in SSR is not possible to calculate the percentage
  // screen usage of this container.
  const hasDockedComponents =
    pageDescriptor?.bottomSheet?.dockedComponentIds &&
    pageDescriptor?.bottomSheet?.dockedComponentIds?.length > 0;

  const isBottomSheetFullscreen = !isMapEnabled;
  const bottomSheetDockedComponentContainerPadding = [0, 0, 16, 0];
  const bottomSheetDockedComponentContainerMargin = [2, 0, 0, 0];

  const notchTopPadding = 0;

  useEffect(() => {
    if (layoutRef?.current) {
      onLayoutChange({
        layout: { height: limitNumberRange(layoutRef?.current?.offsetHeight || 0, 400, 900) },
        bottomsheet: {
          floatingComponents: {
            height: bottomsheetFloatingComponentsRef?.current
              ? bottomsheetFloatingComponentsRef.current.offsetTop +
                bottomsheetFloatingComponentsRef.current.offsetHeight
              : 0,
          },
          dockedComponents: {
            height: bottomsheetDockedComponentsRef?.current
              ? bottomsheetDockedComponentsRef.current.offsetHeight
              : 0,
          },
        },
      });
    }
  }, [
    windowHeight,
    layoutRef,
    bottomsheetFloatingComponentsRef,
    BSFloatingComponentsHeight,
    bottomsheetDockedComponentsRef,
  ]);

  const dataContainerStyles = {
    width: '100%',
    minWidth: '378px',
    maxWidth: 'auto',
    display: 'block',
    padding: '0',
  };
  const totalBSContentHeightPercentage = Math.abs(
    BSFloatingComponentsHeight - pageDescriptor.bottomSheet.screenHeight.max.abs,
  );

  const bottomSheetContentStyle = {
    padding: '8px 2px 0 0',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    maxWidth: '100%',
    width: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    maxHeight: '500px',
    margin: 0,
  };

  const bsContentWrapperStyles = {
    position: 'relative',
    zIndex: 5,
    top: '0',
    width: '100%',
    left: '0px',
    display: 'flex',
    flexDirection: 'column',
  };

  if (isMapEnabled) {
    dataContainerStyles.width = '33%';
    dataContainerStyles.maxWidth = '378px';
    //if it is the tab version, it will add some margin for the tabs
    if (alchemistContext.hasTabs()) {
      bsContentWrapperStyles.marginTop = '40px';
      // this set the same height for each tab no matter its contents.
      bottomSheetContentStyle.height = pageDescriptor.bottomSheet.screenHeight.max.abs + 'px';
      bottomSheetContentStyle.maxHeight = BS_MAX_HEIGHT + 'px';
      bottomSheetContentStyle.minHeight = BS_MIN_HEIGHT + 'px';
      // bottomSheetContentStyle.overflowX = 'auto';
      // bottomSheetContentStyle.overflowY = 'auto';
    }
  }

  return (
    <OSDesktopLayout ref={layoutRef}>
      {isMapEnabled && (
        <MapSection
          notchValue={notchTopPadding}
          heightPercentage={pageDescriptor.map.screenHeight.percentage}
          mapBottomCoveredPercentage={MAP_COVERED_ZONE_PERCENTAGE}
          // controlComponents={}
          topComponents={
            pageDescriptor.map.topFloatingComponentIds?.length > 0 ? (
              <ComponentGroupRenderer
                componentList={pageDescriptor.componentList}
                componentIdentifiers={pageDescriptor.map.topFloatingComponentIds}
              >
                {({ componentsOutput: mapTopChildren, error }) =>
                  error ? undefined : mapTopChildren
                }
              </ComponentGroupRenderer>
            ) : undefined
          }
          bottomComponents={
            pageDescriptor.map.bottomFloatingComponentIds?.length > 0 ? (
              <ComponentGroupRenderer
                componentList={pageDescriptor.componentList}
                componentIdentifiers={pageDescriptor.map.bottomFloatingComponentIds}
              >
                {({ componentsOutput: mapBottomChildren, error }) =>
                  error ? undefined : mapBottomChildren
                }
              </ComponentGroupRenderer>
            ) : undefined
          }
        >
          {!isFirstRender ? (
            <React.Suspense fallback={<Spinner />}>
              <MapComponent
                orderId={alchemistContext.getOrderId()}
                countryId={countryId}
                mapHeight={`${pageDescriptor.map.screenHeight.abs}px`}
                markersBoundPadding={[30, 30, 30, 30]}
              />
            </React.Suspense>
          ) : (
            <Spinner />
          )}
        </MapSection>
      )}
      <div id="data-container" style={dataContainerStyles}>
        {hasTopFloatingComponents && (
          <div id="bottomsheet-floating-components">
            <ComponentGroupRenderer
              componentList={pageDescriptor.componentList}
              componentIdentifiers={pageDescriptor.bottomSheet.topFloatingComponents}
            >
              {({ componentsOutput: mapTopChildren, error }) =>
                error ? undefined : mapTopChildren
              }
            </ComponentGroupRenderer>
          </div>
        )}
        <Container
          id="bs-content-wrapper"
          expanded={isBottomSheetFullscreen}
          style={bsContentWrapperStyles}
        >
          <div id="bs-content" style={bottomSheetContentStyle}>
            {hasDockedComponents ? (
              <Container
                ref={bottomsheetDockedComponentsRef}
                direction={FLOW_DIRECTION.COLUMN}
                hAlignment={ALIGNMENT.CENTER}
                // ref={bottomSheetContainerRef}
                padding={bottomSheetDockedComponentContainerPadding}
                margin={bottomSheetDockedComponentContainerMargin}
              >
                <ComponentGroupRenderer
                  componentList={pageDescriptor.componentList}
                  componentIdentifiers={pageDescriptor.bottomSheet.dockedComponentIds}
                >
                  {({ componentsOutput: bottomSheetFloatingChildren, error }) => {
                    return error ? undefined : bottomSheetFloatingChildren;
                  }}
                </ComponentGroupRenderer>
              </Container>
            ) : undefined}
            <ComponentGroupRenderer
              componentList={pageDescriptor.componentList}
              renderAllIfNoIdentifiers={true}
              componentIdentifiers={pageDescriptor.bottomSheet.componentIds}
            >
              {({ componentsOutput: bottomSheetChildren, error }) => {
                if (error) {
                  getLogger().error(
                    `[ORDER STATE][TRACKING_VIEW] ${getAlchemistContextData().getContextInfoString()} [countryId]: "${countryId}" [error]: ${error}`,
                  );
                  return <MicrositeErrorLoader />;
                }
                return bottomSheetChildren;
              }}
            </ComponentGroupRenderer>
          </div>
        </Container>
      </div>
    </OSDesktopLayout>
  );
};

OSDesktopTrackingOrderView.propTypes = {
  onLayoutChange: PropTypes.func,
  pageDescriptor: PropTypes.shape({
    bottomSheet: PropTypes.shape({
      componentIds: PropTypes.array,
      dockedComponentIds: PropTypes.array.isRequired,
      screenHeight: PropTypes.shape({
        percentage: PropTypes.number,
      }),
    }),
    map: PropTypes.shape({
      screenHeight: PropTypes.shape({
        percentage: PropTypes.number,
      }),
    }),
    componentList: PropTypes.array.isRequired,
  }).isRequired,
  alchemistContext: PropTypes.object.isRequired,
  countryId: PropTypes.number.isRequired,
  platformName: PropTypes.string.isRequired,
  isMapEnabled: PropTypes.bool,
  bsSnapPoints: PropTypes.array.isRequired,
  defaultSnapPoint: PropTypes.number.isRequired,
};

OSDesktopTrackingOrderView.defaultProps = {
  isMapEnabled: true,
  onLayoutChange: () => {},
};

export default React.memo(OSDesktopTrackingOrderView);
