import isObject from '@commons/utils/object/isObject';

export function getContentProp(alchemistProps, property, defaultValue = null) {
  let value;
  if (alchemistProps) {
    if (alchemistProps[property] !== undefined) {
      value = alchemistProps[property];
    } else if (
      isObject(alchemistProps?.content) &&
      alchemistProps.content[property] !== undefined
    ) {
      value = alchemistProps.content[property];
    } else {
      return defaultValue;
    }

    return !Number.isNaN(Number(value)) ? Number(value) : value || defaultValue;
  }

  return defaultValue;
}
export function matchesComponent(alchemistProps, componentId) {
  return alchemistProps?.id === componentId;
}

export function matchesOneOfComponents(alchemistProps, componentIdList) {
  if (componentIdList?.length > 0) {
    for (let i = 0; i < componentIdList.length; i++) {
      if (matchesComponent(alchemistProps, componentIdList[i])) {
        return true;
      }
    }
  }
  return false;
}
