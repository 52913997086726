import React from 'react';
import AlchemistContentComponent from '@app/alchemist/AlchemistContentComponent';
import { ClickableElement, ContainerV2, FLOW_DIRECTION } from '@pedidosya/order-status-components';
import { ACTION_TYPE } from '@app/alchemist/utils/actions';
import crossPlatformEvents from '@app/services/event/platform/cross-platform-events';
import RowContainerComponent from './RowContainerComponent';
import PaymentInfoComponent from './PaymentInfoComponent';
import InfoArrowComponent from './InfoArrowComponent';
import ProductInfoComponent from './ProductInfoComponent';
import { getLogger } from '@app/logger';
import { goToTargetUrl } from '@app/utils/url';

function FullDetailComponent(props) {
  const navigateAction = props.componentData.getAction(ACTION_TYPE.NAVIGATE);

  const onClick = () => {
    getLogger(`FullDetailComponent targetUrl: ${navigateAction?.targetUrl}`);
    goToTargetUrl(navigateAction, crossPlatformEvents.goToUrl);
  };

  return (
    <ClickableElement data-testid="clickable-detail" onClick={onClick} enabled overShadow={false}>
      <AlchemistContentComponent
        Component={RowContainerComponent}
        parentProps={props}
        childNode={
          <InfoArrowComponent {...props}>
            <ContainerV2
              direction={FLOW_DIRECTION.COLUMN}
              expanded
              paddingTokens={[
                'spacing-component-none',
                'spacing-component-none',
                'spacing-component-large',
                'spacing-component-none',
              ]}
            >
              <AlchemistContentComponent Component={ProductInfoComponent} parentProps={props} />
            </ContainerV2>
            <AlchemistContentComponent Component={PaymentInfoComponent} parentProps={props} />
          </InfoArrowComponent>
        }
      />
    </ClickableElement>
  );
}

export default FullDetailComponent;
