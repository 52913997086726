import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  ALIGNMENT,
  FLOW_DIRECTION,
  ClickableElement,
  ArrowRight,
} from '@pedidosya/order-status-components';
import { getDefaultHigherComponentPadding } from '@app/alchemist/utils/defaults';

const FallbackView = ({ DescriptionComponent, onClick, IconComponent, children }) => {
  const containerPadding = getDefaultHigherComponentPadding();
  const iconPadding = [0, 18, 0, 0];
  const childrenPadding = [0, containerPadding[1], 0, containerPadding[3]];
  // const divider = {
  //   margin: [6],
  //   bgColor: COLORS.PLATINUM,
  //   height: 1,
  // };

  const showClickableIcon = typeof onClick === 'function';
  const clickHandler = showClickableIcon ? onClick : () => {};

  return (
    <ClickableElement onClick={clickHandler} enabled>
      <Container
        padding={containerPadding}
        direction={FLOW_DIRECTION.COLUMN}
        expanded
        hAlignment={ALIGNMENT.START}
        vAlignment={ALIGNMENT.START}
      >
        <Container
          direction={FLOW_DIRECTION.ROW}
          expanded
          hAlignment={ALIGNMENT.START}
          vAlignment={ALIGNMENT.CENTER}
          fitContent
        >
          {IconComponent && (
            <Container
              padding={iconPadding}
              direction={FLOW_DIRECTION.ROW}
              vAlignment={ALIGNMENT.CENTER}
              fillSpace
            >
              {IconComponent}
            </Container>
          )}
          {DescriptionComponent && (
            <Container
              fillSpace
              direction={FLOW_DIRECTION.ROW}
              vAlignment={ALIGNMENT.CENTER}
              expanded
            >
              {DescriptionComponent}
            </Container>
          )}
          {showClickableIcon && (
            <Container direction={FLOW_DIRECTION.ROW} vAlignment={ALIGNMENT.CENTER}>
              <ArrowRight />
            </Container>
          )}
        </Container>
      </Container>
      <Container expanded padding={childrenPadding}>
        {children}
      </Container>
    </ClickableElement>
  );
};

FallbackView.propTypes = {
  onClick: PropTypes.func,
  DescriptionComponent: PropTypes.node,
  IconComponent: PropTypes.object,
};

export default FallbackView;
