import styled from 'styled-components';

export const OSMobileLayout = styled.div.attrs(() => ({
  id: 'main-layout',
}))`
  --eta-border-color: #ededed;

  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: no-wrap;
  /*the background color for the non map area */
  background-color: #fff;

  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  #static-bottomsheet {
    position: static;
  }

  & #bottomsheet-floating-components {
    position: absolute !important;
    top: ${({ hasHeader, hasNotch }) =>
      hasHeader || hasNotch ? '0px !important' : '20px !important'};
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 600px;
    min-width: 328px;
  }
`;

export const BottomSheetFloatingComponentsContainer = styled.div.attrs(() => ({
  id: 'bottomsheet-floating-components',
}))`
  z-index: 2;
  width: 100%;
  position: 'absolute';

  & > div > * {
    padding: 0;
  }
  & > * {
    padding: 0 16px;
  }
`;
