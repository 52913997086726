import React from 'react';
import AlchemistMessageData from './AlchemistMessageData';
import {
  Toast,
  Text,
  Image,
  FLOW_DIRECTION,
  Container,
  ALIGNMENT,
} from '@pedidosya/order-status-components';

const NonDismissibleMessage = (props) => {
  return (
    <AlchemistMessageData {...props}>
      {({
        titleProps,
        backgroundColor,
        margin,
        padding,
        borderColor,
        borderSize,
        borderPosition,
        icon,
      }) => {
        return (
          <Toast
            margin={margin}
            bgColor={backgroundColor}
            borderSize={borderSize}
            borderPosition={borderPosition}
            borderColor={borderColor}
            showCloseButton={false}
            padding={padding}
            expanded
          >
            <Container
              direction={FLOW_DIRECTION.ROW}
              vAlignment={ALIGNMENT.CENTER}
              expanded
              fitContent
            >
              {icon && (
                <Image
                  src={icon.url}
                  fallback={icon.fallbackSrc}
                  width={icon.size}
                  height={icon.size}
                  fallbackRenderer={icon.fallbackComponent}
                  margin={icon.margin}
                  hideOnError={icon.hideOnError}
                />
              )}
              <Text {...titleProps}>{titleProps.text}</Text>
            </Container>
          </Toast>
        );
      }}
    </AlchemistMessageData>
  );
};

export default NonDismissibleMessage;
