import applicationVersion from '@commons/constants/applicationVersion';

export const DATADOG_LOG_LEVEL = {
  debug: 'debug',
  info: 'info',
  warn: 'warn',
  warning: 'warn',
  error: 'error',
  fatal: 'error',
};

export const LOGGER_NAME = 'order-status-micro-site';

export const DATADOG_SITE = 'datadoghq.com';

export const DATADOG_CLIENT_TOKEN = 'pub3c568d433b837b1cec4a29b787e011a7';

export const version = applicationVersion;
