import React from 'react';
import PropTypes from 'prop-types';
import { Text, Container, HorizontalSeparator } from '@pedidosya/order-status-components';
import { getDefaultHigherComponentPadding } from '@app/alchemist/utils/defaults';
import SimpleDetailLayout from '../ImageDetails/SimpleDetailLayout';

function ImageDescriptionActionChevron({ reverseContent, ...props }) {
  const titleProps = props.componentData.getTextProps('title');
  const subtitleProps = props.componentData.getTextProps('sub_title', { lineHeight: '20px' });
  const dividerProps = props.componentData.getStyles().getDividerProps();
  const padding = props.componentData.getStyles().getPadding(getDefaultHigherComponentPadding());

  const TitleComponent = titleProps?.text ? (
    <Text as="span" {...titleProps}>
      {titleProps.text}
    </Text>
  ) : undefined;
  const SubtitleComponent = subtitleProps ? (
    <Text as="span" {...subtitleProps}>
      {subtitleProps.text}
    </Text>
  ) : undefined;

  let DividerComponent = dividerProps ? (
    <Container expanded padding={dividerProps?.padding}>
      <HorizontalSeparator
        color={dividerProps?.borderColor?.length > 0 ? dividerProps.borderColor[0] : undefined}
        height={dividerProps?.height}
      />
    </Container>
  ) : undefined;

  return (
    <SimpleDetailLayout
      padding={padding}
      titleComponent={TitleComponent}
      subtitleComponent={SubtitleComponent}
      dividerComponent={DividerComponent}
    >
      {props.componentData.getChildren()}
    </SimpleDetailLayout>
  );
}

ImageDescriptionActionChevron.propTypes = {
  enabled: PropTypes.bool,
  title: PropTypes.object.isRequired,
  reverseContent: PropTypes.bool,
};

export default ImageDescriptionActionChevron;
