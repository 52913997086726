import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AnimationSecuence from '@app/components/AnimationSecuence';
import { getLottieInfo } from '@app/alchemist/utils/lottie';
import { ALIGNMENT, Container } from '@pedidosya/order-status-components';

const Lottie = (props) => {
  const [lottieData, setLottieData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [frameSegments, setFrameSegments] = useState(null);
  useEffect(() => {
    let mounted = true;
    setLoading(true);
    getLottieInfo(props, props?.version)
      .then(({ animationData, frameSegments }) => {
        if (mounted) {
          if (animationData) {
            setLottieData(animationData);
            setFrameSegments(frameSegments);
          } else {
            setLottieData(null);
          }
        }
      })
      .catch((e) => mounted && setLottieData(null))
      .finally(() => mounted && setLoading(false));
    return () => {
      mounted = false;
    };
  }, [props]);
  // const minHeight = props.componentData.getStyles().getCustomProp('minHeight', 'auto');
  const padding = props.componentData.getStyles().getPadding();

  const commonStyles = {
    width: props?.width ? props.width : 'auto',
    height: props?.height ? props.height + 'px' : 'auto',
  };
  const containerStyles = {
    position: 'relative',
    ...commonStyles,
  };
  const svgStyles = {
    ...commonStyles,
    ...(props?.customStyles || {}),
  };

  const LottiePlaceholder = props.Placeholder ? props.Placeholder : () => null;
  if (loading) {
    return (
      <Container padding={padding} style={containerStyles}>
        <LottiePlaceholder />
      </Container>
    );
  }

  if (!lottieData) return null;

  return (
    <Container padding={padding} hAlignment={ALIGNMENT.CENTER} style={containerStyles}>
      <AnimationSecuence
        styles={svgStyles}
        play
        animationData={lottieData}
        frameSegments={frameSegments}
      />
    </Container>
  );
};

Lottie.propTypes = {
  minHeight: PropTypes.number,
  version: PropTypes.oneOf(['v1', 'v2']),
};

Lottie.defaultProps = {
  minHeight: 0,
  version: 'v2',
};

Lottie.displayName = 'Lottie';

export default Lottie;
