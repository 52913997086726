const allowedMicrositeParamList = [
  'c',
  'code',
  'orderId',
  'userName',
  'origin',
  'location',
  'sharedId',
  'has_notch',
];

export default allowedMicrositeParamList;
