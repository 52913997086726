/* eslint-disable no-console */
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState, createContext, useCallback } from 'react';
import axios from 'axios';
import pkg from '../../../package.json';
import isObject from '@commons/utils/object/isObject';
import MicrositeErrorLoader from '@app/components/Microsite/MicrositeErrorLoader';
import { getLogger } from '@app/logger';

const SERVICE_KEY = `[PUBLIC ENV]`;

const PublicEnvContext = createContext();

export const usePublicEnv = () => {
  return useContext(PublicEnvContext);
};

export const PublicEnvProvider = ({ children, ssr, context, debugMode = false }) => {
  const [publicEnv, setPublicEnv] = useState({
    ...context,
    debugMode: Boolean(debugMode),
    clearUserData: () => {},
    ssr,
  });
  const [error, setError] = useState(null);
  const clearUserData = useCallback(() => {
    const env = { ...publicEnv };
    env.app.user = { ...publicEnv.app.user };
    env.app.user.id = null;
    env.app.user.email = null;
    setPublicEnv({ ...env, debugMode: Boolean(debugMode), clearUserData, ssr });
  }, [publicEnv]);

  useEffect(() => {
    if (isObject(context)) {
      setPublicEnv({ ...context, debugMode: Boolean(debugMode), clearUserData, ssr });
      setError(null);
    } else {
      setError('Invalid public env.');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debugMode, context]);

  if (error) {
    getLogger().error(`${SERVICE_KEY} error getting public env: ${error}`);
    return <MicrositeErrorLoader debugMessage={error} />;
  }

  return <PublicEnvContext.Provider value={publicEnv}>{children}</PublicEnvContext.Provider>;
};

export const publicEnvShape = {
  debugMode: PropTypes.bool,
  ssr: PropTypes.bool,
  locale: PropTypes.string.isRequired,
  device: PropTypes.string.isRequired,
  environment: PropTypes.string.isRequired,
  country: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};
