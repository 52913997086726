import React from 'react';
import PropTypes from 'prop-types';
import { textPropTypes } from '@app/alchemist/types/props';
import { Container, HorizontalSeparator, Image, Text } from '@pedidosya/order-status-components';
import SimpleDetailLayout from './SimpleDetailLayout';
function ImageDetails(props) {
  const titleProps = props.componentData.getTextProps('title');
  const subtitleProps = props.componentData.getTextProps('sub_title', { lineHeight: '20px' });

  if (!titleProps) {
    throw new Error('There is no title/sub_title prop provided to ImageDetails component');
  }

  const imgProps = props.componentData.getImageData(
    props?.image_container ? 'image_container' : 'image',
    {
      size: 36,
      rounded: false,
    },
  );

  imgProps.margin = [0, 12, 0, 0];
  const dividerProps = props.componentData.getStyles().getDividerProps();

  const padding = props.componentData.getStyles().getPadding([0]);

  const TitleComponent = <Text {...titleProps}>{titleProps.text}</Text>;
  const SubtitleComponent = subtitleProps ? (
    <Text {...subtitleProps}>{subtitleProps.text}</Text>
  ) : undefined;

  let DividerComponent = dividerProps ? (
    <Container expanded padding={dividerProps?.padding}>
      <HorizontalSeparator
        color={dividerProps?.borderColor?.length > 0 ? dividerProps.borderColor[0] : undefined}
        height={dividerProps?.height}
      />
    </Container>
  ) : undefined;

  const ImageComponent =
    imgProps && imgProps?.hasImages()
      ? imgProps
          .getImageList()
          .map((image) => (
            <Image
              key={image.url}
              src={image.url}
              fallback={image.fallbackSrc}
              alt={titleProps.text}
              width={image.size}
              height={image.size}
              borderRadius={6}
              margin={imgProps?.margin}
              fallbackRenderer={image.fallbackComponent}
            />
          ))
      : null;
  return (
    <SimpleDetailLayout
      padding={padding}
      titleComponent={TitleComponent}
      subtitleComponent={SubtitleComponent}
      imageComponent={ImageComponent}
      dividerComponent={DividerComponent}
    >
      {/* TODO: for now the children are not allowed, because for web there
        are no microsites to redirect to.
      */}
      {/* {props.children} */}
    </SimpleDetailLayout>
  );
}

ImageDetails.propTypes = {
  title: PropTypes.shape(textPropTypes).isRequired,
};

export default ImageDetails;
