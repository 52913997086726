const baseApiUrl = `/order-state/api`;
const apiVersion = `v1`;

export const getAlchemistOrderURL = `${baseApiUrl}/${apiVersion}/country/{0}/alchemist/orders/{1}`;
export const customMetricsUrl = `${baseApiUrl}/metrics`;
// esta url es 'gratuities' (sinonimo de tips) porque en mweb (monolith) se esta capturando todo
// lo que lleve tips en la url impidiendo que este request me llegue.
export const tipsURL = `${baseApiUrl}/${apiVersion}/country/{0}/orders/{1}/gratuities`;
export const getUserAddressesURL = `${baseApiUrl}/${apiVersion}/country/{0}/address`;
export const getTrackingData = `${baseApiUrl}/${apiVersion}/country/{0}/orders/{1}/tracking`;
