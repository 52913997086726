import React from 'react';
import { useExternalServices } from '@app/providers/ExternalServiceProvider/ExternalServiceProvider';
import businesImage from '@app/assets/icons/order-status/business.svg';
import detailMessages from '@app/alchemist/components/FullDetailAmount/messages';
import imageDetailMessages from '@app/alchemist/components/ImageDetails/messages';
import {
  Text,
  Image,
  HorizontalSeparator,
  FONT_TOKEN,
  Order,
} from '@pedidosya/order-status-components';
import FallbackView from '@app/alchemist/components/Fallback/FallbackView';
import { useTranslate } from '@app/providers/I18nProvider';
import SimpleDetailLayout from '@app/alchemist/components/ImageDetails/SimpleDetailLayout';

export const UnusedComponent = (props) => {
  const { tracker } = useExternalServices();
  props.tracking?.forEach((eventData) => tracker.turnOffEvent(eventData?.id));
  return null;
};

export const ImageDetailsFallback = (props) => {
  const { translate } = useTranslate();
  const typography = {
    fontToken: FONT_TOKEN.FONT_BODY_MIDCONTRAST_SENTENCE_LARGE,
  };
  const padding = [0];
  const imgMargin = [0, 12, 0, 0];
  const TitleComponent = (
    <Text {...typography} nodeType="p">
      {translate(imageDetailMessages.imageDetailText)}
    </Text>
  );
  const ImageComponent = (
    <Image
      width={24}
      height={24}
      src={businesImage}
      margin={imgMargin}
      placeholder={businesImage}
    />
  );

  return (
    <SimpleDetailLayout
      padding={padding}
      titleComponent={TitleComponent}
      imageComponent={ImageComponent}
    >
      {props.children}
    </SimpleDetailLayout>
  );
};

export const FullDetailAmountFallback = (props) => {
  const { translate } = useTranslate();

  const typography = {
    fontToken: FONT_TOKEN.FONT_BODY_MIDCONTRAST_SENTENCE_LARGE,
  };
  const onClick = props?.globals?.onDetailClicked;

  return (
    <FallbackView
      onClick={onClick}
      IconComponent={<Order size="medium" />}
      DescriptionComponent={<Text {...typography}>{translate(detailMessages.detailText)}</Text>}
    >
      <HorizontalSeparator />
    </FallbackView>
  );
};
