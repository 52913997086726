import { useState, useRef, useEffect, useCallback } from 'react';
import AxiosHTTPAdapter from './http-adapters/axios-adapter';

const isValidHTTPAdapter = (adapter) =>
  Object.prototype.toString.call(adapter) === '[Object object]' &&
  'getResponseHttpCode' in adapter &&
  'getResponseData' in adapter &&
  'getResponseError' in adapter &&
  typeof adapter.getResponseHttpCode === 'function' &&
  typeof adapter.getResponseData === 'function' &&
  typeof adapter.getResponseError === 'function';

const retryableStatusCodes = [500, 502, 503, 504];

function useFetcher({
  fetchService,
  initialData,
  maxRetries,
  retryMillisecondsInterval,
  refetchMillisecondsInterval,
  HTTPClientAdapter,
}) {
  const [data, setData] = useState({ body: initialData || null, httpCode: 200, error: null });
  const [loading, setLoading] = useState(false);
  const [refetching, setRefetching] = useState(false);
  const retries = useRef(0);
  const calls = useRef(0);
  const retryTimeoutId = useRef(null);
  const refetchTimeoutId = useRef(null);
  const httpClientAdapter = useRef(null);
  const fetchServiceRef = useRef(null);

  const MAX_RETRIES = Number.isNaN(Number(maxRetries)) ? 0 : maxRetries - 1;
  useEffect(
    function saveFetcherInstance() {
      if (typeof fetchService === 'function') {
        fetchServiceRef.current = fetchService;
      } else {
        throw new Error('useFetcher error: no fetcherService provided.');
      }
    },
    [fetchService],
  );

  useEffect(function saveAdapterInstance() {
    if (isValidHTTPAdapter(HTTPClientAdapter)) {
      httpClientAdapter.current = HTTPClientAdapter;
    } else {
      httpClientAdapter.current = new AxiosHTTPAdapter();
    }
  }, []);

  useEffect(function clearTimeouts() {
    return () => {
      if (retryTimeoutId.current) {
        clearTimeout(retryTimeoutId.current);
      }
      if (refetchTimeoutId.current) {
        clearTimeout(refetchTimeoutId.current);
      }
    };
  }, []);

  const shouldRetryRequest = (httpStatusCode) => {
    return (
      retries.current < MAX_RETRIES &&
      retryMillisecondsInterval > 0 &&
      httpStatusCode &&
      retryableStatusCodes.indexOf(httpStatusCode) !== -1
    );
  };

  const fetchData = useCallback(() => {
    if (calls.current === 0) {
      setLoading(true);
    } else {
      setRefetching(true);
    }
    calls.current += 1;

    fetchServiceRef
      .current()
      .then((response) => {
        clearRetryCounter();
        setData({
          body: httpClientAdapter.current.getResponseData(response) || null,
          httpCode: httpClientAdapter.current.getResponseHttpCode({ response }),
          error: null,
        });
        return response;
      })
      .catch((e) => {
        const httpStatusCode = httpClientAdapter.current.getResponseHttpCode(e);
        if (shouldRetryRequest(httpStatusCode)) {
          if (refetchTimeoutId.current) {
            clearTimeout(refetchTimeoutId.current);
          }

          if (retryTimeoutId.current) {
            clearTimeout(retryTimeoutId.current);
          }

          retries.current += 1;
          retryTimeoutId.current = setTimeout(() => {
            fetchData();
          }, retryMillisecondsInterval);
        } else {
          setData({
            body: data.body,
            httpCode: httpStatusCode,
            error: httpClientAdapter.current.getResponseError(e) || 'unknown error',
          });
        }
      })
      .finally(() => {
        setLoading(false);
        setRefetching(false);
      });
  }, []);

  useEffect(
    function refetchInterval() {
      if (refetchTimeoutId.current) {
        clearInterval(refetchTimeoutId.current);
      }
      refetchTimeoutId.current = setInterval(() => fetchData(), refetchMillisecondsInterval);
      () => {
        clearInterval(refetchTimeoutId.current);
      };
    },
    [refetchMillisecondsInterval, fetchData],
  );

  const clearRetryCounter = () => {
    retries.current = 0;
  };

  return {
    fetchData,
    loading,
    refetching,
    response: data.body,
    error: data.error,
    httpCode: data.httpCode,
  };
}

export default useFetcher;
