import getInstance from '@app/services/axios';
import HTTPResponse from '@commons/models/HTTPResponse';
import isObject from '@commons/utils/object/isObject';
import MICROSITE_STATE from '../../constants/microsite-state';
import Response from '../../models/response';

const getAxiosStatusCode = (response) => {
  return response?.networkStatus || response?.status || response?.response?.status;
};

const isUnauthorizedError = (statusCode) => {
  return statusCode === 401 || statusCode === 403;
};
const getErrorResponseBody = (error) => {
  return isObject(error) && (error?.response?.data || {});
};

async function getPublicEnv({ countryId, locationId, orderCode }) {
  const axios = await getInstance();
  let response;
  try {
    const queryParams = new URLSearchParams({
      code: orderCode,
      c: countryId,
      location: locationId,
    });
    response = await axios.get(`/order-state/api/env?${queryParams.toString()}`);
  } catch (e) {
    throw new HTTPResponse({
      data: getErrorResponseBody(e),
      message: e?.message,
      httpCode: getAxiosStatusCode(e) || 500,
    });
  }

  if (!response) {
    throw new HTTPResponse({
      data: null,
      message: 'Invalid public env response',
      httpCode: 500,
    });
  }

  if (isUnauthorizedError(getAxiosStatusCode(response))) {
    throw new HTTPResponse({
      data: response.data?.data,
      message: response.data?.message,
      httpCode: getAxiosStatusCode(response),
    });
  }

  return new HTTPResponse({
    data: response.data?.data,
    message: response.data?.message,
    httpCode: getAxiosStatusCode(response),
  });
}

export const initPublicEnv = async (orderCode, country, locationId) => {
  const response = new Response();
  try {
    const serverResponse = await getPublicEnv({ countryId: country, locationId, orderCode });
    response.setStatus(true).setData(serverResponse.data).setCode(MICROSITE_STATE.SUCCESS);
  } catch (e) {
    response
      .setStatus(false)
      .setData(e?.data)
      .setError(e?.message)
      .setCode(
        isUnauthorizedError(e?.httpCode)
          ? MICROSITE_STATE.UNAUTHORIZED
          : e?.httpCode === 404
          ? MICROSITE_STATE.DATA_NOT_FOUND
          : MICROSITE_STATE.ERROR,
      );
  }

  return response.toObject();
};
