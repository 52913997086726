/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/prop-types */
/* eslint-disable dot-notation */
import React from 'react';

import {
  AlchemistClient,
  AlchemistTracker,
  AlchemistNavigator,
  AlchemistClientProvider,
  uiComponentRepository,
} from '@pedidosya/web-alchemist';

const AlchemistProvider = ({ registry, children, tracker }) => {
  const history = { navigateToUrl: () => {} };

  if (registry && Array.isArray(registry)) {
    registry.forEach((data) => {
      uiComponentRepository[data.id] = () => ({
        component: data.component,
      });
    });
  }

  const alchemistNavigator = new AlchemistNavigator(history);
  const alchemistTracker = new AlchemistTracker(tracker);
  const alchemistClient = new AlchemistClient({
    uiComponentRepository,
    alchemistNavigator,
    alchemistTracker,
    fenix_flag: false,
  });

  return <AlchemistClientProvider client={alchemistClient}>{children}</AlchemistClientProvider>;
};

export default AlchemistProvider;
