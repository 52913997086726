import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { initClientSideMicrositeData } from './components/Microsite/utils/microsite-data';
import initLogger from './components/Microsite/utils/microsite-data/init-logger';
import { setupSentry } from './utils/sentry';

const RootCSR = ({ children, serverSideProps }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    let mounted = true;

    setLoading(true);
    initClientSideMicrositeData(serverSideProps)
      .then((response) => {
        if (mounted) {
          if (!response.status) {
            if (response?.data) {
              setData(response.data);
            }
            return setError(response.code);
          }

          setData(response.data);
          initLogger(response.data?.context);
          setupSentry(response.data?.context.environment);
        }
      })
      .catch((e) => {
        if (mounted) {
          setError(e.message);
        }
      })
      .finally(() => mounted && setLoading(false));

    return () => {
      mounted = false;
    };
  }, [serverSideProps]);

  return children({ data, error, loading });
};

RootCSR.propTypes = {
  serverSideProps: PropTypes.object.isRequired,
  children: PropTypes.func.isRequired,
};

export default RootCSR;
