import React, { useEffect } from 'react';
import { getLogger } from '@app/logger';
import { Image } from '@pedidosya/order-status-components';
import errorImage from '@app/assets/icons/error.svg';
import defaultMessages from '../../messages';
import ErrorPageLoader from '../ui/organisms/ErrorPage/ErrorPageLoader';

const ErrorBoundary = ({ error, componentStack }) => {
  useEffect(() => {
    getLogger().error(`[APP_ERROR] ${error.toString()} componentStack: ${componentStack}`);
  }, [componentStack, error, error.stack]);

  return (
    <ErrorPageLoader
      anchoredToParent
      title={defaultMessages.micrositeErrorTitle.defaultMessage}
      description={defaultMessages.micrositeErrorDescription.defaultMessage}
      IconComponent={
        <Image src={errorImage} alt={defaultMessages.micrositeErrorTitle.defaultMessage} />
      }
    ></ErrorPageLoader>
  );
};

export default ErrorBoundary;
