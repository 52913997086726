export const ACTION_TYPE = {
  NAVIGATE: 'navigate',
  TRIGGER: 'trigger',
  SHOW_SNACKBAR: 'showSnackbar',
};

export const ACTION_TRIGGER = {
  CLICKED: 'CLICKED',
  LOADED: 'LOADED',
  SUCCEEDED: 'SUCCEEDED',
  FAILED: 'FAILED',
  SWIPED: 'SWIPED',
};

function buildActionKey(actionId, actionTrigger) {
  return (actionId ?? 'unknown') + (actionTrigger ? '_' + actionTrigger : '');
}

export const extractActions = (alchemistProps) => {
  let actionsMap = {};
  const actions = alchemistProps?.actions;
  if (actions && Array.isArray(actions)) {
    actionsMap = actions.reduce((accum, action) => {
      if (action?.id && action?.properties) {
        accum[buildActionKey(action.id, action?.trigger)] = action.properties;
      }
      return accum;
    }, {});
  }

  const get = (actionId, actionTrigger = ACTION_TRIGGER.CLICKED) => {
    return actionsMap[buildActionKey(actionId, actionTrigger)] ?? null;
  };

  return {
    get,
  };
};

export function getAction(alchemistProps, actionType) {
  const actions = alchemistProps.actions;
  if (actions && Array.isArray(actions)) {
    const action = actions.find((action) => action.id === actionType) || null;
    if (action) {
      return action.properties;
    }
  }
  return null;
}
