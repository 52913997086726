import styled from 'styled-components';

export const OSDesktopLayout = styled.div.attrs(() => ({
  id: 'main-layout',
}))`
  --eta-border-color: #ededed;

  width: 100%;
  height: 100%;
  max-height: 900px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: no-wrap;
  box-shadow: 0 0 6px 1px #00000024;
  border-radius: 5px;
  margin-top: 20px;

  /*
      the background color for the non map area is changed 
      because in desktop, the floating components are positioned
      inside the left data column (stacked).
    */
  background-color: #fff;

  & #bottomsheet-floating-components {
    width: 378px;
    & #progressBarComponent-VerticalStack > div,
    & #progressBarComponentUpdated2-VerticalStack > div {
      padding: 0;
    }
    & #floating-eta-issues {
      width: 100%;
      position: absolute;
      z-index: 8;
      background-color: #fafafa;
      top: 101%;
      box-shadow: rgb(16 4 35 / 12%) 0px 8px 12px;
      border-radius: 0px 0px 12px 12px;
      border-bottom: 2px solid var(--eta-border-color);
      padding-bottom: 8px;
      /* border-left: 2px solid var(--eta-border-color);
      border-right: 2px solid var(--eta-border-color); */
    }
  }

  & #progressBarComponent-VerticalStack > div,
  & #progressBarComponentUpdated2-VerticalStack > div {
    border-radius: 0;
    box-shadow: none;
  }

  & #pillContainer-LeftRightPlaceHolder {
    position: absolute;
    top: 20px;
    width: 100%;
    .placeholder-left-children {
      /* position: absolute; */
      margin-left: 400px;
    }
    .placeholder-right-children {
      /* position: absolute; */
      margin-right: 16px;
    }
  }
`;

export const BottomSheetFloatingComponentsContainer = styled.div.attrs(() => ({
  id: 'bottomsheet-floating-components',
}))`
  z-index: 2;
  width: 100%;
  & > div > * {
    padding: 0;
  }
`;
