import React from 'react';
import {
  Container,
  ALIGNMENT,
  FLOW_DIRECTION,
  ClickableElement,
} from '@pedidosya/order-status-components';

function FullDetailAmountLayout({
  titleComponent,
  subtitleComponent,
  imageSectionComponent,
  actionSectionComponent,
  onActionClicked,
  wrapperContainerProps,
  childrenContainerProps,
  children,
}) {
  const showInfoRow = titleComponent && subtitleComponent;
  const infoRowStyles = { padding: [0, 0, 27, 0] };
  return (
    <ClickableElement
      data-testid="clickable-detail"
      onClick={onActionClicked}
      enabled
      overShadow={false}
    >
      <Container
        fitContent
        direction={FLOW_DIRECTION.ROW}
        expanded
        vAlignment={ALIGNMENT.CENTER}
        {...wrapperContainerProps}
      >
        <Container
          fillSpace
          width={40}
          height={40}
          direction={FLOW_DIRECTION.COLUMN}
          hAlignment={ALIGNMENT.START}
          vAlignment={ALIGNMENT.CENTER}
          fitContent
        >
          {showInfoRow && (
            <Container direction={FLOW_DIRECTION.ROW} expanded fitContent {...infoRowStyles}>
              <Container
                data-testid="vendor-data-container"
                direction={FLOW_DIRECTION.COLUMN}
                fillSpace
              >
                {titleComponent}
                {subtitleComponent}
              </Container>

              {imageSectionComponent && (
                <Container hAlignment={ALIGNMENT.END}>{imageSectionComponent}</Container>
              )}
            </Container>
          )}
          <Container
            direction={FLOW_DIRECTION.ROW}
            vAlignment={ALIGNMENT.CENTER}
            expanded
            {...childrenContainerProps}
          >
            {children}
          </Container>
        </Container>
        {actionSectionComponent && (
          <Container
            data-testid="actions-container"
            direction={FLOW_DIRECTION.COLUMN}
            hAlignment={ALIGNMENT.CENTER}
            vAlignment={ALIGNMENT.CENTER}
            fitContent
          >
            {actionSectionComponent}
          </Container>
        )}
      </Container>
    </ClickableElement>
  );
}

FullDetailAmountLayout.propTypes = {
  // title: PropTypes.shape(textPropTypes).isRequired,
  // sub_title: PropTypes.shape(textPropTypes).isRequired,
};

export default FullDetailAmountLayout;
