import 'core-js/features/object/from-entries';
import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import pkg from '../package.json';
import Root from './root.component';
import MicrositeWrapper from './components/Microsite/MicrositeWrapper';
import errorImage from '@app/assets/icons/error.svg';
import ErrorPage from './components/ui/organisms/ErrorPage/ErrorPageLoader';
import { Image } from '@pedidosya/order-status-components';
import messages from './messages';

const rootId = `single-spa-application:${pkg.name}`;
let domElement = document.getElementById(rootId);

if (!document.body.contains(domElement)) {
  domElement = document.createElement('section');
  domElement.id = rootId;
  document.body.appendChild(domElement);
}

const lifecycles = singleSpaReact({
  domElementGetter: () => domElement,
  React,
  ReactDOM,
  renderType: () => (domElement.hasChildNodes() ? 'hydrate' : 'render'),
  rootComponent: Root,
  errorBoundary: (error) => (
    <MicrositeWrapper>
      <ErrorPage
        anchoredToParent
        title={messages.micrositeErrorTitle.defaultMessage}
        description={messages.micrositeErrorDescription.defaultMessage}
        IconComponent={<Image src={errorImage} alt={messages.micrositeErrorTitle.defaultMessage} />}
      ></ErrorPage>
    </MicrositeWrapper>
  ),
});

export const mount = [
  async (props) => {
    const tracker = await props?.trackerPromise?.catch(() => undefined);
    lifecycles.mount({ ...props, tracker });
  },
  async () => domElement.setAttribute('data-hydrated', 'true'),
];

export const { bootstrap, unmount } = lifecycles;
