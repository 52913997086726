import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ALIGNMENT,
  Container,
  FLOW_DIRECTION,
  useSSR,
  Tabs,
} from '@pedidosya/order-status-components';
import { getAlchemistContextData } from '@app/alchemist/utils/context-data/alchemist-context-data';
import ComponentGroupRenderer from '@app/alchemist/ComponentGroupRenderer';
import styled from 'styled-components';
import SessionStorage from '@app/services/cache/SessionStorage';
import { TRACKING_TRIGGERS } from '@app/tracking';

const SELECTED_TAB_CACHE_KEY = 'TABS_DATA';
const cache = new SessionStorage();

const TabBody = styled(Container).attrs(() => ({
  direction: FLOW_DIRECTION.COLUMN,
  expanded: true,
}))`
  width: 100%;
  margin-top: 57px;
  position: relative;

  & > * {
    width: 100%;
  }
`;

function getTabIndex(currentTabIndex, orderId) {
  const cachedData = cache.get(SELECTED_TAB_CACHE_KEY);
  const userTabIndex = cachedData?.tabIndex;
  const prevTabIndex = cachedData?.prevTabIndex;

  return prevTabIndex != currentTabIndex
    ? currentTabIndex
    : userTabIndex !== undefined
    ? userTabIndex
    : currentTabIndex;
}

function saveTabIndex(currentTabIndex, prevTabIndex, orderId) {
  cache.set(SELECTED_TAB_CACHE_KEY, { orderId, tabIndex: currentTabIndex, prevTabIndex });
}

function SegmentedControl(props) {
  const { ssr } = useSSR();
  const alchemistContext = getAlchemistContextData();
  const activeTabIndex = getTabIndex(
    alchemistContext.getActiveTabIndex(),
    alchemistContext.getOrderId(),
  );

  const allComponents = alchemistContext.getComponentList();
  const tabsComponentIds = alchemistContext.getTabsComponentIdList();
  const tabsDataList = props.componentData.getContentProp('options', []);

  const onTabClicked = (tabIndex) => {
    saveTabIndex(tabIndex, alchemistContext.getActiveTabIndex(), alchemistContext.getOrderId());
    props.componentData.getTracking().trackAllByTrigger(TRACKING_TRIGGERS.CLICKED);
    // setFooterComponentIds(alchemistContext.getTabFixedBottomComponentIdList(tabIndex));
  };

  const tabList = tabsDataList.map((tab, index) => {
    return {
      text: tab.title,
      contentId: `tab${tab.index}`,
      active: activeTabIndex === index,
      BottomComponent: () => {
        const footerComponentIds = alchemistContext.getTabFixedBottomComponentIdList(index);
        return (
          footerComponentIds && (
            <Container
              id={'tab-footer-' + activeTabIndex}
              direction={FLOW_DIRECTION.COLUMN}
              hAlignment={ALIGNMENT.CENTER}
              bgColor={'#FFF'}
              expanded
              style={{
                width: '100%',
                padding: '7px',
              }}
            >
              <ComponentGroupRenderer
                componentIdentifiers={footerComponentIds}
                componentList={allComponents}
                renderAllIfNoIdentifiers={false}
              >
                {({ componentsOutput, error }) => (error ? undefined : componentsOutput)}
              </ComponentGroupRenderer>
            </Container>
          )
        );
      },
      Component: () => {
        const componentIds = tabsComponentIds[index];

        return (
          <TabBody>
            <ComponentGroupRenderer
              componentIdentifiers={componentIds}
              componentList={allComponents}
              renderAllIfNoIdentifiers={false}
            >
              {({ componentsOutput, error }) => (error ? undefined : componentsOutput)}
            </ComponentGroupRenderer>
          </TabBody>
        );
      },
    };
  });

  const [shouldPinContainer, setShouldPinContainer] = useState(false);

  const tabStyles = {
    position: 'absolute',
    top: 0,
    borderBottom: '1px solid #ccc',
    backgroundColor: '#FFF',
    zIndex: 3,
    width: '96%',
    maxWidth: '650px',
  };

  const tabBottomContainerStyles = {
    position: 'absolute',
    maxWidth: '100%',
    top: 'auto',
    bottom: '0',
    left: 0,
    width: '100%',
  };

  if (shouldPinContainer) {
    tabStyles.top = '36px';
    tabStyles.position = 'fixed';
    tabBottomContainerStyles.position = 'fixed';
  }

  useEffect(() => {
    let mounted = true;
    if (!ssr && !props.globals.isDesktop && tabStyles.position != 'fixed') {
      setTimeout(() => mounted && setShouldPinContainer(true), 1000);
    }

    return () => {
      mounted = false;
    };
  }, [ssr]);

  const tabContainerStyles = { position: 'relative' };

  if (props?.globals?.isDesktop) {
    tabContainerStyles.position = 'static';
  }
  return (
    <Tabs
      onTabClicked={onTabClicked}
      tabsContainerStyles={tabContainerStyles}
      tabsHeaderContainerStyles={tabStyles}
      tabList={tabList}
      tabsBottomContainerStyles={tabBottomContainerStyles}
    />
  );
}

SegmentedControl.propTypes = {};

export default SegmentedControl;
