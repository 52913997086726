export const PLATFORM_NAME = {
  PEDIDOS_YA: 'PedidosYa',
  APPETITO_24: 'Appetito24',
  DOMICILIOS: 'Domicilios',
};

export const PLATFORM_KEY = {
  PEDIDOS_YA: 'pedidosya',
  APPETITO_24: 'appetito',
  DOMICILIOS: 'domicilios',
};

const PLATFORMS = {
  pedidosya: {
    id: 1,
    faviconDir: '',
    name: PLATFORM_NAME.PEDIDOS_YA,
    key: PLATFORM_KEY.PEDIDOS_YA,
    appleStoreUrl:
      'https://itunes.apple.com/uy/app/pedidosya-delivery-de-comida/id490099807',
    googlePlayId: 'com.pedidosya',
    googlePlayUrl:
      'https://play.google.com/store/apps/details?id=com.pedidosya',
    logo: 'https://img.pystatic.com/social_image.png',
    sameAs: [
      'https://twitter.com/@pedidosya',
      'https://www.facebook.com/pedidosya/',
      'https://www.linkedin.com/company/pedidosya.com/',
      'https://www.youtube.com/user/pedidosya',
    ],
    fbAppID: '342906170186',
    ogDescription:
      'En PedidosYa puedes dejar volar tu imaginación porque ahora todo lo que quieras lo llevamos directo desde tu cabeza a donde estés, ¡en minutos!\n¿Lo quieres? ¡Lo tienes! Pide a los mejores restaurantes, haz el pedido del súper, compra la comida para tu mascota, la bebida para los amigos, pide lo que necesites de la farmacia y ¡mucho más!\nEn simples pasos puedes tener lo que quieras directamente en tu puerta: Descubre, pide y recibe a domicilio con PedidosYa.              ',
    ogTitle: 'Todo lo que necesites, ¡te lo llevamos!',
  },
  appetito: {
    id: 14,
    faviconDir: 'whitelabel/appetito24/',
    name: PLATFORM_NAME.APPETITO_24,
    key: PLATFORM_KEY.APPETITO_24,
    appleStoreUrl:
      'https://itunes.apple.com/uy/app/appetito24-comida-delivery/id972198238',
    googlePlayId: 'com.mobmedianet.appetito24',
    googlePlayUrl:
      'https://play.google.com/store/apps/details?id=com.mobmedianet.appetito24',
    logo: 'https://img.pystatic.com/whitelabel/appetito24/social_image.png',
    sameAs: [
      'https://twitter.com/@appetito24',
      'https://www.facebook.com/appetito24/',
      'https://www.linkedin.com/company/appetito24',
      'https://www.youtube.com/channel/UCRCvG6SaKptjwPVx-jA-5jw',
    ],
    fbAppID: '1642268992519658',
    ogDescription:
      'Delivery de Comida Online - Tu Comida a Domicilio | Appetito24',
    ogTitle: 'Delivery de Comida Online - Tu Comida a Domicilio | Appetito24',
  },
  domicilios: {
    id: 15,
    faviconDir: 'whitelabel/domicilios/',
    name: PLATFORM_NAME.DOMICILIOS,
    key: PLATFORM_KEY.DOMICILIOS,
    appleStoreUrl:
      'https://apps.apple.com/us/app/domicilios-com-pide-domicilio/id611358746',
    googlePlayId: 'com.clickdelivery.android.domiciliosbogota',
    googlePlayUrl:
      'https://play.google.com/store/apps/details?id=com.clickdelivery.android.domiciliosbogota',
    logo: 'https://img.pystatic.com/whitelabel/domicilios/social_image.png',
    sameAs: [
      'https://twitter.com/DomiciliosCol',
      'https://www.facebook.com/DomiciliosCol/',
      'https://www.linkedin.com/company/domicilios-com',
      'https://www.youtube.com/user/DomiciliosBog',
    ],
    fbAppID: '117521928319978',
    ogDescription:
      'Descubre Opciones con Delivery en tu Barrio con Domicilios.com, Escoge lo que Quieras Hoy y Disfruta tu Pedido en Donde Estés.',
    ogTitle: 'Pide Domicilio y Recibe tu Pedido Donde Estés | Domicilios.com',
  },
};

export default PLATFORMS;
