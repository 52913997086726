import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.modules.orderstatus.header',
    defaultMessage: 'Tu pedido a {partner}',
  },
  defaultHeader: {
    id: 'app.orders.orderstatus.defaultHeader',
    defaultMessage: 'Tu pedido',
  },
  noDetailTitle: {
    id: 'app.orders.orderstatus.detail.loadingError.title',
    defaultMessage: 'Detalles no disponibles',
  },
  noDetailDescription: {
    id: 'app.orders.orderstatus.detail.loadingError.description',
    defaultMessage:
      'No te preocupes, todo sigue bien con tu pedido. Estamos trabajando para mostrarte los detalles a la brevedad.',
  },
  retry: {
    id: 'app.orders.orderstatus.actions.retry',
    defaultMessage: 'Reintentar',
  },
});
