import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  ALIGNMENT,
  FLOW_DIRECTION,
  HorizontalSeparator,
  toFenixToken,
} from '@pedidosya/order-status-components';
import styled from 'styled-components';

const ChildrenContainer = styled(Container)`
  & > * {
    ${({ spaceBetweenChild, direction }) =>
      spaceBetweenChild
        ? direction === FLOW_DIRECTION.ROW
          ? 'margin-right: 4px;'
          : 'margin-bottom: 4px'
        : ''}
    ${({ expandChildren }) => (expandChildren ? 'width: 100%;' : '')}
    ${({ spacing }) => (spacing ? `padding: ${spacing}px 0;` : '')}
  }
`;

const BaseContainer = forwardRef(
  (
    {
      direction,
      defaultPadding,
      defaultMargin,
      hAlignment,
      vAlignment,
      expandChildren,
      spaceBetweenChild,
      ...alchemistProps
    },
    ref,
  ) => {
    const spacing = alchemistProps.componentData.getSpacing();
    const margin = alchemistProps.componentData.getStyles().getMargin(defaultMargin || [0]);
    const padding = alchemistProps.componentData.getStyles().getPadding(defaultPadding || [0]);
    const dividerProps = alchemistProps.componentData.getStyles().getDividerProps();
    const hasRoundedBorders =
      alchemistProps.componentData.getContentProp('rounded_borders', false) ||
      alchemistProps.componentData.getContentProp('border_radius', false);
    let style = {};
    let borderRadius = 0;

    // Esto es turbio del lado de back, para indicarte que hay sombras,
    // te tenes que basar en si el borde es redondeado (inserte meme de explosion mental aca).
    if (hasRoundedBorders) {
      style.boxShadow = '0px 12px 24px rgba(16, 4, 35, 0.12)';
      borderRadius = 12;
    }

    const bgColor =
      alchemistProps.componentData.getContentProp('background_color', '') ||
      toFenixToken(alchemistProps.componentData.getContentProp('content_color', 'transparent'));

    const mappedProps = {
      padding,
      spacing,
      margin,
      direction: direction || FLOW_DIRECTION.COLUMN,
      hAlignment: hAlignment || ALIGNMENT.CENTER,
      expanded: true,
      borderRadius,
      bgColor,
    };

    return (
      <>
        <ChildrenContainer
          data-testid="stack-child-container"
          ref={ref}
          {...mappedProps}
          style={style}
          expandChildren={expandChildren}
          spaceBetweenChild={spaceBetweenChild}
        >
          {alchemistProps.children}
        </ChildrenContainer>
        {dividerProps && (
          <Container expanded padding={dividerProps?.padding}>
            <HorizontalSeparator
              color={
                dividerProps?.borderColor?.length > 0 ? dividerProps.borderColor[0] : undefined
              }
              height={dividerProps?.height}
            />
          </Container>
        )}
      </>
    );
  },
);

BaseContainer.displayName = 'BaseContainer';

BaseContainer.propTypes = {
  direction: PropTypes.oneOf(Object.values(FLOW_DIRECTION)),
  hAlignment: PropTypes.oneOf(Object.values(ALIGNMENT)),
  vAlignment: PropTypes.oneOf(Object.values(ALIGNMENT)),
  expandChildren: PropTypes.bool,
};

BaseContainer.defaultProps = {
  direction: FLOW_DIRECTION.COLUMN,
  hAlignment: ALIGNMENT.CENTER,
  vAlignment: ALIGNMENT.CENTER,
  expandChildren: false,
};

export default BaseContainer;
