import { defineMessages } from 'react-intl';

export default defineMessages({
  micrositeErrorTitle: {
    id: 'app.microsite.error.title',
    defaultMessage: 'Tuvimos un problema',
  },
  micrositeErrorDescription: {
    id: 'app.microsite.error.description',
    defaultMessage: '¡Puede fallar! No pudimos llevarte a donde esperabas.',
  },
  micrositeCancellationTitle: {
    id: 'app.microsite.cancellation.title',
    defaultMessage: 'Orden cancelada',
  },
  micrositeCancellationDescription: {
    id: 'app.microsite.cancellation.description',
    defaultMessage: 'Es posible que estes accediendo a una orden que fue cancelada.',
  },
  micrositeCompletedTitle: {
    id: 'app.microsite.completed.title',
    defaultMessage: 'Orden completada',
  },
  micrositeCompletedDescription: {
    id: 'app.microsite.completed.description',
    defaultMessage:
      'La orden a la que accediste ya se encuentra completada, no pudimos redirigirte al inicio.',
  },
});
