import React, { useEffect, useRef, useState, useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
// import Lottie from 'lottie-react';
// import { Lottie } from '@alfonmga/react-lottie-light-ts';
import lottie from 'lottie-web-light';
// const AnimationContainer = styled.div`
//   & svg {
//     width: auto;
//     height: auto;
//   }
// `;
// TODO: temporary implementation until lottie-react v3 is released
const AnimationSecuence = ({ animationData, frameSegments, play, styles }) => {
  const animBox = useRef(null);
  useEffect(() => {
    if (animBox?.current) {
      let animObj = lottie.loadAnimation({
        container: animBox.current,
        renderer: 'svg',
        loop: true,
        autoplay: play,
        animationData: animationData,
      });
    }
  }, [animBox, animationData, play]);

  return <div style={styles} ref={animBox}></div>;
};

// const AnimationSecuence = ({ animationData, frameSegments, play, styles }) => {
//   const lottieRef = useRef(null);
//   const [currentSegment, setCurrentSegment] = useState(0);
//   const [animationOptions] = useState({
//     animationData,
//     autoplay: false,
//     loop: true,
//   });

//   const getFrame = (segmentData) => {
//     let start = 'start';
//     let end = 'end';
//     let startFrameDefaultValue = 0;
//     let endFrameDefaultValue = animationData?.op;
//     if (segmentData.reverse) {
//       start = 'end';
//       end = 'start';
//       startFrameDefaultValue = endFrameDefaultValue;
//       endFrameDefaultValue = 0;
//     }

//     return [segmentData[start] || startFrameDefaultValue, segmentData[end] || endFrameDefaultValue];
//   };

//   const shouldUpdateAnimation = useCallback(
//     () => lottieRef && lottieRef.current && play && frameSegments && frameSegments.length > 0,
//     [lottieRef, play, frameSegments],
//   );

//   const onAnimationFinished = useCallback(() => {
//     console.log('====================================');
//     console.log('onAnimationFinished CALLED!');
//     console.log('====================================');
//     if (frameSegments) {
//       const currentSegmentData = frameSegments[currentSegment];
//       if (currentSegmentData.loop) {
//         const frame = getFrame(currentSegmentData);
//         lottieRef.current.playSegments(frame, true);
//       } else {
//         const nextSegment = currentSegment + 1;
//         if (nextSegment < frameSegments.length) {
//           setCurrentSegment(nextSegment);
//         }
//       }
//     }
//   }, [currentSegment, frameSegments]);

//   useEffect(() => {
//     setCurrentSegment(0);
//   }, [frameSegments]);

//   useEffect(() => {
//     if (frameSegments && shouldUpdateAnimation()) {
//       const currentSegmentData = frameSegments[currentSegment];
//       if (currentSegmentData) {
//         lottieRef.current.setDirection(currentSegmentData.reverse ? -1 : 1);
//         lottieRef.current.setSpeed(currentSegmentData.speed || 1);

//         lottieRef.current.playSegments(getFrame(currentSegmentData), true);
//       }
//     }
//   }, [currentSegment, animationData, play, frameSegments, shouldUpdateAnimation]);

//   const lottieContainerStyles = { ...styles, minHeight: '110px' };

//   return (
//     <AnimationContainer className="animation-secuence" style={lottieContainerStyles}>
//       <Lottie
//         lottieRef={lottieRef}
//         playingState={play ? 'playing' : 'paused'}
//         onComplete={onAnimationFinished}
//         config={animationOptions}
//       />
//     </AnimationContainer>
//   );
// };

AnimationSecuence.propTypes = {
  animationData: PropTypes.object.isRequired,
  frameSegments: PropTypes.arrayOf(
    PropTypes.shape({
      start: PropTypes.number,
      end: PropTypes.number,
      loop: PropTypes.bool,
      reverse: PropTypes.bool,
      speed: PropTypes.number,
    }),
  ),
  play: PropTypes.bool,
  styles: PropTypes.object,
};

AnimationSecuence.defaultProps = {
  styles: {},
  play: true,
};

export default AnimationSecuence;
