import { DEVICE_STRING, isWebviewPlatform } from '@app/utils/platform';
import { getMobileOperatingSystem, isAndroid, isIOS } from '@app/utils/userAgent/getMobileOS';

export function getPlaform(platformKey) {
  let platform = null;
  if (!platformKey) {
    const deviceOS = getMobileOperatingSystem();
    platform = isAndroid(deviceOS)
      ? DEVICE_STRING.ANDROID
      : isIOS(deviceOS)
      ? DEVICE_STRING.IOS
      : 'web';
  } else {
    platform = isWebviewPlatform(platformKey) ? platformKey : 'web';
  }

  return platform;
}
