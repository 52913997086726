import React from 'react';
import PropTypes from 'prop-types';
import { ButtonV2 } from '@pedidosya/order-status-components';
import { ACTION_TYPE } from '@app/alchemist/utils/actions';
import crossPlatformEvents from '@app/services/event/platform/cross-platform-events';
import { TRACKING_TRIGGERS } from '@app/tracking';
import { getLogger } from '@app/logger';
import { goToTargetUrl } from '@app/utils/url';

const Button = (props) => {
  let size = props.componentData.getContentProp('size', 'small');
  const hierarchy = props.componentData.getContentProp('hierarchy', 'primary');
  const text = props.componentData.getContentProp('text');

  const onClick = () => {
    props.componentData.getTracking().trackAllByTrigger(TRACKING_TRIGGERS.CLICKED);
    const triggerEvent = props.componentData.getAction(ACTION_TYPE.NAVIGATE);
    getLogger().warn(`Button targetUrl: ${triggerEvent?.targetUrl}`);
    goToTargetUrl(triggerEvent, crossPlatformEvents.goToUrl);
  };

  return <ButtonV2 onClick={onClick} label={text} hierarchy={hierarchy} size={size} />;
};

Button.propTypes = {
  onClick: PropTypes.func,
};

Button.defaultProps = {
  onClick: () => {},
};

export default Button;
