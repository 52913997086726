import { isEmpty } from '@app/utils/string';
import { extractComponentIdentifiers } from '../../utils';

function getPageContent(order) {
  return order?.page_content;
}

function getTabsContext(order) {
  return getPageContent(order)?.context?.bottom_sheet?.tabs;
}

class AlchemistContextOne {
  /**
   *
   * @param {import('../../alchemist-context-data').Order} order
   */
  constructor(order) {
    this.order = order;

    let bsBodyComponentIdList = extractComponentIdentifiers(
      this.getPageContext(this.order)?.bottom_sheet,
      'component_identifiers',
    );
    const bsDockedComponentIdList = extractComponentIdentifiers(
      this.getPageContext(this.order)?.bottom_sheet,
      'floating_component_identifiers',
    );
    const bsTopComponentIdList = extractComponentIdentifiers(
      this.getPageContext(this.order)?.bottom_sheet,
      'top_component_identifiers',
    );

    let mapTopComponentIdList = [];
    let mapBottomComponentIdList = [];
    if (this.isMapActive()) {
      mapTopComponentIdList = extractComponentIdentifiers(
        this.getPageContext(this.order)?.map,
        'top_component_identifiers',
      );

      mapBottomComponentIdList = extractComponentIdentifiers(
        this.getPageContext(this.order)?.map,
        'bottom_component_identifiers',
      );
    }

    let tabsTopComponentIdList = [];
    let tabsBodyComponentIdList = [];
    let tabsBottomComponentIdList = [];
    if (this.hasTabs()) {
      const tabs = getTabsContext(this.order);
      tabsTopComponentIdList = extractComponentIdentifiers(tabs, 'top');
      tabsBottomComponentIdList = extractComponentIdentifiers(tabs, 'bottom');
      const body = extractComponentIdentifiers(tabs, 'children');

      if (body && Array.isArray(body) && body.length > 0) {
        tabsBodyComponentIdList = body
          .sort((a, b) => a.index - b.index)
          .map((tabData) => tabData.component_identifiers);
      }
      bsBodyComponentIdList = tabsTopComponentIdList;
    }

    /**
     * @typedef {Object} BSComponentIdList
     * @property {string[]} docked - List of docked component IDs.
     * @property {string[]} top - List of top component IDs.
     * @property {string[]} tabs - List of tab component IDs.
     * @property {string[]} body - List of body component IDs.
     * @property {string[]} fixedTabBottom - List of fixed tab bottom component IDs.
     */

    /**
     * @typedef {Object} MapComponentIdList
     * @property {string[]} top - List of top component IDs.
     * @property {string[]} bottom - List of bottom component IDs.
     */

    /**
     * @typedef {Object} ComponentIdList
     * @property {BSComponentIdList} bs - Bottomsheet components ID lists.
     * @property {MapComponentIdList} map - Map components ID lists.
     */
    /**
     * @type {ComponentIdList}
     */
    this.componentIdList = {
      bs: {
        docked: bsDockedComponentIdList,
        top: bsTopComponentIdList,
        tabs: tabsBodyComponentIdList,
        body: bsBodyComponentIdList,
        fixedTabBottom: tabsBottomComponentIdList,
      },
      map: {
        top: mapTopComponentIdList,
        bottom: mapBottomComponentIdList,
      },
    };
  }

  /**
   *
   * @param {import('../../alchemist-context-data').Order} order
   * @returns
   */
  static matchVersion(order) {
    return Boolean(getTabsContext(order));
  }

  getOrderId() {
    return this.order?.order_id || this.order?.orderId || null;
  }

  getPageContext() {
    return getPageContent(this.order)?.context;
  }

  isOrderCancelledPageType() {
    return !isEmpty(this.order?.page_type) && this.order?.page_type === 'CANCELLED_ORDER_PAGE';
  }

  isOrderCompletedPageType() {
    return !isEmpty(this.order?.page_type) && this.order?.page_type === 'COMPLETED_ORDER_PAGE';
  }

  getPageType() {
    return this.order?.page_type || 'unknown';
  }

  isFloatingETAActive() {
    return Boolean(this.getPageContext(this.order)?.bottom_sheet?.top_component_identifiers);
  }

  hasTabs() {
    return Boolean(getTabsContext(this.order));
  }

  isHeaderActive() {
    return !getPageContent(this.order)?.navigation_bar_behavior?.is_hidden || false;
  }

  getPageUrl() {
    return this.getPageContext(this.order)?.web_view_url || null;
  }

  getBottomSheetHeight() {
    return this.getPageContext(this.order)?.bottom_sheet?.height_percentage || 40;
  }

  getDockedComponentIdList() {
    return this.componentIdList.bs.docked;
  }

  getBottomSheetTopComponentIdList() {
    return this.componentIdList.bs.top;
  }

  getMapTopComponentIdList() {
    return this.componentIdList.map.top;
  }

  getMapBottomComponentIdList() {
    return this.componentIdList.map.bottom;
  }

  getBottomSheetComponentIdList() {
    return this.componentIdList.bs.body;
  }

  getTabsComponentIdList() {
    return this.componentIdList.bs.tabs;
  }

  getTabFixedBottomComponentIdList(tabIndex) {
    if (getTabsContext(this.order)?.bottom_trigger_index === tabIndex) {
      return this.componentIdList.bs.fixedTabBottom;
    }

    return [];
  }

  getActiveTabIndex() {
    return getTabsContext(this.order)?.selected_tab?.index || 0;
  }

  getComponentList() {
    const pageContent = getPageContent(this.order);
    return pageContent?.components;
  }

  isMapActive() {
    return Boolean(this.getPageContext(this.order)?.map);
  }

  isNonTrackingOrderPageType() {
    return this.order?.page_type === 'NON_TRACKING_ORDER_PAGE';
  }

  isTrackingOrderPageType() {
    return this.order?.page_type === 'TRACKING_ORDER_PAGE';
  }

  getOrder() {
    return this.order;
  }

  getHeaderData() {
    const navBar = getPageContent(this.order)?.navigation_bar_behavior;
    return {
      title: navBar?.title || '',
    };
  }
}
export default AlchemistContextOne;
