import useIsFirstRender from '@app/hooks/useIsFirstRender';
import { Spinner } from '@pedidosya/order-status-components';
import React from 'react';

const ErrorPageComponent = React.lazy(() => import('./ErrorPage'));

const ErrorPageLoader = (props) => {
  const { isFirstRender } = useIsFirstRender();
  if (!isFirstRender) {
    return (
      <React.Suspense fallback={<Spinner />}>
        <ErrorPageComponent {...props} />
      </React.Suspense>
    );
  } else {
    return <Spinner />;
  }
};

export default ErrorPageLoader;
