import React from 'react';
import AlchemistContentComponent from '@app/alchemist/AlchemistContentComponent';
import { ALIGNMENT, Container, FLOW_DIRECTION } from '@pedidosya/order-status-components';
import Icon from '../Icon';

function InfoArrowComponent(props) {
  return (
    <Container direction={FLOW_DIRECTION.ROW} vAlignment={ALIGNMENT.CENTER} expanded fitContent>
      <Container fillSpace direction={FLOW_DIRECTION.COLUMN} fitContent>
        {props?.children}
      </Container>
      <Container margin={[0, 0, 0, 5]} vAlignment={ALIGNMENT.CENTER} hAlignment={ALIGNMENT.END}>
        <AlchemistContentComponent
          Component={Icon}
          parentProps={props}
          contentPropName="icon_view"
        />
      </Container>
    </Container>
  );
}

export default InfoArrowComponent;
