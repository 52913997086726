import BaseCustomError from '@commons/models/BaseCustomError';

class AxiosError extends BaseCustomError {
  constructor(message, response) {
    super(message);
    this.response = response;
  }
}

export default AxiosError;
