import React from 'react';
import styled from 'styled-components';
import AlchemistContentComponent from '@app/alchemist/AlchemistContentComponent';
import Typography from '../Typography';
import ImageTextActions from './ImageTextActions';
import { Container, FLOW_DIRECTION, Image } from '@pedidosya/order-status-components';

const ChildrenContainer = styled(Container)`
  & > * {
    margin-right: 6px;
  }
  & > *:last-child {
    margin-right: 0;
  }
`;

function PaymentInfoComponent(props) {
  let amountImageData = props.componentData.getImageData('amounts_complement', {
    width: 32,
  });
  const amountProps = props.componentData.getContentProp('amounts');

  const paymentImageMargin = [0, 4, 0, 0];
  const ImageComponent =
    amountImageData && amountImageData?.hasImages()
      ? amountImageData
          .getImageList()
          .map((image, index) => (
            <Image
              key={index}
              src={image.url}
              margin={paymentImageMargin}
              width={32}
              height={20}
              borderSize={[1]}
              borderColor={['#FFF']}
              borderRadius={8}
              fallbackRenderer={image.fallbackComponent}
              fallback={image.fallbackSrc}
            />
          ))
      : null;

  const FirstRowComponent = props?.amounts_complement?.description_label ? (
    <AlchemistContentComponent
      Component={Typography}
      parentProps={props}
      childContentProps={props?.amounts_complement}
      contentPropName="description_label"
    />
  ) : null;
  return (
    <ImageTextActions
      testId="payment-container"
      ImageComponent={ImageComponent}
      FirstRowComponent={FirstRowComponent}
      {...props}
    >
      <ChildrenContainer direction={FLOW_DIRECTION.ROW}>
        <AlchemistContentComponent
          Component={Typography}
          parentProps={props}
          childContentProps={amountProps}
          contentPropName="secondary_label"
        />
        <AlchemistContentComponent
          Component={Typography}
          parentProps={props}
          childContentProps={amountProps}
          contentPropName="primary_label"
        />
      </ChildrenContainer>
    </ImageTextActions>
  );
}

export default PaymentInfoComponent;
