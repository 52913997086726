import React from 'react';
import {
  Image,
  ALIGNMENT,
  Container,
  Text,
  FLOW_DIRECTION,
  useModal,
  EXPAND_OPTIONS,
  useScreenSize,
} from '@pedidosya/order-status-components';
import PinModalView from './PinModalView';
import AlchemistComponent from '@app/alchemist/AlchemistComponent';

const Pill = (props) => {
  return (
    <Container
      borderRadius={60}
      direction={FLOW_DIRECTION.ROW}
      hAlignment={ALIGNMENT.CENTER}
      vAlignment={ALIGNMENT.CENTER}
      bgColor={'#FFF'}
      padding={[6]}
      {...props}
    />
  );
};

const PinButton = (props) => {
  const { isDesktop, isTablet } = useScreenSize();
  const { openModal, closeModal } = useModal();
  const elementsMargin = [0, 8, 0, 0];
  const pinIcons = props.componentData.getImageData('image', { size: 18 });
  const titleTextProps = props.componentData.getTextProps('title');
  const pinTextProps = props.componentData.getTextPropsFrom(
    props.componentData.getContentProp('pin'),
    'value',
  );
  const parentPillStyles = { maxWidth: '300px', minHeight: '48px' };
  const childPillStyles = {
    padding: '4px 12px',
    minHeight: '32px',
    wordSpacing: '5px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  };

  const onButtonClicked = () => {
    const events = props.componentData.getEventList();
    const modalEvent = events.find((event) => event?.id === 'showModal');

    if (modalEvent) {
      openModal({
        title: '',
        content: (
          <AlchemistComponent
            publicEnv={props?.publicEnv}
            globals={props?.globals}
            ssr={props?.publicEnv?.ssr}
            Component={PinModalView}
            alchemistProps={modalEvent?.properties}
            componentProps={{
              onClose: closeModal,
            }}
          />
        ),
        showFloatingCloseButton: false,
        showCloseButton: false,
        expandUntil: isDesktop()
          ? EXPAND_OPTIONS.SIZE_33
          : isTablet()
          ? EXPAND_OPTIONS.SIZE_50
          : EXPAND_OPTIONS.SIZE_75,
      });
    }
  };

  return (
    <Pill
      onClick={onButtonClicked}
      borderSize={[1]}
      borderColor={['#100423']}
      style={parentPillStyles}
      fitContent
    >
      {pinIcons &&
        pinIcons
          .getImageList()
          .map((image) => (
            <Image
              key={image.url}
              src={image.url}
              fallback={image.fallbackSrc}
              width={image.size}
              height={image.size}
              alt={image.alt || 'Info'}
              fallbackRenderer={image.fallbackComponent}
              margin={elementsMargin}
            />
          ))}

      {titleTextProps && (
        <Text {...titleTextProps} margin={elementsMargin}>
          {titleTextProps.text}
        </Text>
      )}
      {pinTextProps && (
        <Pill style={childPillStyles} bgColor={'#100423'}>
          <Text {...pinTextProps}>{pinTextProps.text}</Text>
        </Pill>
      )}
    </Pill>
  );
};

PinButton.propTypes = {};

export default PinButton;
