import React from 'react';
import PropTypes from 'prop-types';
import { TextV2 } from '@pedidosya/order-status-components';

const Typography = (props) => {
  const elementProps = props.componentData.getContentProp(props?.contentPropName, null);

  if (!elementProps) {
    return null;
  }
  return (
    <TextV2 token={elementProps?.typography} color={elementProps?.color}>
      {elementProps?.text}
    </TextV2>
  );
};

Typography.propTypes = {
  contentPropName: PropTypes.string.isRequired,
};

export default Typography;
