import React from 'react';
import PropTypes from 'prop-types';
import { ALIGNMENT, Button, FLOW_DIRECTION } from '@pedidosya/order-status-components';

function BaseButton({
  onClick,
  shadow,
  padding,
  margin,
  borderRadius,
  enabled,
  style,
  children,
  ...alchemistProps
}) {
  const buttonTextColor = 'normal';
  return (
    <Button
      enabled={enabled}
      style={style}
      direction={FLOW_DIRECTION.ROW}
      borderRadius={borderRadius}
      shadow={shadow}
      hAlignment={ALIGNMENT.CENTER}
      vAlignment={ALIGNMENT.CENTER}
      bgColor={alchemistProps.componentData.getStyles().getBgColor('#FFF')}
      padding={padding || alchemistProps.componentData.getStyles().getPadding(padding || [0])}
      margin={margin || alchemistProps.componentData.getStyles().getMargin(margin || [0])}
      textColor={buttonTextColor}
      onClick={onClick}
    >
      {children}
    </Button>
  );
}

BaseButton.propTypes = {
  enabled: PropTypes.bool,
};

BaseButton.defaultProps = {
  enabled: true,
};

export default BaseButton;
