import React from 'react';
import GlobalContexProvider from '@app/providers/GlobalContexProvider/GlobalContexProvider';
import AlchemistProvider from '@app/providers/AlchemistProvider';

const AlchemistContext = ({ children, registry, globals, tracker }) => {
  return (
    <AlchemistProvider registry={registry} tracker={tracker}>
      <GlobalContexProvider context={globals}>{children}</GlobalContexProvider>
    </AlchemistProvider>
  );
};

export default AlchemistContext;
