import {
  ALIGNMENT,
  Button,
  Container,
  FLOW_DIRECTION,
  Image,
  Text,
  TEXT_ALIGNMENT,
} from '@pedidosya/order-status-components';
import React from 'react';

const PinModalView = ({ onClose, ...alchemistProps }) => {
  const titleProps = alchemistProps.componentData.getTextProps('title');
  const subTitleProps = alchemistProps.componentData.getTextProps('sub_title');
  const auxiliarySubTitleProps = alchemistProps.componentData.getTextProps('auxiliary_sub_title');
  const imageMargin = [0, 0, 30, 0];
  const descriptionMargin = [20, 0, 30, 0];
  const buttonTitleProps = alchemistProps.componentData.getTextProps('button_title');
  buttonTitleProps.padding = [12];
  const image = alchemistProps.componentData.getImageData('image', { size: 82 });

  const titleStyles = { lineHeight: '28px' };

  return (
    <Container direction={FLOW_DIRECTION.COLUMN} hAlignment={ALIGNMENT.CENTER}>
      {image?.hasImages() &&
        image
          .getImageList()
          .map((image) => (
            <Image
              key={image.url}
              margin={imageMargin}
              src={image.url}
              fallback={image.fallbackSrc}
              width={image.size}
              height={image.size}
              alt={image.alt || 'Info'}
              fallbackRenderer={image.fallbackComponent}
            />
          ))}
      <Text {...titleProps} textAlign={TEXT_ALIGNMENT.CENTER} style={titleStyles}>
        {titleProps.text}
      </Text>
      <Container
        direction={FLOW_DIRECTION.COLUMN}
        margin={descriptionMargin}
        hAlignment={ALIGNMENT.CENTER}
      >
        <Text {...subTitleProps} textAlign={TEXT_ALIGNMENT.CENTER}>
          {subTitleProps.text}
        </Text>
        <Text {...auxiliarySubTitleProps} textAlign={TEXT_ALIGNMENT.CENTER}>
          {auxiliarySubTitleProps.text}
        </Text>
      </Container>
      <Button {...buttonTitleProps} onClick={onClose} expanded>
        {buttonTitleProps.text.toUpperCase()}
      </Button>
    </Container>
  );
};

export default PinModalView;
