function Response(status = false, data = null, code = '', error = null) {
  let responseStatus = status;
  let responseData = data;
  let responseCode = code;
  let responseError = error;

  function setData(data) {
    responseData = data;
    return this;
  }
  function setStatus(status) {
    responseStatus = status;
    return this;
  }
  function setCode(code) {
    responseCode = code;
    return this;
  }
  function setError(error) {
    responseError = error;
    return this;
  }
  function toObject() {
    return {
      status: responseStatus,
      data: responseData,
      code: responseCode,
      error: responseError,
    };
  }

  return {
    setData,
    setStatus,
    setCode,
    setError,
    toObject,
  };
}

export default Response;
