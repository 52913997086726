import React from 'react';
import PropTypes from 'prop-types';
import { ButtonV2, IconButtonV2, IconV2 } from '@pedidosya/order-status-components';
import { ACTION_TYPE } from '@app/alchemist/utils/actions';
import crossPlatformEvents from '@app/services/event/platform/cross-platform-events';
import { TRACKING_TRIGGERS } from '@app/tracking';

const IconButton = (props) => {
  const iconButtonProps = props.componentData.getContentProp(props?.contentPropName, null);

  if (!iconButtonProps) {
    return null;
  }
  return (
    <IconButtonV2
      onClick={props.onClick}
      icon={
        <IconV2
          token={iconButtonProps?.icon?.name}
          filled={iconButtonProps?.icon?.style === 'filled'}
        />
      }
      size={iconButtonProps?.size}
      withStroke={iconButtonProps?.stroke ? true : false}
      buttonStyle={iconButtonProps?.style}
    />
  );
};

IconButton.propTypes = {
  contentPropName: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default IconButton;
