import React from 'react';
import { ALIGNMENT, Container, FLOW_DIRECTION } from '@pedidosya/order-status-components';
import { getLogger } from '@app/logger';
import ComponentGroupRenderer from '@app/alchemist/ComponentGroupRenderer';
import styled from 'styled-components';
import { OSDesktopLayout } from './OSDesktopLayout';

const Content = styled(Container)`
  padding: 40px 6px 0 6px;
  max-width: 700px;
  overflow-y: auto;
  & > * {
    width: 100%;
  }
`;

function OSDesktopNonTrackingView({
  pageDescriptor,
  bsSnapPoints,
  defaultSnapPoint,
  alchemistContext,
  platformName,
  countryId,
  isMapEnabled,
  hasNotch,
  onLayoutChange,
  hasHeader,
  isNative,
  onBSSnapPointChanged,
}) {
  const hasTopFloatingComponents =
    pageDescriptor?.bottomSheet?.topFloatingComponents &&
    pageDescriptor?.bottomSheet?.topFloatingComponents?.length > 0;
  // because in SSR is not possible to calculate the percentage
  // screen usage of this container.
  const hasDockedComponents =
    pageDescriptor?.bottomSheet?.dockedComponentIds &&
    pageDescriptor?.bottomSheet?.dockedComponentIds?.length > 0;

  const bottomSheetDockedComponentContainerStyles = { padding: [8] };
  return (
    <OSDesktopLayout>
      <Content>
        {hasTopFloatingComponents && (
          <ComponentGroupRenderer
            componentList={pageDescriptor.componentList}
            componentIdentifiers={pageDescriptor.bottomSheet.topFloatingComponents}
          >
            {({ componentsOutput: mapTopChildren, error }) => (error ? undefined : mapTopChildren)}
          </ComponentGroupRenderer>
        )}

        {hasDockedComponents && (
          <Container
            direction={FLOW_DIRECTION.COLUMN}
            hAlignment={ALIGNMENT.CENTER}
            padding={bottomSheetDockedComponentContainerStyles.padding}
          >
            <ComponentGroupRenderer
              componentList={pageDescriptor.componentList}
              componentIdentifiers={pageDescriptor.bottomSheet.dockedComponentIds}
            >
              {({ componentsOutput: bottomSheetFloatingChildren, error }) => {
                return error ? undefined : bottomSheetFloatingChildren;
              }}
            </ComponentGroupRenderer>
          </Container>
        )}
        <ComponentGroupRenderer
          componentList={pageDescriptor.componentList}
          renderAllIfNoIdentifiers={true}
          componentIdentifiers={pageDescriptor.bottomSheet.componentIds}
        >
          {({ componentsOutput: bottomSheetChildren, error }) => {
            if (error) {
              getLogger().error(
                `[ORDER STATE][NON_TRACKING_VIEW] ${alchemistContext.getContextInfoString()} [countryId]: "${countryId}" [error]: ${error}`,
              );
              return <MicrositeErrorLoader />;
            }
            return bottomSheetChildren;
          }}
        </ComponentGroupRenderer>
      </Content>
    </OSDesktopLayout>
  );
}

export default OSDesktopNonTrackingView;
