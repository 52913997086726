import React from 'react';
import AlchemistContentComponent from '@app/alchemist/AlchemistContentComponent';
import Typography from '../Typography';
import {
  ALIGNMENT,
  Container,
  ContainerV2,
  EXPAND_OPTIONS,
  FLOW_DIRECTION,
  Text,
} from '@pedidosya/order-status-components';
import ProductImageList from './ProductImageList';

function ProductInfoComponent(props) {
  const textProps = props.componentData.getContentProp('text');

  return (
    <ContainerV2 direction={FLOW_DIRECTION.ROW} expanded fitContent>
      <Container
        data-testid="vendor-data-container"
        direction={FLOW_DIRECTION.COLUMN}
        vAlignment={ALIGNMENT.CENTER}
        fillSpace
        padding={[0, 0, 10, 0]}
        expandUntil={EXPAND_OPTIONS.SIZE_75}
      >
        <Container padding={[0, 0, 10, 0]}>
          <AlchemistContentComponent
            Component={Typography}
            parentProps={props}
            childContentProps={textProps}
            contentPropName="title_label"
          />
        </Container>
        <AlchemistContentComponent
          Component={Typography}
          parentProps={props}
          childContentProps={textProps}
          contentPropName="sub_title_label"
        />
      </Container>
      <Container direction={FLOW_DIRECTION.COLUMN} vAlignment={ALIGNMENT.CENTER}>
        <AlchemistContentComponent Component={ProductImageList} parentProps={props} />
      </Container>
    </ContainerV2>
  );
}

export default ProductInfoComponent;
