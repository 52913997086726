const { default: BaseCustomError } = require('@commons/models/BaseCustomError');

class HTTPResponse extends BaseCustomError {
  constructor(
    { message, httpCode, data, details } = { message: '', httpCode: null, data: null, details: {} },
  ) {
    super(message);
    this.httpCode = httpCode;
    this.details = details || {};
    this.data = data || null;
  }
}

module.exports = HTTPResponse;
