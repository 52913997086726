import trackerInterceptor from '@app/alchemist/utils/tracker-interceptor';
import { setEnvironment } from '@app/utils/environment';
import getClientQueryParamsObject from '@app/utils/url/parse-query-params';
import allowedMicrositeParamList from '@commons/constants/allowedMicrositeParamList';
import { normalizeCountryShortName } from '@commons/utils/normalization/normalization';
import isObject from '@commons/utils/object/isObject';
import MICROSITE_STATE from '../../constants/microsite-state';
import Response from '../../models/response';
import { initPublicEnv } from './init-public-env';

export const getTranslationMessages = async (locale) => {
  let messages = {};
  try {
    messages = await import(`../../../../../build/translations/${locale}.json`);
  } catch (e) {
    messages = {};
  }

  return messages;
};

function getTrackerFromShellData(initialData) {
  return trackerInterceptor(
    initialData?.tracker || {
      track: () => {
        // eslint-disable-next-line no-console
        console.log('Non tracker instance registered.');
      },
    },
  );
}

function filterMicrositeProps(micrositeProps) {
  return {
    context: micrositeProps?.context || {},
    flags: micrositeProps?.flags || {},
    messages: micrositeProps?.messages || {},
    externalServices: micrositeProps?.externalServices || {},
    order: micrositeProps?.order || null,
    userAddresses: micrositeProps?.userAddresses || [],
    serverException: micrositeProps?.serverException || null,
    queryParams: micrositeProps?.queryParams || null,
  };
}

export async function initClientSideMicrositeData(initialData) {
  const response = new Response();

  try {
    const queryParams = getClientQueryParamsObject(allowedMicrositeParamList);
    //country normalization
    if (queryParams?.c) {
      queryParams.c = normalizeCountryShortName(queryParams.c);
    }

    const contextInfoResponse = await initPublicEnv(
      queryParams?.code,
      queryParams?.c,
      queryParams?.location,
    );

    if (!contextInfoResponse?.status) {
      return contextInfoResponse;
    }
    const clientSideProps = contextInfoResponse?.data;

    if (clientSideProps?.context?.locale) {
      const messages = await getTranslationMessages(clientSideProps?.context?.locale);
      clientSideProps.messages = messages;
    }

    setEnvironment(clientSideProps?.context?.environment);

    const externalServices = {
      fwf: clientSideProps.fwf,
      tracker: getTrackerFromShellData(initialData),
    };
    response
      .setStatus(true)
      .setCode(MICROSITE_STATE.SUCCESS)
      .setData(
        filterMicrositeProps({
          ...initialData,
          ...clientSideProps,
          externalServices,
          queryParams,
        }),
      );
  } catch (e) {
    response
      .setStatus(false)
      .setCode(MICROSITE_STATE.ERROR)
      .setError(`Error getting client side props microsite: ${e.message}`);
  }

  return response.toObject();
}

export function initSSRMicrositeData(initialData) {
  const response = new Response();

  let serverSideProps = {};

  const contextResponse = initialData?.context;

  if (contextResponse && isObject(contextResponse)) {
    serverSideProps.context = contextResponse;
    serverSideProps.order = initialData?.order;
    serverSideProps.flags = initialData?.flags;
    serverSideProps.messages = initialData?.messages;
    setEnvironment(serverSideProps.context?.environment);
  } else {
    response.setStatus(false).setError('Invalid public env').setCode(MICROSITE_STATE.ERROR);
    return response.toObject();
  }

  try {
    const externalServices = {
      fwf: initialData?.fwf,
      tracker: getTrackerFromShellData(initialData),
    };

    response
      .setStatus(true)
      .setCode(MICROSITE_STATE.SUCCESS)
      .setData(
        filterMicrositeProps({
          ...initialData,
          ...serverSideProps,
          externalServices,
        }),
      );
  } catch (error) {
    response.setStatus(false).setCode(MICROSITE_STATE.ERROR).setError(error.message);
  }

  return response.toObject();
}
