import React from 'react';
import Lottie from '../Lottie/Lottie';
import ProgressBarLottiePlaceholder from '../Lottie/placeholder/FullProgressBarLottiePlaceholder';
function BottomSheetProgressBar(props) {
  return (
    <Lottie
      {...props}
      version={props?.globals?.lottieVersion}
      height={113}
      Placeholder={ProgressBarLottiePlaceholder}
    />
  );
}

export default BottomSheetProgressBar;
