import AxiosError from '@app/models/axios-error';

export function isAuthError(response) {
  return response?.status === 403 || response?.status === 401;
}

export function authHandler(response) {
  if (isAuthError(response)) {
    throw new AxiosError('Authentication error', response);
  }
  return response;
}
