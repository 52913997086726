import React, { useRef } from 'react';
import { Text, Slider, useDebounce, Container } from '@pedidosya/order-status-components';
import { TRACKING_TRIGGERS } from '@app/tracking';
import SessionStorage from '@app/services/cache/SessionStorage';
const storage = new SessionStorage();

const BANNER_WIDTH = 318;

const SliderComponent = ({ padding, globals, ...props }) => {
  const SLIDER_CACHE_KEY = 'SliderComponent-' + props.componentData.getId();
  const sliderReady = useRef(false);
  const trackInitialSlide = () => {
    if (sliderReady?.current) {
      const isSameOrder =
        globals?.alchemistContext?.getOrderId() &&
        storage.get(SLIDER_CACHE_KEY) == globals?.alchemistContext?.getOrderId();
      if (!isSameOrder) {
        storage.set(SLIDER_CACHE_KEY, globals?.alchemistContext?.getOrderId());
        props.componentData.getTracking().trackAllByTrigger(TRACKING_TRIGGERS.SWIPED);
      }
    }
  };
  const { debounceTask } = useDebounce(trackInitialSlide);

  if (!globals?.isNative) {
    return null;
  }

  const titleProps = props.componentData.getTextProps('title');

  const onSliderReady = () => {
    sliderReady.current = true;
    debounceTask(1000);
  };

  const onSlide = (indexList) => {
    if (sliderReady.current) {
      props.componentData.getTracking().trackAllByTrigger(TRACKING_TRIGGERS.SWIPED);
    }
  };
  const list = React.Children.toArray(props.componentData.getChildren());
  const spaceBetweenBanners = 6;
  const containerStyles = {
    width: globals?.isMobile ? '100vw' : '400px',
    overflowX: 'hidden',
  };
  const containerPadding = props.componentData.getStyles().getPadding();
  if (containerPadding?.length && containerPadding.length > 3 && containerPadding[3] === 0) {
    containerPadding[3] = 24;
  }
  const titleStyles = { margin: '0 0 8px 0' };

  const renderItem = (item) => item;
  return (
    <Container padding={containerPadding} style={containerStyles}>
      <Text {...titleProps} style={titleStyles} expanded>
        {titleProps.text}
      </Text>
      <Slider
        loop={false}
        initialSlide={0}
        itemsPerView={0}
        itemMinWidth={BANNER_WIDTH}
        showControls={false}
        onLoad={onSliderReady}
        onSlide={onSlide}
        spaceBetweenItems={spaceBetweenBanners}
        itemsList={list}
        itemRenderer={renderItem}
      />
    </Container>
  );
};

SliderComponent.displayName = 'SliderComponent';

export default SliderComponent;
