import React from 'react';
import PropTypes from 'prop-types';
import { Container, ALIGNMENT, FLOW_DIRECTION } from '@pedidosya/order-status-components';
function SimpleDetailLayout({
  titleComponent,
  subtitleComponent,
  imageComponent,
  children,
  dividerComponent,
  reverseContent,
  constainerStyles,
  ...rest
}) {
  const contentStyle = { flexDirection: reverseContent ? 'column-reverse' : 'column' };
  return (
    <Container direction={FLOW_DIRECTION.COLUMN} expanded style={constainerStyles}>
      <Container
        expanded
        direction={FLOW_DIRECTION.ROW}
        hAlignment={ALIGNMENT.START}
        vAlignment={ALIGNMENT.CENTER}
        fitContent
        {...rest}
      >
        {imageComponent && imageComponent}
        <Container style={contentStyle}>
          {titleComponent && titleComponent}
          {subtitleComponent && subtitleComponent}
        </Container>
        <Container fillSpace hAlignment={ALIGNMENT.END} vAlignment={ALIGNMENT.CENTER}>
          {children}
        </Container>
      </Container>
      {dividerComponent && dividerComponent}
    </Container>
  );
}

SimpleDetailLayout.propTypes = {
  titleComponent: PropTypes.node,
  subtitleComponent: PropTypes.node,
  imageComponent: PropTypes.node,
  children: PropTypes.node,
  dividerComponent: PropTypes.node,
  reverseContent: PropTypes.bool,
  constainerStyles: PropTypes.object,
};

SimpleDetailLayout.defaultProps = {
  reverseContent: false,
  constainerStyles: {},
};

export default SimpleDetailLayout;
