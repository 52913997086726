function trackerInterceptor(tracker) {
  const eventMap = {};

  function eventExists(event) {
    return eventMap[event] ? true : false;
  }

  function saveEventMetadata(event, metadata) {
    return (eventMap[event] = metadata);
  }

  function callsLimitReached(metadata) {
    return metadata.callsLimit && metadata.calls >= metadata.callsLimit;
  }

  function getOrCreateEventMetadata(event) {
    if (eventExists(event)) {
      return eventMap[event];
    }
    return { active: true, calls: 0, callsLimit: 0 };
  }

  const setMaxEventCalls = (event, calls) => {
    const eventMetadata = getOrCreateEventMetadata(event);
    eventMetadata.callsLimit = !Number.isNaN(Number(calls)) && calls >= 0 ? Number(calls) : 0;
    saveEventMetadata(event, eventMetadata);
  };

  const setInfiniteEventCalls = (event, calls) => {
    setMaxEventCalls(event, 0);
  };

  const turnOffEvent = (event) => {
    const eventMetadata = getOrCreateEventMetadata(event);
    eventMetadata.active = false;
    saveEventMetadata(event, eventMetadata);
  };
  const turnOnEvent = (event) => {
    const eventMetadata = getOrCreateEventMetadata(event);
    eventMetadata.active = true;
    saveEventMetadata(event, eventMetadata);
  };

  const track = (event, context) => {
    const eventMetadata = getOrCreateEventMetadata(event);

    if (callsLimitReached(eventMetadata)) {
      turnOffEvent(event);
    }

    if (!eventMetadata.active) {
      return;
    }

    eventMetadata.calls += 1;
    saveEventMetadata(event, eventMetadata);
    tracker.track(event, context);
  };

  const getEventCalls = (event) => {
    const eventMetadata = getOrCreateEventMetadata(event);
    return eventMetadata.calls;
  };

  return {
    turnOffEvent,
    turnOnEvent,
    track,
    getEventCalls,
    setMaxEventCalls,
    setInfiniteEventCalls,
  };
}

export default trackerInterceptor;
