import React from 'react';
import Icon from '../Icon';
import ThumbnailImage from '../ThumbnailImage';
import AlchemistContentComponent from '@app/alchemist/AlchemistContentComponent';
import Typography from '../Typography';
import ImageTextActions from './ImageTextActions';
import RowContainerComponent from './RowContainerComponent';

function AddressComponent(props) {
  const imageProps = props.componentData.getContentProp('image', { size: 32 });
  const textProps = props.componentData.getContentProp('text', null);

  const ImageComponent = imageProps?.thumbnail_image ? (
    <AlchemistContentComponent
      Component={ThumbnailImage}
      childContentProps={imageProps}
      parentProps={props}
      contentPropName="thumbnail_image"
    />
  ) : (
    <AlchemistContentComponent
      Component={Icon}
      childContentProps={imageProps}
      parentProps={props}
      contentPropName="icon_view"
    />
  );

  const FirstRowComponent = textProps?.title_label ? (
    <AlchemistContentComponent
      Component={Typography}
      parentProps={props}
      childContentProps={textProps}
      contentPropName="title_label"
    />
  ) : null;

  const SecondRowComponent = textProps?.sub_title_label ? (
    <AlchemistContentComponent
      Component={Typography}
      parentProps={props}
      childContentProps={textProps}
      contentPropName="sub_title_label"
    />
  ) : null;

  return (
    <AlchemistContentComponent
      Component={RowContainerComponent}
      parentProps={props}
      childNode={
        <ImageTextActions
          ImageComponent={ImageComponent}
          FirstRowComponent={FirstRowComponent}
          SecondRowComponent={SecondRowComponent}
          {...props}
        />
      }
    />
  );
}

export default AddressComponent;
