import isObject from '@commons/utils/object/isObject';
import AlchemistContextBroker from './interfaces/broker/alchemist-context-broker';
import AlchemistContextOne from './interfaces/one/alchemist-context-one';

const ALCHEMIST_VERSION = {
  BROKER: 'BROKER',
  A1: 'A1',
};

let instance = null;

/**
 * @typedef {Object} NavigationBarBehavior
 * @property {string} title - The title of the navigation bar.
 * @property {boolean} is_hidden - Indicates if the navigation bar is hidden.
 */

/**
 * @typedef {Object} BottomSheet
 * @property {number} height_percentage - The height percentage of the bottom sheet.
 * @property {string[]} top_component_identifiers - List of top component identifiers.
 * @property {string[]} floating_component_identifiers - List of floating component identifiers.
 * @property {string[]} component_identifiers - List of component identifiers.
 */

/**
 * @typedef {Object} Context
 * @property {BottomSheet} bottom_sheet - The bottom sheet configuration.
 * @property {Object} map - The map configuration.
 */

/**
 * @typedef {Object} PageContent
 * @property {NavigationBarBehavior} navigation_bar_behavior - The navigation bar behavior.
 * @property {Context} context - The context configuration.
 * @property {Alchemist} alchemist - The alchemist configuration.
 */

/**
 * @typedef {Object} ContentStyle
 * @property {Padding} padding - The padding configuration.
 */

/**
 * @typedef {Object} Padding
 * @property {number} top - The top padding.
 * @property {number} bottom - The bottom padding.
 * @property {number} left - The left padding.
 * @property {number} right - The right padding.
 */

/**
 * @typedef {Object} Icon
 * @property {string} name - The name of the icon.
 * @property {string} style - The style of the icon.
 */

/**
 * @typedef {Object} Content
 * @property {Icon} icon - The icon configuration.
 * @property {boolean} show_shadow - Indicates if the shadow should be shown.
 * @property {string} content_color - The content color.
 * @property {ContentStyle} content_style - The content style.
 */

/**
 * @typedef {Object} PublishEvent
 * @property {string} id - The event ID.
 * @property {string} trigger - The trigger for the event.
 * @property {Object} properties - The properties of the event.
 */

/**
 * @typedef {Object} Events
 * @property {PublishEvent[]} publish - List of publish events.
 */

/**
 * @typedef {Object} Child
 * @property {string} id - The ID of the child component.
 * @property {string} component_type - The type of the child component.
 * @property {Content} content - The content configuration of the child component.
 * @property {Events} [events] - The events configuration of the child component.
 * @property {Child[]} [children] - The children components.
 * @property {Tracking[]} [tracking] - The tracking configuration of the child component.
 */

/**
 * @typedef {Object} Tracking
 * @property {string} id - The tracking ID.
 * @property {Object} properties - The properties of the tracking.
 * @property {string} trigger - The trigger for the tracking.
 */

/**
 * @typedef {Object} Alchemist
 * @property {string} id - The alchemist ID.
 * @property {string} component_type - The component type of the alchemist.
 * @property {Array} header - The header configuration.
 * @property {Array} footer - The footer configuration.
 * @property {Child[]} children - The children components of the alchemist.
 */

/**
 * @typedef {Object} Order
 * @property {string} order_id - The order ID.
 * @property {string} page_type - The page type.
 * @property {string} user_type - The user type.
 * @property {PageContent} page_content - The page content.
 */

class AlchemistContextData {
  /**
   *
   * @param {Order} order
   */
  constructor(order) {
    this.updateContext({ order });
  }

  updateContext({ order }) {
    //TODO: here use the interface for the order type
    if (AlchemistContextOne.matchVersion(order)) {
      this.alchemistVersion = ALCHEMIST_VERSION.A1;
      this.interface = new AlchemistContextOne(order);
    } else {
      this.alchemistVersion = ALCHEMIST_VERSION.BROKER;
      this.interface = new AlchemistContextBroker(order);
    }
  }

  getContextInfoString() {
    return `[orderId]: ${this.interface.getOrderId()} [page_type]: ${this.interface.getPageType()} [page_context]: ${JSON.stringify(
      this.interface.getPageContext(),
    )}`;
  }

  getOrder() {
    return this.interface.getOrder();
  }

  getOrderId() {
    return this.interface.getOrderId();
  }

  isNonTrackingOrderPageType() {
    return this.interface.isNonTrackingOrderPageType();
  }

  isTrackingOrderPageType() {
    return this.interface.isTrackingOrderPageType();
  }

  isOrderCancelledPageType() {
    return this.interface.isOrderCancelledPageType();
  }

  isOrderCompletedPageType() {
    return this.interface.isOrderCompletedPageType();
  }

  getPageType() {
    return this.interface.getPageType();
  }

  isFloatingETAActive() {
    return this.interface.isFloatingETAActive();
  }

  hasTabs() {
    return this.interface.hasTabs();
  }

  isHeaderActive() {
    return this.interface.isHeaderActive();
  }

  getPageUrl() {
    return this.interface.getPageUrl();
  }

  getBottomSheetHeight() {
    return this.interface.getBottomSheetHeight();
  }

  getDockedComponentIdList() {
    return this.interface.getDockedComponentIdList();
  }

  getBottomSheetTopComponentIdList() {
    return this.interface.getBottomSheetTopComponentIdList();
  }

  getBottomSheetComponentIdList() {
    return this.interface.getBottomSheetComponentIdList();
  }

  getMapTopComponentIdList() {
    return this.interface.getMapTopComponentIdList();
  }

  getMapBottomComponentIdList() {
    return this.interface.getMapBottomComponentIdList();
  }

  getTabsComponentIdList() {
    return this.interface.getTabsComponentIdList();
  }

  getTabFixedBottomComponentIdList(tabIndex) {
    return this.interface.getTabFixedBottomComponentIdList(tabIndex);
  }

  getActiveTabIndex() {
    return this.interface.getActiveTabIndex();
  }

  getComponentList() {
    return this.interface.getComponentList();
  }

  isMapActive() {
    return this.interface.isMapActive();
  }

  getHeaderData() {
    return this.interface.getHeaderData();
  }
}

export function createAlchemistContextDataInstance(orderData) {
  if (orderData && isObject(orderData)) {
    instance = new AlchemistContextData(orderData);
  } else {
    throw new Error(
      `createAlchemistContextDataInstance: Invalid orderData ${JSON.stringify(orderData)}`,
    );
  }
}

export function updateAlchemistContextData(orderData) {
  if (!orderData || !isObject(orderData)) {
    throw new Error(`updateAlchemistContextData: Invalid orderData ${JSON.stringify(orderData)}`);
  }
  instance = new AlchemistContextData(orderData);
}

export function getAlchemistContextData() {
  return instance;
}

export default AlchemistContextData;
