import React from 'react';
import PropTypes from 'prop-types';
import { textPropTypes } from '@app/alchemist/types/props';
import {
  Text,
  Container,
  Image,
  ImageList,
  ALIGNMENT,
  FLOW_DIRECTION,
  EXPAND_OPTIONS,
  HorizontalSeparator,
  PriceDiscount,
} from '@pedidosya/order-status-components';
import { ACTION_TRIGGER, ACTION_TYPE } from '@app/alchemist/utils/actions';
import {
  getDefaultHigherComponentPadding,
  getDefaultLineHeight,
} from '@app/alchemist/utils/defaults';
import FullDetailAmountLayout from './FullDetailAmountLayout';
import { goToTargetUrl } from '@app/utils/url';
import { TRACKING_TRIGGERS } from '@app/tracking';
import AlchemistContentComponent from '@app/alchemist/AlchemistContentComponent';
import ProductImageList from '../Fenix/ImageTextActions/ProductImageList';

function FullDetailAmount(props) {
  //PAYMENT
  const amountProps = props.componentData.getContentProp('amounts');
  const amountTextProps = props.componentData.getTextPropsFrom(amountProps, 'primary');
  const discountTextProps = props.componentData.getTextPropsFrom(amountProps, 'secondary');

  const amountData = {
    value: amountProps?.primary?.value || 0,
    discount: null,
    currencySymbol: amountProps?.primary?.prefix || '$',
    amountColor: '#000',
    discountColor: '#000',
  };

  if (discountTextProps && amountProps?.secondary) {
    amountData.amountColor = '#746e7f';
    amountData.discount = amountData.value;
    amountData.value = amountProps?.secondary?.value || 0;
  }

  const paymentImageData = props.componentData.getImageData('amounts_complement');
  const descriptionProps = props.componentData.getTextPropsFrom(
    props.componentData.getContentProp('amounts_complement'),
    'description',
  );

  //PRODUCT
  const productImagesProps = {
    borderSize: [1],
    borderColor: ['#FFF'],
    borderRadius: 8,
    width: 40,
    height: 40,
  };
  const titleProps = props.componentData.getTextProps('title', {
    lineHeight: `${getDefaultLineHeight()}em`,
    marginBottom: '-2px',
  });
  const subtitleProps = props.componentData.getTextProps('sub_title');
  const subtitleMargin = [4, 0, 0, 0];

  const paymentImageMargin = [0, 4, 0, 0];
  const chevronImage = props.componentData.getImageData('chevron', { dpi: 1 });
  const chevronMargin = [0, 5, 0, 19];

  const padding = props.componentData.getStyles().getPadding(getDefaultHigherComponentPadding());
  const dividerProps = props.componentData.getStyles().getDividerProps();

  //ACTIONS & TRACKING
  const navigateAction = props.componentData.getAction(
    ACTION_TYPE.NAVIGATE,
    ACTION_TRIGGER.CLICKED,
  );
  const onDetailClicked = () => {
    props.componentData.getTracking().trackAllByTrigger(TRACKING_TRIGGERS.CLICKED);
    goToTargetUrl(navigateAction);
  };

  const TitleComponent =
    titleProps && titleProps?.text ? (
      <Text {...titleProps} expandUntil={EXPAND_OPTIONS.SIZE_75}>
        {titleProps.text}
      </Text>
    ) : undefined;

  const SubtitleComponent =
    subtitleProps && subtitleProps?.text ? (
      <Text {...subtitleProps} margin={subtitleMargin}>
        {subtitleProps.text}
      </Text>
    ) : undefined;

  const ImageSectionComponent = (
    <AlchemistContentComponent Component={ProductImageList} parentProps={props} />
  );

  const ActionSectionComponent =
    chevronImage && chevronImage?.imageList?.length > 0
      ? chevronImage?.imageList.map((image, index) => (
          <Image
            key={image.url}
            src={image.url}
            width={7}
            height={12}
            margin={chevronMargin}
            fallbackRenderer={image.fallbackComponent}
            fallback={image.fallbackSrc}
          />
        ))
      : null;

  return (
    <>
      <FullDetailAmountLayout
        titleComponent={TitleComponent}
        subtitleComponent={SubtitleComponent}
        imageSectionComponent={ImageSectionComponent}
        actionSectionComponent={ActionSectionComponent}
        onActionClicked={onDetailClicked}
        wrapperContainerProps={{ padding }}
      >
        <Container
          data-testid="payment-container"
          direction={FLOW_DIRECTION.ROW}
          vAlignment={ALIGNMENT.CENTER}
          fillSpace
        >
          {paymentImageData && paymentImageData.hasImages()
            ? paymentImageData
                .getImageList()
                .map((image, index) => (
                  <Image
                    key={index}
                    src={image.url}
                    {...productImagesProps}
                    margin={paymentImageMargin}
                    width={32}
                    height={20}
                    fallbackRenderer={image.fallbackComponent}
                    fallback={image.fallbackSrc}
                  />
                ))
            : null}
          {descriptionProps && descriptionProps?.text && (
            <Text {...descriptionProps}>{descriptionProps.text}</Text>
          )}
        </Container>
        {amountTextProps && (
          <PriceDiscount
            discountFontToken={amountTextProps?.fontToken}
            discount={amountData.discount}
            discountColor={amountData.discountColor}
            color={amountData.amountColor}
            fontToken={discountTextProps?.fontToken || amountTextProps?.fontToken}
            value={amountData.value}
            currencySymbol={amountData.currencySymbol}
          />
        )}
      </FullDetailAmountLayout>
      {dividerProps && (
        <Container expanded padding={dividerProps?.padding}>
          <HorizontalSeparator
            color={dividerProps?.borderColor?.length > 0 ? dividerProps.borderColor[0] : undefined}
            height={dividerProps?.height}
          />
        </Container>
      )}
    </>
  );
}

FullDetailAmount.propTypes = {
  title: PropTypes.shape(textPropTypes).isRequired,
  sub_title: PropTypes.shape(textPropTypes).isRequired,
};

export default FullDetailAmount;
