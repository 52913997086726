/* eslint-disable no-console */
import { datadogLogs } from '@datadog/browser-logs';
import { DATADOG_CLIENT_TOKEN, DATADOG_SITE, LOGGER_NAME } from './constants';
import { isEmpty } from '@app/utils/string';
import { TEST_ENVIRONMENT, LOCAL_ENVIRONMENT } from '@commons/constants/environments';

export const createLoggerInstance = ({
  environment,
  tokenType = null,
  countryId = null,
  countryShortName = null,
  origin = null,
  appVersion,
  platformKey,
  locale,
  device,
}) => {
  if (!isEmpty(environment)) {
    try {
      datadogLogs.init({
        clientToken: DATADOG_CLIENT_TOKEN,
        site: DATADOG_SITE,
        forwardErrorsToLogs: true,
        sampleRate: 10,
        env: environment,
        service: LOGGER_NAME,
      });

      const isLocalEnv = environment === LOCAL_ENVIRONMENT || environment === TEST_ENVIRONMENT;

      const instance = datadogLogs.createLogger(LOGGER_NAME, {
        level: 'info',
        handler: isLocalEnv ? ['console'] : ['console', 'http'],
        context: {
          environment,
          tokenType,
          countryId,
          countryShortName,
          origin,
          appVersion,
          platformKey,
          locale,
          device,
        },
      });
      instance.info('initializing datadog logger', { environment });
    } catch (e) {
      throw new Error(`logger initialization has failed: ${e.message}`);
    }
  } else {
    throw new Error(`the context environment value is not valid`);
  }
};

export function getLogger() {
  const instance = datadogLogs.getLogger(LOGGER_NAME);
  return typeof document !== 'undefined' && typeof instance !== 'undefined' ? instance : console;
}
