import { ALIGNMENT, Container, Text, toFenixToken } from '@pedidosya/order-status-components';
import React from 'react';

function InfoColorComponent(props) {
  const bgColor =
    props.componentData.getContentProp('background_color', '') ||
    toFenixToken(props.componentData.getContentProp('component_color', '#2656F7'));
  const contentPadding = props.componentData.getContentProp('content_padding', 20);
  const titleProps = props.componentData.getTextProps('title');

  const styles = {
    borderRadius: props.globals.isDesktop ? '0' : '8px 8px 0 0',
  };
  if (!titleProps?.text) {
    return null;
  }

  return (
    <Container
      vAlignment={ALIGNMENT.CENTER}
      hAlignment={ALIGNMENT.CENTER}
      padding={[8, contentPadding]}
      bgColor={bgColor}
      style={styles}
    >
      <Text {...titleProps}>{titleProps.text}</Text>
    </Container>
  );
}

export default InfoColorComponent;
