import React from 'react';
import PropTypes from 'prop-types';
import { FLAGS } from '@commons/fwf/constants';
import isVariation from '@app/components/fwf/utils/isVariation';
import OrderStatusPage from '@app/pages/OrderStatus';
import MicrositeWrapper from '@app/components/Microsite/MicrositeWrapper';
import Authentication from '@app/components/Microsite/components/Authentication';
import RootCSR from '@app/root.csr.component';
import RootSSR from '@app/root.ssr.component';
import MicrositeLoader from '@app/components/Microsite/MicrositeLoader';
import MICROSITE_STATE from '@app/components/Microsite/constants/microsite-state';
import LoginLoader from '@app/components/Login/LoginLoader';
import { getPlaform } from '@commons/utils/platform';
import RootProviders from './root.providers.component';
import MicrositeErrorLoader from '@app/components/Microsite/MicrositeErrorLoader';
import MicrositeErrorNotFoundLoader from '@app/components/Microsite/MicrositeErrorNotFoundLoader';
import { getLogger } from '@app/logger';
import { DEVICE, registerCustomFont } from '@pedidosya/order-status-components';
import crossPlatformEvents from '@app/services/event/platform/cross-platform-events';
import CUSTOM_FONT_TOKEN from './constants/custom-font-token';
import {
  createAlchemistContextDataInstance,
  getAlchemistContextData,
} from '@app/alchemist/utils/context-data/alchemist-context-data';
import { initMetricsService } from './services/metrics-service';

function Root(micrositeData) {
  // Custom fonts
  registerCustomFont(CUSTOM_FONT_TOKEN.ERROR_PAGE_TITLE, {
    size: 20,
    weight: 800,
    lineHeight: 1.17,
  });

  initMetricsService();

  let Renderer = RootCSR;
  const isSSR = micrositeData?.isSSR;
  if (isSSR) {
    Renderer = RootSSR;
  }

  return (
    <Renderer serverSideProps={micrositeData}>
      {({ data, error, loading }) => {
        const viewType = micrositeData?.viewType || '';
        let pageError = error;
        const device = data?.context?.device;
        const locale = data?.context?.locale;
        const messages = data?.messages;
        const platform =
          device === DEVICE.DESKTOP ? 'web' : getPlaform(micrositeData?.context?.app?.platformKey);
        const hasNotch =
          platform === 'web' || platform === 'mweb'
            ? false
            : data?.queryParams?.has_notch == 'true';

        if (loading) {
          return (
            <MicrositeWrapper
              locale={locale}
              device={device}
              messages={messages}
              platform={platform}
              hasNotch={hasNotch}
            >
              <MicrositeLoader />
            </MicrositeWrapper>
          );
        }

        try {
          crossPlatformEvents.setContext({
            isNative: data?.context?.isNative,
            platformKey: platform,
            countryId: data?.context?.app?.country?.id,
            countryShortName: data?.queryParams?.c,
          });
        } catch (e) {
          getLogger().error(`Error trying to create crossPlatformEvents ${e.message}`);
          pageError = MICROSITE_STATE.DATA_NOT_FOUND;
        }

        // Si hay error o no encuentra la orden
        if (pageError || !data?.order) {
          return (
            <MicrositeWrapper
              locale={locale}
              device={device}
              messages={messages}
              platform={platform}
              hasNotch={hasNotch}
            >
              {pageError === MICROSITE_STATE.UNAUTHORIZED ? (
                <LoginLoader />
              ) : pageError === MICROSITE_STATE.DATA_NOT_FOUND ? (
                // "No encontramos datos de tu orden"
                <MicrositeErrorNotFoundLoader platform={platform} device={device} />
              ) : (
                // "Detalles no disponibles"
                // "No te preocupes, todo sigue bien con tu pedido. Estamos trabajando para mostrarte los detalles a la brevedad."
                <MicrositeErrorLoader />
              )}
            </MicrositeWrapper>
          );
        }

        try {
          createAlchemistContextDataInstance(data?.order);
        } catch (e) {
          getLogger().error(`Error trying to update an AlchemistContextData: ${e.message}`);
          return (
            <MicrositeWrapper
              locale={locale}
              device={device}
              messages={messages}
              platform={platform}
              hasNotch={hasNotch}
            >
              <MicrositeErrorLoader />
            </MicrositeWrapper>
          );
        }

        const alchemistContext = getAlchemistContextData();

        const shouldRedirectToOrderCompleted =
          isVariation(data?.flags[FLAGS.REDIRECT_ORDER_COMPLETED_ENABLED.KEY]) || false;
        const context = data?.context;

        return (
          <MicrositeWrapper
            locale={locale}
            device={device}
            messages={messages}
            platform={platform}
            hasNotch={hasNotch}
          >
            <RootProviders publicEnv={data}>
              <Authentication context={context}>
                <OrderStatusPage
                  ssr={isSSR}
                  publicEnv={data}
                  alchemistContext={alchemistContext}
                  userAddresses={data?.userAddresses}
                  shouldRedirectToOrderCompleted={shouldRedirectToOrderCompleted}
                  queryParams={data?.queryParams}
                  hasNotch={hasNotch}
                  viewType={viewType}
                />
              </Authentication>
            </RootProviders>
          </MicrositeWrapper>
        );
      }}
    </Renderer>
  );
}

const ssrProps = typeof window !== 'undefined' ? window.__INITIAL_DATA__ : {};

Root.defaultProps = { ...ssrProps };

Root.propTypes = {
  context: PropTypes.object,
  messages: PropTypes.shape({}),
};

export default Root;
