import React from 'react';
import { IntlProvider as ReactIntlProvider } from 'react-intl';

const IntlProvider = ({ children, messages, locale }) => {
  return (
    <ReactIntlProvider messages={messages} locale={locale} defaultLocale="es">
      {children}
    </ReactIntlProvider>
  );
};

export default IntlProvider;
