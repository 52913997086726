const parcels = [
  {
    regex: /\/cross-selling/,
    id: '@microsite/cross-selling-parcel',
    propsExtractor(url, alchemistContext, publicEnv) {
      return {
        orderId: alchemistContext.getOrderId(),
        flow: 'order-status',
        mock: false,
        pWebView: publicEnv?.context?.isNative || false,
      };
    },
  },
];

export default parcels;
