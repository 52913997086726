import { getLogger } from '@app/logger';
import MobileEvents from './mobile-events';
import { getDefaultPlatformEventContext, isValidPlatformEventContext } from './utils';
import WebEvents from './web-events';

class CrossPlatformEvents {
  context = getDefaultPlatformEventContext();
  platformEvents = null;

  setContext({ isNative, countryId, platformKey, countryShortName = '' }) {
    this.context.platformKey = platformKey;
    this.context.country.id = countryId;
    this.context.country.shortName = countryShortName;

    if (isNative) {
      if (!this.platformEvents || !(this.platformEvents instanceof MobileEvents)) {
        this.platformEvents = new MobileEvents();
      }
    } else {
      if (!this.platformEvents || !(this.platformEvents instanceof WebEvents)) {
        this.platformEvents = new WebEvents();
      }
    }
    if (isValidPlatformEventContext(this.context)) {
      this.platformEvents.setContext({ ...this.context });
    } else {
      const errorMessage = `CrossPlatformEvents:setContext: Invalid platform events context`;
      throw new Error(errorMessage);
    }
  }

  closePage() {
    this.platformEvents.closePage();
  }

  goToUrl(url, eventName = '', closePageOnRedirectionFailure = false) {
    if (url) {
      this.platformEvents.goToUrl(url, eventName);
    } else if (closePageOnRedirectionFailure) {
      this.closePage();
    }
  }
}

const instance = new CrossPlatformEvents();

export default instance;
