import { isEmpty } from '@app/utils/string';
import isObject from '@commons/utils/object/isObject';
import { isAndroidPlatform, isIOSPlatform } from '@app/utils/platform';
import { isWindowObjectDefined } from '@pedidosya/order-status-components';

function sendAndroidEvent(jsonData) {
  if (
    window.ReviewWebEvents &&
    isObject(jsonData) &&
    typeof window.ReviewWebEvents.send === 'function'
  ) {
    window.ReviewWebEvents.send(JSON.stringify(jsonData));
  }
}

function sendIosEvent(jsonData) {
  if (
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers.ListenEventsInterface &&
    typeof window.webkit.messageHandlers.ListenEventsInterface.postMessage === 'function' &&
    isObject(jsonData)
  ) {
    window.webkit.messageHandlers.ListenEventsInterface.postMessage(jsonData);
  }
}

export function sendNativeEvent(platform, jsonData) {
  if (isWindowObjectDefined() && !isEmpty(platform)) {
    if (isAndroidPlatform(platform)) {
      sendAndroidEvent(jsonData);
    } else if (isIOSPlatform(platform)) {
      sendIosEvent(jsonData);
    }
  }
}
