import React from 'react';
import { Spinner } from '@pedidosya/order-status-components';

import styled from 'styled-components';

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 100vh;
`;

const MicrositeLoader = () => {
  return (
    <LoaderContainer>
      <Spinner />
    </LoaderContainer>
  );
};

export default MicrositeLoader;
