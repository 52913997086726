import React, { createContext, useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { EXTERNAL_ROUTES, INTERNAL_ROUTES } from '@commons/services/routes.service';
import { isEmpty } from '@app/utils/string';
import { useSSR } from '@pedidosya/order-status-components';

const CrossDeviceRoutesContext = createContext({});

const buildRoutes = (currDevice, lastDevice) => {
  if (!isEmpty(currDevice) && lastDevice !== currDevice) {
    const devicePrefix = `${currDevice.toUpperCase()}_`;
    const externalRoutes = Object.entries(EXTERNAL_ROUTES).reduce((accum, [key, value]) => {
      if (key.startsWith(devicePrefix)) {
        accum[key.replace(devicePrefix, '')] = value;
      }
      return accum;
    }, {});
    return { ...INTERNAL_ROUTES, ...externalRoutes };
  } else {
    return null;
  }
};

const CrossDeviceRoutesProvider = ({ children, device }) => {
  const { ssr } = useSSR();
  const [lastDevice, setLastDevice] = useState(device);
  let initialRoutes = EXTERNAL_ROUTES;
  if (ssr) {
    initialRoutes = buildRoutes(device, null);
    initialRoutes = initialRoutes ? initialRoutes : EXTERNAL_ROUTES;
  }
  const [routes, setRoutes] = useState(initialRoutes);

  useEffect(() => {
    const currDevice = device;
    const updatedRoutes = buildRoutes(currDevice, lastDevice);
    if (updatedRoutes) {
      setRoutes(updatedRoutes);
      setLastDevice(currDevice);
    }
  }, [device, lastDevice]);

  return (
    <CrossDeviceRoutesContext.Provider value={routes}>{children}</CrossDeviceRoutesContext.Provider>
  );
};

CrossDeviceRoutesProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useRoutes = () => useContext(CrossDeviceRoutesContext);

export default CrossDeviceRoutesProvider;
