import {
  TEXT_STYLE,
  TEXT_DECORATION,
  FONT_TOKEN,
  toFenixToken,
} from '@pedidosya/order-status-components';
import { isEmpty } from '@app/utils/string';
import isObject from '@commons/utils/object/isObject';
import { getContentColor } from './colors';
import { getContentProp } from './content';
import { getDefaultLineHeight } from './defaults';

const typographyCache = {};
const getTypographyFromCache = (token) => (typographyCache[token] ? typographyCache[token] : null);
const setTypographyInCache = (token, typography) => {
  typographyCache[token] = typography;
};

function buildTypographyProps(fontToken, style = {}) {
  return {
    fontToken: fontToken || FONT_TOKEN.FONT_BODY_MIDCONTRAST_SENTENCE_SMALL,
    style,
  };
}

function withLineThrough(typographyProps) {
  return { ...typographyProps, textDecoration: TEXT_DECORATION.LINE_THROUGH };
}
function withItalicStyle(typographyProps) {
  return { ...typographyProps, textStyle: TEXT_STYLE.ITALIC };
}
function withUnderlineStyle(typographyProps) {
  return { ...typographyProps, textDecoration: TEXT_DECORATION.UNDERLINE };
}

const typographyPropsMap = {
  bodySmallRegular: buildTypographyProps(FONT_TOKEN.FONT_BODY_MIDCONTRAST_SENTENCE_SMALL),
  bodySmallRegularLineThrough: buildTypographyProps(
    FONT_TOKEN.FONT_BODY_MIDCONTRAST_STRIKETHROUGH_SMALL,
  ),
  bodySmallBold: buildTypographyProps(FONT_TOKEN.FONT_LABEL_HIGHERCONTRAST_MEDIUM),
  bodyMediumBold: buildTypographyProps(FONT_TOKEN.FONT_SUBTITLE_HIGHCONTRAST_SENTENCE_MEDIUM),
  labelMedium: buildTypographyProps(FONT_TOKEN.FONT_LABEL_HIGHERCONTRAST_MEDIUM),
  buttonLabelSmall: buildTypographyProps(FONT_TOKEN.FONT_LABEL_HIGHERCONTRAST_MEDIUM),
  bodyLargeBold: buildTypographyProps(FONT_TOKEN.FONT_SUBTITLE_HIGHCONTRAST_SENTENCE_LARGE),
  //esta en mobile tiene size 8px (revisar, es muy chico)
  tagLabelSmall: buildTypographyProps(FONT_TOKEN.FONT_LABEL_HIGHERCONTRAST_MEDIUM),
  //revisar esta, no tenia mapeo.
  segmaBodySmallBold: buildTypographyProps(FONT_TOKEN.FONT_BODY_MIDCONTRAST_SENTENCE_SMALL),
  segmaBodySmallRegular: buildTypographyProps(FONT_TOKEN.FONT_BODY_MIDCONTRAST_SENTENCE_SMALL),
  segmaBodySmallBoldItalic: withItalicStyle(
    buildTypographyProps(FONT_TOKEN.FONT_LABEL_HIGHERCONTRAST_MEDIUM),
  ),
  segmaBodySmallBoldUnderline: buildTypographyProps(
    FONT_TOKEN.FONT_LABEL_HIGHERCONTRAST_UNDERLINE_MEDIUM,
  ),
  segmaBodyLarge: buildTypographyProps(FONT_TOKEN.FONT_SUBTITLE_MIDCONTRAST_SENTENCE_LARGE),
  //esta en mobile tiene size 36px (revisar), por ahora toma el size 24 que es el maximo del theme.
  segmaTitleBoldItalic: withItalicStyle(
    buildTypographyProps(FONT_TOKEN.FONT_HEADLINE_HIGHCONTRAST_LARGE, {
      lineHeight: `${getDefaultLineHeight()}em`,
    }),
  ),
  segmaTitleMediumBoldItalic: withItalicStyle(
    buildTypographyProps(FONT_TOKEN.FONT_HEADLINE_HIGHCONTRAST_LARGE, {
      lineHeight: `${getDefaultLineHeight()}em`,
    }),
  ),
  segmaLabelMediumBoldItalic: buildTypographyProps(FONT_TOKEN.FONT_LABEL_HIGHERCONTRAST_SMALL),
  segmaBodySmallSemiBold: buildTypographyProps(FONT_TOKEN.FONT_BODY_HIGHCONTRAST_SENTENCE_SMALL),
  segmaBodyLargeSemiBold: buildTypographyProps(
    FONT_TOKEN.FONT_SUBTITLE_HIGHCONTRAST_SENTENCE_LARGE,
  ),
  segmaBodyLargeRegular: buildTypographyProps(FONT_TOKEN.FONT_HEADLINE_LOWCONTRAST_MEDIUM),
  segmaBodyMediumSemiBold: buildTypographyProps(
    FONT_TOKEN.FONT_SUBTITLE_HIGHCONTRAST_SENTENCE_MEDIUM,
  ),
  segmaBodyMediumRegular: buildTypographyProps(FONT_TOKEN.FONT_BODY_MIDCONTRAST_SENTENCE_MEDIUM),
  segmaBodySmallItalic: withItalicStyle(
    buildTypographyProps(FONT_TOKEN.FONT_LABEL_MIDCONTRAST_SMALL, {
      lineHeight: `${getDefaultLineHeight()}em`,
    }),
  ),
};

function getTypographyPropsByToken(alchemistTextToken) {
  let fontObj = typographyPropsMap[alchemistTextToken];

  if (!fontObj) {
    fontObj = buildTypographyProps(alchemistTextToken);
  }

  const { fontToken, ...rest } = fontObj;
  return {
    fontToken: fontToken || FONT_TOKEN.FONT_BODY_MIDCONTRAST_SENTENCE_SMALL,
    ...rest,
  };
}

/**
 * returns the contained text from a text type element.
 * @param {*} alchemistProps
 * @param {*} property
 * @returns
 */
function getTextContentFrom(contentElement) {
  return contentElement && isObject(contentElement) ? contentElement.text || null : null;
}

/**
 * returns a mapped font props ready to be used in components thath support them (Text, etc)
 * @param {*} alchemistProps
 * @param {*} property
 * @returns
 */
function getTextContentFontProps(contentElement) {
  if (!contentElement) {
    return {
      font: null,
      fontVariant: null,
    };
  }
  const typographyToken =
    contentElement['typography_token'] ||
    contentElement['typographyToken'] ||
    toFenixToken(contentElement['typography']) ||
    FONT_TOKEN.FONT_BODY_MIDCONTRAST_SENTENCE_MEDIUM;
  if (!typographyToken || isEmpty(typographyToken)) {
    return null;
  }

  const cachedTypography = getTypographyFromCache(typographyToken);
  let result;

  if (cachedTypography) {
    result = cachedTypography;
  } else {
    result = getTypographyPropsByToken(typographyToken) || null;
    if (result) {
      setTypographyInCache(typographyToken, result);
    }
  }

  return result;
}

export function getTextElementPropsByKey(alchemistProps, property = 'text', styles = {}) {
  const textElement =
    getContentProp(alchemistProps, property) || getContentProp(alchemistProps, property + '_label');
  if (!textElement) {
    return null;
  }

  const { style, ...fontProps } = getTextContentFontProps(textElement);

  return {
    text: getTextContentFrom(textElement),
    ...fontProps,
    color: getContentColor(textElement),
    style: { ...styles, ...style },
  };
}
