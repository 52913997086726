import React from 'react';
import PropTypes from 'prop-types';
import { HorizontalSeparator } from '@pedidosya/order-status-components';
import { getLogger } from '@app/logger';

const Divider = (props) => {
  const styles = props.componentData.getStyles();
  const dividers = styles.getRawProp('dividers');
  let divider = null;
  if (dividers && Object.prototype.toString.call(dividers) === '[object Object]') {
    let keys = Object.keys(dividers);
    if (keys.length > 0) {
      divider = dividers[keys[0]] || {};
    }
  }

  if (!divider) {
    getLogger().error(`[Components][Divider]: invalid divider props: ${JSON.stringify(dividers)}`);
    return null;
  }

  return (
    <div style={{ padding: `4px ${divider?.right || 0}px 6px ${divider?.left || 0}px` }}>
      <HorizontalSeparator />
    </div>
  );
};

export default Divider;
