import React from 'react';
import AlchemistComponent from '@app/alchemist/AlchemistComponent';
import VerticalStack from '@app/alchemist/components/Containers/VerticalStack';
import CenteredLabelTag from '@app/alchemist/components/CenteredLabelTag/CenteredLabelTag';
import ImageBorderButton from '@app/alchemist/components/ImageBorderButton/ImageBorderButton';
import ImageDetails from '@app/alchemist/components/ImageDetails/ImageDetails';
import FullDetailAmount from '@app/alchemist/components/FullDetailAmount/FullDetailAmount';
import ImageBadgeButton from '@app/alchemist/components/ImageBadgeButton/ImageBadgeButton';
import PillButton from '@app/alchemist/components/Buttons/PillButton';
import {
  UnusedComponent,
  ImageDetailsFallback,
  FullDetailAmountFallback,
} from './alchemist-component-fallbacks';
import NonDismissibleMessage from '@app/alchemist/components/Message/NonDismissibleMessage';
import DismissibleMessage from '@app/alchemist/components/Message/DismissibleMessage';
import FenixButton from '@app/alchemist/components/Fenix/Button';
import PinButton from '@app/alchemist/components/Buttons/PinButton';
import SliderComponent from '@app/alchemist/components/SliderComponent/SliderComponent';
import Banner from '@app/alchemist/components/Banner/Banner';
import BottomSheetProgressBar from '@app/alchemist/components/ProgressBar/BottomSheetProgressBar';
import FloatingProgressBar from '@app/alchemist/components/ProgressBar/FloatingProgressBar';
import FenixFloatingProgressBar from '@app/alchemist/components/Fenix/ProgressBar/FloatingProgressBar';
import CollapsibleVertical from '@app/alchemist/components/CollapsibleVertical';
import CollapsibleVerticalFenix from '@app/alchemist/components/Fenix/CollapsibleVertical';
import IssueMessage from '@app/alchemist/components/Fenix/IssueMessage';
import LeftRightPlaceHolder from '@app/alchemist/components/Containers/LeftRightPlaceHolder';
import HorizontalStack from '@app/alchemist/components/Containers/HorizontalStack';
import PillComponent from '@app/alchemist/components/Buttons/PillComponent';
import ImageDescriptionActionChevron from '@app/alchemist/components/ImageDescriptionActionChevron';
import Tips from '@app/alchemist/components/Tips/Tips';
import InfoColorComponent from '@app/alchemist/components/InfoColor/InfoColorComponent';
import SegmentedControl from '@app/alchemist/components/SegmentedControl/SegmentedControl';
import WebControl from '@app/alchemist/components/WebControl/WebControl';
import AddressComponent from '@app/alchemist/components/Fenix/ImageTextActions/AddressComponent';
import SimpleDetailComponent from '@app/alchemist/components/Fenix/ImageTextActions/SimpleDetailComponent';
import FullDetailComponent from '@app/alchemist/components/Fenix/ImageTextActions/FullDetailComponent';

// components that are not in use yet in the web version.
// they are mapped to avoid console errors.
const unmappedComponents = [
  {
    id: 'titleAction',
    component: (props) => <UnusedComponent {...props} />,
  },
];

function getRegistry({ publicEnv, globals }) {
  return [
    {
      id: 'verticalStack',
      component: (props) => (
        <AlchemistComponent
          publicEnv={publicEnv}
          globals={globals}
          ssr={publicEnv?.ssr}
          Component={VerticalStack}
          alchemistProps={props}
        />
      ),
    },
    {
      id: 'addressAction',
      component: (props) => (
        <AlchemistComponent
          publicEnv={publicEnv}
          globals={globals}
          ssr={publicEnv?.ssr}
          Component={ImageDetails}
          alchemistProps={props}
        />
      ),
    },
    {
      id: 'imageBorderButton',
      component: (props) => (
        <AlchemistComponent
          publicEnv={publicEnv}
          globals={globals}
          ssr={publicEnv?.ssr}
          Component={ImageBorderButton}
          alchemistProps={props}
        />
      ),
    },
    {
      id: 'imageDetails',
      component: (props) => (
        <AlchemistComponent
          publicEnv={publicEnv}
          globals={globals}
          ssr={publicEnv?.ssr}
          Component={ImageDetails}
          alchemistProps={props}
          fallback={(props) => <ImageDetailsFallback {...props} />}
        />
      ),
    },
    {
      id: 'imageBadgeButton',
      component: (props) => (
        <AlchemistComponent
          publicEnv={publicEnv}
          globals={globals}
          ssr={publicEnv?.ssr}
          Component={ImageBadgeButton}
          alchemistProps={props}
        />
      ),
    },
    {
      id: 'infoPin',
      component: (props) => (
        <AlchemistComponent
          publicEnv={publicEnv}
          globals={globals}
          ssr={publicEnv?.ssr}
          Component={PinButton}
          alchemistProps={props}
        />
      ),
    },
    {
      id: 'fullDetailAmount',
      component: (props) => (
        <AlchemistComponent
          publicEnv={publicEnv}
          globals={globals}
          ssr={publicEnv?.ssr}
          Component={FullDetailAmount}
          alchemistProps={props}
          fallback={(props) => <FullDetailAmountFallback {...props} />}
        />
      ),
    },
    {
      id: 'simpleDetailAmount',
      component: (props) => (
        <AlchemistComponent
          publicEnv={publicEnv}
          globals={globals}
          ssr={publicEnv?.ssr}
          Component={FullDetailAmount}
          alchemistProps={props}
          fallback={(props) => <FullDetailAmountFallback {...props} />}
        />
      ),
    },
    {
      id: 'centeredLabelTag',
      component: (props) => (
        <AlchemistComponent
          publicEnv={publicEnv}
          globals={globals}
          ssr={publicEnv?.ssr}
          Component={CenteredLabelTag}
          alchemistProps={props}
        />
      ),
    },
    {
      id: 'lottie',
      component: (props) => {
        return (
          <AlchemistComponent
            publicEnv={publicEnv}
            globals={globals}
            ssr={publicEnv?.ssr}
            Component={BottomSheetProgressBar}
            alchemistProps={props}
          />
        );
      },
    },
    {
      id: 'pillButton',
      component: (props) => (
        <AlchemistComponent
          publicEnv={publicEnv}
          globals={globals}
          ssr={publicEnv?.ssr}
          Component={PillButton}
          alchemistProps={props}
        />
      ),
    },
    {
      id: 'imageDescriptionActionChevron',
      component: (props) => (
        <AlchemistComponent
          publicEnv={publicEnv}
          globals={globals}
          ssr={publicEnv?.ssr}
          Component={ImageDescriptionActionChevron}
          alchemistProps={props}
        />
      ),
    },
    {
      id: 'horizontalImageTitleButton',
      component: (props) => (
        <AlchemistComponent
          publicEnv={publicEnv}
          globals={globals}
          ssr={publicEnv?.ssr}
          Component={ImageBorderButton}
          alchemistProps={props}
        />
      ),
    },
    {
      id: 'incidentMessageComponent',
      component: (props) => (
        <AlchemistComponent
          publicEnv={publicEnv}
          globals={globals}
          ssr={publicEnv?.ssr}
          Component={DismissibleMessage}
          alchemistProps={props}
        />
      ),
    },
    {
      id: 'nonDismissibleMessage',
      component: (props) => (
        <AlchemistComponent
          publicEnv={publicEnv}
          globals={globals}
          ssr={publicEnv?.ssr}
          Component={NonDismissibleMessage}
          alchemistProps={props}
        />
      ),
    },
    {
      id: 'dismissableMessage',
      component: (props) => (
        <AlchemistComponent
          publicEnv={publicEnv}
          globals={globals}
          ssr={publicEnv?.ssr}
          Component={DismissibleMessage}
          alchemistProps={props}
        />
      ),
    },
    {
      id: 'horizontalContainer',
      component: (props) => (
        <AlchemistComponent
          publicEnv={publicEnv}
          globals={globals}
          ssr={publicEnv?.ssr}
          containerStyle={{ overflowX: 'hidden' }}
          Component={SliderComponent}
          alchemistProps={props}
        />
      ),
    },
    {
      id: 'imageTemplate',
      component: (props) => (
        <AlchemistComponent
          publicEnv={publicEnv}
          globals={globals}
          ssr={publicEnv?.ssr}
          Component={Banner}
          alchemistProps={props}
        />
      ),
    },
    // floating ETA
    {
      id: 'progressBar',
      component: (props) => (
        <AlchemistComponent
          publicEnv={publicEnv}
          globals={globals}
          ssr={publicEnv?.ssr}
          Component={FloatingProgressBar}
          alchemistProps={props}
        />
      ),
    },
    {
      id: 'collapsibleVertical',
      component: (props) => (
        <AlchemistComponent
          publicEnv={publicEnv}
          globals={globals}
          ssr={publicEnv?.ssr}
          Component={CollapsibleVertical}
          alchemistProps={props}
        />
      ),
    },
    {
      id: 'leftRightPlaceHolder',
      component: (props) => (
        <AlchemistComponent
          publicEnv={publicEnv}
          globals={globals}
          ssr={publicEnv?.ssr}
          Component={LeftRightPlaceHolder}
          alchemistProps={props}
        />
      ),
    },
    {
      id: 'horizontalStack',
      component: (props) => (
        <AlchemistComponent
          publicEnv={publicEnv}
          globals={globals}
          ssr={publicEnv?.ssr}
          Component={HorizontalStack}
          alchemistProps={props}
        />
      ),
    },
    {
      id: 'pillComponent',
      component: (props) => (
        <AlchemistComponent
          publicEnv={publicEnv}
          globals={globals}
          ssr={publicEnv?.ssr}
          Component={PillComponent}
          alchemistProps={props}
        />
      ),
    },
    {
      id: 'standaloneTipsComponent',
      component: (props) => (
        <AlchemistComponent
          publicEnv={publicEnv}
          globals={globals}
          ssr={publicEnv?.ssr}
          Component={Tips}
          alchemistProps={props}
        />
      ),
    },
    {
      id: 'infoColorComponent',
      component: (props) => (
        <AlchemistComponent
          publicEnv={publicEnv}
          globals={globals}
          ssr={publicEnv?.ssr}
          Component={InfoColorComponent}
          alchemistProps={props}
        />
      ),
    },
    {
      id: 'segmentedControl',
      component: (props) => (
        <AlchemistComponent
          publicEnv={publicEnv}
          globals={globals}
          ssr={publicEnv?.ssr}
          Component={SegmentedControl}
          containerStyle={{ maxWidth: '100%' }}
          alchemistProps={props}
        />
      ),
    },
    {
      id: 'webControl',
      component: (props) => (
        <AlchemistComponent
          publicEnv={publicEnv}
          globals={globals}
          ssr={publicEnv?.ssr}
          Component={WebControl}
          containerStyle={{ width: 'inherit', maxWidth: '100%' }}
          alchemistProps={props}
        />
      ),
    },
    // FENIX
    {
      id: 'progressBarV2',
      component: (props) => (
        <AlchemistComponent
          publicEnv={publicEnv}
          globals={globals}
          ssr={publicEnv?.ssr}
          Component={FenixFloatingProgressBar}
          alchemistProps={props}
        />
      ),
    },
    {
      id: 'collapsibleVerticalV2',
      component: (props) => (
        <AlchemistComponent
          publicEnv={publicEnv}
          globals={globals}
          ssr={publicEnv?.ssr}
          Component={CollapsibleVerticalFenix}
          alchemistProps={props}
        />
      ),
    },
    {
      id: 'issueMessage',
      component: (props) => (
        <AlchemistComponent
          publicEnv={publicEnv}
          globals={globals}
          ssr={publicEnv?.ssr}
          Component={IssueMessage}
          alchemistProps={props}
          containerStyle={{ width: '100%' }}
        />
      ),
    },
    {
      id: 'fenixButton',
      component: (props) => (
        <AlchemistComponent
          publicEnv={publicEnv}
          globals={globals}
          ssr={publicEnv?.ssr}
          Component={FenixButton}
          alchemistProps={props}
        />
      ),
    },
    {
      id: 'verticalStackV2',
      component: (props) => (
        <AlchemistComponent
          publicEnv={publicEnv}
          globals={globals}
          ssr={publicEnv?.ssr}
          Component={VerticalStack}
          alchemistProps={props}
        />
      ),
    },
    {
      id: 'infoColorComponentV2',
      component: (props) => (
        <AlchemistComponent
          publicEnv={publicEnv}
          globals={globals}
          ssr={publicEnv?.ssr}
          Component={InfoColorComponent}
          alchemistProps={props}
        />
      ),
    },
    {
      id: 'addressNew',
      component: (props) => (
        <AlchemistComponent
          publicEnv={publicEnv}
          globals={globals}
          ssr={publicEnv?.ssr}
          Component={AddressComponent}
          alchemistProps={props}
        />
      ),
    },
    {
      id: 'simpleDetailAmountV2',
      component: (props) => (
        <AlchemistComponent
          publicEnv={publicEnv}
          globals={globals}
          ssr={publicEnv?.ssr}
          Component={SimpleDetailComponent}
          alchemistProps={props}
        />
      ),
    },
    {
      id: 'fullDetailAmountV2',
      component: (props) => (
        <AlchemistComponent
          publicEnv={publicEnv}
          globals={globals}
          ssr={publicEnv?.ssr}
          Component={FullDetailComponent}
          alchemistProps={props}
        />
      ),
    },
    // NOT MAPPED COMPONENTS
    ...unmappedComponents,
  ];
}

export default getRegistry;
