import React from 'react';
import AlchemistComponentData from './utils/context-data/alchemist-component-data';

const AlchemistContentComponent = ({
  Component,
  childContentProps,
  childStyleProps,
  parentProps,
  childNode,
  ...rest
}) => {
  const childProps = { ...rest, ...parentProps };

  if (childContentProps) {
    childProps.content = childContentProps;
  }
  if (childStyleProps) {
    childProps.styles = childStyleProps;
  }

  childProps.children = childNode;
  childProps.componentData = new AlchemistComponentData(childProps, parentProps?.tracker);
  return <Component {...childProps} />;
};

AlchemistContentComponent.defaultProps = {
  childStyleProps: null,
  childNode: undefined,
};

export default AlchemistContentComponent;
