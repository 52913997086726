import isObject from '@commons/utils/object/isObject';
import { getContentProp } from './content';
const fallbackLottieMapV1 = {
  CONFIRMED_COURIER: () => import('@app/assets/lotties/v1/CONFIRMED_COURIER.json'),
  CONFIRMED_FINISHING_RESTAURANT: () =>
    import('@app/assets/lotties/v1/CONFIRMED_FINISHING_RESTAURANT.json'),
  CONFIRMED_GROCERIES: () => import('@app/assets/lotties/v1/CONFIRMED_GROCERIES.json'),
  CONFIRMED_NEW_VERTICALS: () => import('@app/assets/lotties/v1/CONFIRMED_NEW_VERTICALS.json'),
  CONFIRMED_RESTAURANT_PICKUP: () =>
    import('@app/assets/lotties/v1/CONFIRMED_RESTAURANT_PICKUP.json'),
  CONFIRMED_RESTAURANT: () => import('@app/assets/lotties/v1/CONFIRMED_RESTAURANT.json'),

  FIRST_EQUAL_BAR: () => import('@app/assets/lotties/v1/FIRST_EQUAL_BAR.json'),
  FIRST_LONG_MIDDLE_BAR: () => import('@app/assets/lotties/v1/FIRST_LONG_MIDDLE_BAR.json'),
  ICON_CONFIRMED_COURIER: () => import('@app/assets/lotties/v1/ICON_CONFIRMED_COURIER.json'),
  ICON_CONFIRMED_FINISHING_RESTAURANT: () =>
    import('@app/assets/lotties/v1/ICON_CONFIRMED_FINISHING_RESTAURANT.json'),
  ICON_CONFIRMED_NEW_VERTICALS: () =>
    import('@app/assets/lotties/v1/ICON_CONFIRMED_NEW_VERTICALS.json'),
  ICON_CONFIRMED_RESTAURANT: () => import('@app/assets/lotties/v1/ICON_CONFIRMED_RESTAURANT.json'),
  ICON_ORIGIN_ARRIVED_RESTAURANT: () =>
    import('@app/assets/lotties/v1/ICON_ORIGIN_ARRIVED_RESTAURANT.json'),
  ICON_PENDING: () => import('@app/assets/lotties/v1/ICON_PENDING.json'),
  ICON_PICKED_UP_BYCICLE: () => import('@app/assets/lotties/v1/ICON_PICKED_UP_BYCICLE.json'),
  ICON_PICKED_UP_MOTORBIKE: () => import('@app/assets/lotties/v1/ICON_PICKED_UP_MOTORBIKE.json'),
  ORIGIN_ARRIVED_RESTAURANT: () => import('@app/assets/lotties/v1/ORIGIN_ARRIVED_RESTAURANT.json'),
  PENDING_COURIER: () => import('@app/assets/lotties/v1/PENDING_COURIER.json'),
  PENDING_RESTAURANT_PICKUP: () => import('@app/assets/lotties/v1/PENDING_RESTAURANT_PICKUP.json'),
  PENDING_RESTAURANT: () => import('@app/assets/lotties/v1/PENDING_RESTAURANT.json'),
  PICKED_UP_COURIER: () => import('@app/assets/lotties/v1/PICKED_UP_COURIER.json'),
  PICKED_UP_RESTAURANT: () => import('@app/assets/lotties/v1/PICKED_UP_RESTAURANT.json'),
  READY_FOR_PICKUP_RESTAURANT_PICKUP: () =>
    import('@app/assets/lotties/v1/READY_FOR_PICKUP_RESTAURANT_PICKUP.json'),
  SECOND_EQUAL_BAR: () => import('@app/assets/lotties/v1/SECOND_EQUAL_BAR.json'),
  SECOND_LONG_MIDDLE_BAR: () => import('@app/assets/lotties/v1/SECOND_LONG_MIDDLE_BAR.json'),
  THIRD_EQUAL_BAR: () => import('@app/assets/lotties/v1/THIRD_EQUAL_BAR.json'),
  THIRD_LONG_MIDDLE_BAR: () => import('@app/assets/lotties/v1/THIRD_LONG_MIDDLE_BAR.json'),
  ICON_CONFIRMED_GROCERIES: () => import('@app/assets/lotties/v1/ICON_CONFIRMED_GROCERIES.json'),
};

const fallbackLottieMapV2 = {
  CONFIRMED_COURIER: () => import('@app/assets/lotties/v2/CONFIRMED_COURIER.json'),
  CONFIRMED_FINISHING_RESTAURANT: () =>
    import('@app/assets/lotties/v2/CONFIRMED_FINISHING_RESTAURANT.json'),
  CONFIRMED_GROCERIES: () => import('@app/assets/lotties/v2/CONFIRMED_GROCERIES.json'),
  CONFIRMED_NEW_VERTICALS: () => import('@app/assets/lotties/v2/CONFIRMED_NEW_VERTICALS.json'),
  CONFIRMED_RESTAURANT_PICKUP: () =>
    import('@app/assets/lotties/v2/CONFIRMED_RESTAURANT_PICKUP.json'),
  CONFIRMED_RESTAURANT: () => import('@app/assets/lotties/v2/CONFIRMED_RESTAURANT.json'),

  FIRST_EQUAL_BAR: () => import('@app/assets/lotties/v2/FIRST_EQUAL_BAR.json'),
  FIRST_LONG_MIDDLE_BAR: () => import('@app/assets/lotties/v2/FIRST_LONG_MIDDLE_BAR.json'),
  ICON_CONFIRMED_COURIER: () => import('@app/assets/lotties/v2/ICON_CONFIRMED_COURIER.json'),
  ICON_CONFIRMED_FINISHING_RESTAURANT: () =>
    import('@app/assets/lotties/v2/ICON_CONFIRMED_FINISHING_RESTAURANT.json'),
  ICON_CONFIRMED_NEW_VERTICALS: () =>
    import('@app/assets/lotties/v2/ICON_CONFIRMED_NEW_VERTICALS.json'),
  ICON_CONFIRMED_RESTAURANT: () => import('@app/assets/lotties/v2/ICON_CONFIRMED_RESTAURANT.json'),
  ICON_ORIGIN_ARRIVED_RESTAURANT: () =>
    import('@app/assets/lotties/v2/ICON_ORIGIN_ARRIVED_RESTAURANT.json'),
  ICON_PENDING: () => import('@app/assets/lotties/v2/ICON_PENDING.json'),
  ICON_PICKED_UP_BYCICLE: () => import('@app/assets/lotties/v2/ICON_PICKED_UP_BYCICLE.json'),
  ICON_PICKED_UP_MOTORBIKE: () => import('@app/assets/lotties/v2/ICON_PICKED_UP_MOTORBIKE.json'),
  ORIGIN_ARRIVED_RESTAURANT: () => import('@app/assets/lotties/v2/ORIGIN_ARRIVED_RESTAURANT.json'),
  PENDING_COURIER: () => import('@app/assets/lotties/v2/PENDING_COURIER.json'),
  PENDING_RESTAURANT_PICKUP: () => import('@app/assets/lotties/v2/PENDING_RESTAURANT_PICKUP.json'),
  PENDING_RESTAURANT: () => import('@app/assets/lotties/v2/PENDING_RESTAURANT.json'),
  PICKED_UP_COURIER: () => import('@app/assets/lotties/v2/PICKED_UP_COURIER.json'),
  PICKED_UP_RESTAURANT: () => import('@app/assets/lotties/v2/PICKED_UP_RESTAURANT.json'),
  READY_FOR_PICKUP_RESTAURANT_PICKUP: () =>
    import('@app/assets/lotties/v2/READY_FOR_PICKUP_RESTAURANT_PICKUP.json'),
  SECOND_EQUAL_BAR: () => import('@app/assets/lotties/v2/SECOND_EQUAL_BAR.json'),
  SECOND_LONG_MIDDLE_BAR: () => import('@app/assets/lotties/v2/SECOND_LONG_MIDDLE_BAR.json'),
  THIRD_EQUAL_BAR: () => import('@app/assets/lotties/v2/THIRD_EQUAL_BAR.json'),
  THIRD_LONG_MIDDLE_BAR: () => import('@app/assets/lotties/v2/THIRD_LONG_MIDDLE_BAR.json'),
  ICON_CONFIRMED_GROCERIES: () => import('@app/assets/lotties/v2/ICON_CONFIRMED_GROCERIES.json'),
};

export const loadLottieJSON = async (lottieToken, version = 'v2') => {
  const lottieMap = version === 'v1' ? fallbackLottieMapV1 : fallbackLottieMapV2;

  if (!lottieToken || lottieMap[lottieToken] === undefined) {
    return null;
  }
  let json = null;
  try {
    json = await lottieMap[lottieToken]().then((res) => res.default);
  } catch (e) {
    return null;
  }

  return json;
};
export async function getLottieInfo(alchemistProps, version = 'v2') {
  // let animationFrames = [{ start: 0, end: 70, reverse: false, loop: false }];
  let frameSegment = { play: true, start: 0, loop: true };
  const playOptions = getContentProp(alchemistProps, 'play_options');

  if (isObject(playOptions)) {
    frameSegment.loop = playOptions?.loops || false;
  }
  const source = getContentProp(alchemistProps, 'source');

  // for now it will always be null until the backend send the lottie url (next iterations).
  let animationData = null;
  // const lottieMap = version === 'v1' ? fallbackLottieMapV1 : fallbackLottieMapV2;
  const animation = source?.local_animation_name || source;
  if (!animationData && animation) {
    animationData = await loadLottieJSON(animation, version);
  }

  return { animationData, frameSegments: [frameSegment] };
}
