import React from 'react';
import PropTypes from 'prop-types';
import { Image, Text } from '@pedidosya/order-status-components';
import BaseButton from '../BaseButton';

const BasePillButton = ({ onClick, alchemistProps }) => {
  const imageData = alchemistProps.componentData.getImageData('image_container', { size: 20 });
  const pillIcons = alchemistProps.componentData.getImageData('pill_icon', {
    width: 90,
    height: 12,
  });
  const buttonIcons = alchemistProps.componentData.getImageData('button_icon');
  // Importante: este componente ignora los estilos de alchemist
  // tal y como hacen en apps (no tienen en cuenta paddings ni margin, son fijos)
  const buttonStyles = { height: '30px' };
  const buttonPadding = [2, 10];
  const buttonMargin = [15, 0, 15, 0];
  const titleProps = alchemistProps.componentData.getTextProps('title');
  const titlePadding = [0, 0, 0, imageData.hasImages() ? 4 : 0];

  return (
    <BaseButton
      componentData={alchemistProps.componentData}
      alchemistProps={alchemistProps}
      shadow
      padding={buttonPadding}
      margin={buttonMargin}
      style={buttonStyles}
      borderRadius={60}
      onClick={onClick}
    >
      {imageData &&
        imageData.hasImages() &&
        imageData
          .getImageList()
          .map((image) => (
            <Image
              className="image-data"
              key={image.url}
              src={image.url}
              fallback={image.fallbackSrc}
              width={image.width}
              height={image.height}
              alt={image.alt || 'Pedidos Ya'}
              fallbackRenderer={image.fallbackComponent}
            />
          ))}
      {pillIcons &&
        pillIcons.hasImages() &&
        pillIcons
          .getImageList()
          .map((image) => (
            <Image
              className="pill-icon"
              key={image.url}
              src={image.url}
              fallback={image.fallbackSrc}
              width={image.width}
              height={image.height}
              alt={image.alt || 'Pedidos Ya'}
              fallbackRenderer={image.fallbackComponent}
            />
          ))}
      {buttonIcons &&
        buttonIcons.hasImages() &&
        buttonIcons
          .getImageList()
          .map((image) => (
            <Image
              className="button-icon"
              key={image.url}
              src={image.url}
              fallback={image.fallbackSrc}
              width={image.size}
              alt={image.alt || 'Pedidos Ya'}
              fallbackRenderer={image.fallbackComponent}
            />
          ))}

      {titleProps && (
        <Text {...titleProps} padding={titlePadding}>
          {titleProps.text}
        </Text>
      )}
    </BaseButton>
  );
};

BasePillButton.propTypes = {
  onClick: PropTypes.func,
};

BasePillButton.defaultProps = {
  onClick: () => {},
};

export default BasePillButton;
