import React, { createContext, useContext, useMemo } from 'react';

const GlobalContext = createContext({});

function GlobalContexProvider({ context, children }) {
  const state = useMemo(
    () => ({
      ...context,
    }),
    [context],
  );
  return <GlobalContext.Provider value={state}>{children}</GlobalContext.Provider>;
}

export const useGlobalContext = () => useContext(GlobalContext);

export default GlobalContexProvider;
