import isString from '@commons/utils/string/isString';
import _removeSpecialChars from '@commons/utils/string/removeSpecialChars';

export function removeSpecialChars(str) {
  const string = _removeSpecialChars(str);
  return string.replace(/[\(\)]/gi, '').replace(/[^a-zA-Z ]/g, '');
}

export function ucFirst(str) {
  if (str.length <= 1) return str.toUpperCase();
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export function capitalize(str) {
  return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
}

export function isEmpty(value) {
  const valueTrimed = value && isString(value) && value.trim();
  return !valueTrimed;
}

export function padString(inString, char, repetition) {
  return String(inString)
    .padStart(inString.length + repetition, char)
    .padEnd(inString.length + repetition * 2, char);
}

export function removeSpaces(str) {
  return str.replace(/\s/g, '');
}
