import React from 'react';
import {
  ALIGNMENT,
  Container,
  FLOW_DIRECTION,
  Text,
  toFenixToken,
} from '@pedidosya/order-status-components';
import styled from 'styled-components';
import Icon from '../Icon';
import Divider from '../Divider';

const IssueChidren = styled(Container)`
  /*workaround for first level fenix buttons */
  & > div > div > button {
    margin-left: 0px !important;
    padding-left: 0px !important;
  }
`;

const ICON_MARGIN_RIGHT = 0;
const ICON_MARGIN_LEFT = 0;
const ICON_MIN_WIDTH = 32;

function IssueMessage(props) {
  const styles = props.componentData.getStyles();
  const iconButton = props.componentData.getContentProp('status_icon_view');
  const dividers = styles.getRawProp('dividers');
  let bgColor = toFenixToken(props.componentData.getContentProp('content_color'));
  const titleProps = props.componentData.getTextProps('title_label');
  let iconContainerStyles = {};
  let childrenContainerMargin = [0];

  if (iconButton) {
    iconContainerStyles = {
      minWidth: ICON_MIN_WIDTH + 'px',
      margin: `0px ${ICON_MARGIN_RIGHT}px 0px ${ICON_MARGIN_LEFT}px`,
    };
    childrenContainerMargin = [0, 0, 0, ICON_MIN_WIDTH + ICON_MARGIN_RIGHT + ICON_MARGIN_LEFT];
  }

  return (
    <Container
      expanded
      direction={FLOW_DIRECTION.COLUMN}
      padding={styles.getPadding([4, 0, 8, 0])}
      bgColor={bgColor}
    >
      {dividers?.top && <Divider {...props} />}
      <Container
        direction={FLOW_DIRECTION.ROW}
        expanded
        hAlignement={ALIGNMENT.START}
        vAlignment={ALIGNMENT.CENTER}
        fitContent
      >
        {iconButton && (
          <Container style={iconContainerStyles}>
            <Icon {...props} contentPropName="status_icon_view" />
            {/* <IconButtonV2
              icon={
                <IconV2
                  token={iconButton?.icon?.name}
                  filled={iconButton?.icon?.style === 'filled'}
                />
              }
              size={iconButton?.size}
              withStroke={iconButton?.stroke ? true : false}
              buttonStyle={iconButton?.style}
            /> */}
          </Container>
        )}
        <Text fitContent {...titleProps}>
          {titleProps?.text}
        </Text>
      </Container>
      <IssueChidren margin={childrenContainerMargin}>{props.children}</IssueChidren>
    </Container>
  );
}

export default IssueMessage;
