import React, { useEffect, useState, useContext, createContext } from 'react';
import PropTypes from 'prop-types';
import { getLogger } from '@app/logger';
import MicrositeErrorLoader from '@app/components/Microsite/MicrositeErrorLoader';
import isObject from '@commons/utils/object/isObject';
import { useSSR } from '@pedidosya/order-status-components';

const SERVICE_KEY = '[EXTERNAL SERVICES PROVIDER]';
const ExternalServiceContext = createContext();

export const useExternalServices = () => useContext(ExternalServiceContext);

export const allServicesAreValidInstances = (services) => {
  return (
    services &&
    isObject(services) &&
    'fwf' in services &&
    isObject(services?.fwf) &&
    'tracker' in services &&
    isObject(services?.tracker)
  );
};

const ExternalServiceProvider = ({ children, externalServices }) => {
  const { ssr } = useSSR();
  const [services, setServices] = useState(externalServices);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!ssr) {
      if (externalServices && allServicesAreValidInstances(externalServices)) {
        setServices(externalServices);
        setError(null);
      } else {
        const errorMessage = 'Some mandatory external services are not valid instances';
        getLogger().error(`${SERVICE_KEY} errorMessage`);
        setError(errorMessage);
      }
    }
  }, [externalServices, ssr]);

  if (error) return <MicrositeErrorLoader debugMessage={error} />;

  return (
    <ExternalServiceContext.Provider value={services}>{children}</ExternalServiceContext.Provider>
  );
};

ExternalServiceProvider.propTypes = {
  children: PropTypes.node.isRequired,
  externalServices: PropTypes.object.isRequired,
};

export default ExternalServiceProvider;
