import React, { forwardRef } from 'react';
import { ALIGNMENT, FLOW_DIRECTION } from '@pedidosya/order-status-components';
import BaseContainer from './BaseContainer';

const VerticalStack = forwardRef((props, ref) => {
  return (
    <BaseContainer
      {...props}
      ref={ref}
      direction={FLOW_DIRECTION.COLUMN}
      hAlignment={ALIGNMENT.CENTER}
      expandChildren
    />
  );
});

VerticalStack.displayName = 'VerticalStack';

export default VerticalStack;
