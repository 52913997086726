import { isEmpty } from '@app/utils/string';

export function extractTracking(alchemistTrackingList, tracker) {
  let trackingEvents = {};
  if (alchemistTrackingList && Array.isArray(alchemistTrackingList)) {
    trackingEvents = alchemistTrackingList.reduce((accum, evt) => {
      if (accum[evt.trigger] === undefined) {
        accum[evt.trigger] = [];
      }
      accum[evt.trigger].push(evt.id);
      accum[evt.id] = evt.properties;
      return accum;
    }, {});
  }

  return {
    markTriggerToBeTrackedOnce(eventTrigger) {
      if (!isEmpty(eventTrigger) && trackingEvents[eventTrigger] !== undefined) {
        trackingEvents[eventTrigger].forEach((eventId) => {
          tracker.setMaxEventCalls(eventId, 1);
        });
      }
    },
    trackAllByTrigger(eventTrigger) {
      if (!isEmpty(eventTrigger) && trackingEvents[eventTrigger] !== undefined) {
        trackingEvents[eventTrigger].forEach((eventId) => {
          tracker.track(eventId, trackingEvents[eventId]);
        });
      }
    },
    trackById(eventId) {
      if (!isEmpty(eventId) && trackingEvents[eventId] !== undefined) {
        tracker.track(eventId, trackingEvents[eventId]);
      }
    },
  };
}
