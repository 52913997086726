import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ALIGNMENT, Container, Skeleton, useSSR } from '@pedidosya/order-status-components';
import Parcel from 'single-spa-react/parcel';
import ErrorPage from '@app/components/ui/organisms/ErrorPage/ErrorPage';
import parcels from './parcels';
import { isEmpty } from '@app/utils/string';
import messages from './messages';
import { getLogger } from '@app/logger';

const WebControlWrapper = styled(Container)`
  max-width: 100%;
  & > .view-container {
    width: 100% !important;
  }
`;

const WebControlArea = styled(Container).attrs(() => ({
  vAlignment: ALIGNMENT.CENTER,
  hAlignment: ALIGNMENT.CENTER,
}))`
  width: 100%;
  position: relative;
  border-radius: 5px;
  ${({ withBorder }) => (withBorder ? 'box-shadow: 0 0 7px -2px #00000030;' : '')}
`;

function WebControl(props) {
  const scrolleableAreaRef = useRef(null);
  const { ssr } = useSSR();
  const [parcelData, setParcelData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const padding = props.componentData.getStyles().getPadding();
  let maxHeight = props.componentData.getContentProp('height', 0);
  maxHeight = Number.isNaN(Number(maxHeight)) ? 0 : Number(maxHeight);
  const url = props.componentData.getContentProp('url', null);

  useEffect(() => {
    let mounted = true;
    if (url && !isEmpty(url)) {
      const parcel = parcels.find((parcel) => parcel.regex.test(url));
      if (parcel) {
        System.import(parcel.id)
          .then((result) => {
            if (mounted) {
              setParcelData({
                id: parcel.id,
                config: result,
                props: parcel.propsExtractor(
                  url,
                  props?.globals?.alchemistContext,
                  props?.publicEnv,
                ),
              });
            }
          })
          .catch((e) => {
            if (mounted) {
              setError(true);
              setLoading(false);
            }
          });
      } else {
        if (mounted) {
          setError(true);
          setLoading(false);
        }
      }
    }
    return () => {
      mounted = false;
    };
  }, [url]);

  function onLoad() {
    setLoading(false);
  }
  function onError(e) {
    getLogger().error(`WebControl - Error loading parcel '${parcelData.id}: ${e.message}'`);
    setLoading(false);
  }

  return (
    <WebControlWrapper padding={padding} style={{ position: 'relative' }}>
      {loading && (
        <WebControlArea data-testid="loader">
          <Skeleton width={'100%'} height={'150px'} />
        </WebControlArea>
      )}
      {error && (
        <WebControlArea withBorder data-testid="error">
          <ErrorPage
            anchoredToParent
            contentHeight
            title={messages.webControlLoadingError.defaultMessage}
          />
        </WebControlArea>
      )}
      {!ssr && !error && parcelData && (
        <div
          ref={scrolleableAreaRef}
          className="view-container"
          style={{ height: maxHeight > 0 ? maxHeight + 'px' : 'auto', overflowY: 'auto' }}
        >
          <Parcel
            config={parcelData.config}
            handleError={onError}
            parcelDidMount={onLoad}
            fwfPromise={Promise.resolve({})}
            {...parcelData.props}
          />
        </div>
      )}
    </WebControlWrapper>
  );
}

WebControl.propTypes = {};

export default WebControl;
