const EMPTY = '';

function stringify(obj) {
  try {
    return JSON.stringify(obj);
  } catch (e) {
    return EMPTY;
  }
}

export default stringify;
