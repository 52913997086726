import { TRACKING_TRIGGERS } from '@app/tracking';
import { POSITION } from '@pedidosya/order-status-components';

const AlchemistMessageData = (props) => {
  const titleProps = props.componentData.getTextProps('title');
  const backgroundColor = props.componentData.getStyles().getBgColor();
  let borderColor = [props.componentData.getStyles().getBorderColor()];
  let margin = props.componentData.getStyles().getMargin([0], 'margin');
  const padding = [12, 10, 12, 10]; //props.componentData.getStyles().getPadding([12, 10, 12, 10], 'padding');
  let borderSize = [1, 1, 1, 4];
  let borderPosition = [POSITION.TOP, POSITION.RIGHT, POSITION.BOTTOM, POSITION.LEFT];
  const imageData = props.componentData.getImageData();
  const icon = imageData && imageData?.hasImages() ? imageData.getFirstImage() : null;
  const dividerStyles = props.componentData.getStyles().getDividerProps();

  if (dividerStyles?.borderSize) {
    margin = [10, 0, 0, 0];
    borderSize = dividerStyles.borderSize;
  }

  if (dividerStyles?.borderPosition) {
    borderPosition = dividerStyles.borderPosition;
  }
  if (dividerStyles?.borderColor) {
    borderColor = dividerStyles.borderColor;
  }

  if (icon && typeof icon === 'object') {
    icon.margin = [0, 12, 0, 0];
    icon.hideOnError = false;
  }

  //ACTIONS & TRACKING
  const onMessageClose = () => {
    props.componentData.getTracking().trackAllByTrigger(TRACKING_TRIGGERS.CLICKED);
  };

  return props.children({
    titleProps,
    backgroundColor,
    borderColor,
    borderSize,
    margin,
    padding,
    icon,
    borderPosition,
    onMessageClose,
  });
};

export default AlchemistMessageData;
