import React from 'react';
import { ALIGNMENT, Container, FLOW_DIRECTION } from '@pedidosya/order-status-components';

function ImageTextActions({ ImageComponent, FirstRowComponent, SecondRowComponent, ...props }) {
  const textContainerMargin = [0, 0, 0, 10];

  return (
    <Container
      id={props?.containerId}
      data-testid={props?.testId}
      vAlignment={ALIGNMENT.CENTER}
      expanded
      fitContent
    >
      {ImageComponent}
      <Container
        direction={FLOW_DIRECTION.COLUMN}
        vAlignment={ALIGNMENT.CENTER}
        margin={textContainerMargin}
        fillSpace
      >
        {FirstRowComponent}
        {SecondRowComponent}
      </Container>
      <Container direction={FLOW_DIRECTION.COLUMN} margin={textContainerMargin}>
        {props.children}
      </Container>
    </Container>
  );
}

ImageTextActions.defaultProps = {
  testId: undefined,
  containerId: undefined,
};

export default ImageTextActions;
