import React, { createContext, useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import IntlProvider from '@app/providers/I18nProvider/IntlProvider';

const initialModalState = {
  translate: () => {},
};

const I18nContext = createContext(initialModalState);

const I18nProvider = injectIntl(({ children, intl: { formatMessage } }) => {
  const translate = useCallback(
    (obj, options = {}) => formatMessage(obj, options),
    [formatMessage],
  );

  const state = useMemo(
    () => ({
      translate,
    }),
    [translate],
  );

  return <I18nContext.Provider value={state}>{children}</I18nContext.Provider>;
});

const I18nWrapper = ({ children, messages, locale }) => {
  return (
    <IntlProvider messages={messages} locale={locale}>
      <I18nProvider>{children}</I18nProvider>
    </IntlProvider>
  );
};

I18nWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  messages: PropTypes.object,
};

export const useTranslate = () => useContext(I18nContext);

export default I18nWrapper;
