import useIsFirstRender from '@app/hooks/useIsFirstRender';
import { Spinner } from '@pedidosya/order-status-components';
import React from 'react';

const LoginPage = React.lazy(() => import('./Login'));

const LoginLoader = (props) => {
  const { isFirstRender } = useIsFirstRender();
  if (!isFirstRender) {
    return (
      <React.Suspense fallback={<Spinner />}>
        <LoginPage {...props} />
      </React.Suspense>
    );
  } else {
    return <Spinner />;
  }
};

export default LoginLoader;
