import React from 'react';
import PropTypes from 'prop-types';
import { Text, Image, Button, ALIGNMENT } from '@pedidosya/order-status-components';
import { ACTION_TRIGGER, ACTION_TYPE } from '@app/alchemist/utils/actions';
import { goToTargetUrl } from '@app/utils/url';
import { TRACKING_TRIGGERS } from '@app/tracking';
import { getLogger } from '@app/logger';

function ImageBorderButton(props) {
  const isEnabled = props.componentData.getContentProp('enabled', false);
  const bgColor = props.componentData.getStyles().getBgColor('transparent');
  const imageData = props.componentData.getImageData('image', { dpi: 1 });
  const image = imageData?.hasImages() ? imageData.getFirstImage() : null;

  const textProps = props.componentData.getTextProps('title');
  const padding = props.componentData.getStyles().getPadding([8]);
  const buttonStyles = props.componentData.hasComponentId([
    'onlineHelpComponent',
    'cancelOrderComponent',
  ])
    ? { margin: '0 auto' }
    : {};
  const imgMargin = [0, 4, 0, 0];
  const imgSize = 14;
  const navigateAction = props.componentData.getAction(
    ACTION_TYPE.NAVIGATE,
    ACTION_TRIGGER.CLICKED,
  );

  const onButtonClicked = () => {
    props.componentData.getTracking().trackAllByTrigger(TRACKING_TRIGGERS.CLICKED);
    getLogger().warn(`From ImageBorderButton, targetUrl: ${navigateAction?.targetUrl}`);
    goToTargetUrl(navigateAction);
  };

  const borderProps = {
    borderSize: [1],
    borderColor: ['transparent'],
  };

  return (
    <Button
      disabled={!isEnabled}
      bgColor={bgColor}
      color={textProps?.color}
      padding={padding}
      vAlignment={ALIGNMENT.CENTER}
      onClick={onButtonClicked}
      style={buttonStyles}
      {...borderProps}
    >
      {image && image?.url && (
        <Image
          src={image.url}
          fallback={image.fallbackSrc}
          alt={textProps.text}
          width={imgSize}
          height={imgSize}
          fallbackRenderer={image.fallbackComponent}
          margin={imgMargin}
        />
      )}
      <Text as="span" {...textProps}>
        {textProps.text}
      </Text>
    </Button>
  );
}

ImageBorderButton.propTypes = {
  enabled: PropTypes.bool,
  title: PropTypes.object.isRequired,
};

export default ImageBorderButton;
