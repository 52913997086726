import isObject from '@commons/utils/object/isObject';
import { extractActions } from '../actions';
import { extractImageData } from '../images';
import { extractStyles } from '../styles';
import { getTextElementPropsByKey } from '../typography';
import { getContentProp } from '../content';
import { extractTracking } from '../tracking';

class AlchemistComponentData {
  constructor(componentProp, tracker) {
    if (!componentProp || !isObject(componentProp)) {
      throw new Error(`Invalid component props "${componentProp}"`);
    }
    this.actions = extractActions(componentProp);
    this.tracking = extractTracking(componentProp?.tracking, tracker);
    this.styles = extractStyles(componentProp);
    this.props = componentProp;
  }
  getAlchemistProps() {
    return this.props;
  }
  getChildren() {
    return this.props?.children;
  }

  getEventList() {
    return this.props?.events?.publish && Array.isArray(this.props?.events?.publish)
      ? this.props.events.publish
      : [];
  }

  getTracking() {
    return this.tracking;
  }

  getId() {
    return this.props.id || 'unknown';
  }

  getImageData(imageProp = 'image_container', imgDefaultOptions = {}) {
    return extractImageData(this.props, imageProp, imgDefaultOptions);
  }

  getLottieData(lottieToken) {
    let animationName = 'unknown';
    if (this.props[lottieToken] !== undefined) {
      animationName =
        this.props[lottieToken]?.local_animation_name || this.props[lottieToken] || 'unknown';
    }
    return {
      id: animationName,
      play_options: {
        loops: true,
      },
      source: {
        local_animation_name: animationName,
      },
    };
  }

  getTextProps(textKey = 'text', styles = {}) {
    return getTextElementPropsByKey(this.props, textKey, styles);
  }
  getTextPropsFrom(obj, textKey = 'text', styles = {}) {
    return getTextElementPropsByKey(obj, textKey, styles);
  }

  getContentProp(property, defaultValue = null) {
    return getContentProp(this.props, property, defaultValue);
  }

  getContentPropFrom(obj, property, defaultValue = null) {
    return getContentProp(obj, property, defaultValue);
  }

  getSpacing() {
    return this.getContentProp('spacing') || 0;
  }

  hasComponentId(componentIdList) {
    if (componentIdList?.length > 0) {
      for (let i = 0; i < componentIdList.length; i++) {
        if (this.getId() === componentIdList[i]) {
          return true;
        }
      }
    }
    return false;
  }

  getAction(actionId, actionTrigger) {
    return this.actions.get(actionId, actionTrigger);
  }

  getStyles() {
    return this.styles;
  }
}

export default AlchemistComponentData;
