import React from 'react';
import { ALIGNMENT, Container, FLOW_DIRECTION, Skeleton } from '@pedidosya/order-status-components';
import ProgressBarLottiePlaceholder from './ProgressBarLottiePlaceholder';
import RoundedIconPlaceholder from './RoundedIconPlaceholder';
const FullProgressBarLottiePlaceholder = () => {
  const containerMargin = [20, 0];
  const descriptionMargin = [0, 40];
  return (
    <Container
      margin={containerMargin}
      direction={FLOW_DIRECTION.COLUMN}
      hAlignment={ALIGNMENT.CENTER}
      expanded
    >
      <RoundedIconPlaceholder />
      <ProgressBarLottiePlaceholder />
      <Container direction={FLOW_DIRECTION.ROW}>
        <Skeleton height="10px" width="50px" />
        <Container margin={descriptionMargin}>
          <Skeleton height="10px" width="50px" />
        </Container>
        <Skeleton height="10px" width="50px" />
      </Container>
    </Container>
  );
};

export default FullProgressBarLottiePlaceholder;
