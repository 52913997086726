import React from 'react';
import PropTypes from 'prop-types';
import { IconV2 } from '@pedidosya/order-status-components';

const Icon = (props) => {
  const iconButton = props.componentData.getContentProp(props?.contentPropName, null);

  if (!iconButton) {
    return null;
  }
  return (
    <IconV2
      token={iconButton?.icon?.name}
      filled={iconButton?.icon?.style === 'filled'}
      size={iconButton?.size}
    />
  );
};

Icon.propTypes = {
  contentPropName: PropTypes.string.isRequired,
};

export default Icon;
