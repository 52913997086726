import React from 'react';

import { ACTION_TYPE } from '@app/alchemist/utils/actions';
import { getLogger } from '@app/logger';
import ShareableElement from '@app/components/ShareableElement/ShareableElement';
import BasePillButton from './BasePillButton';
import { TRACKING_TRIGGERS } from '@app/tracking';

const CourierPillButton = (props) => {
  //ACTIONS & TRACKING
  const onShareSuccess = () => {
    props.componentData.getTracking().trackAllByTrigger(TRACKING_TRIGGERS.CLICKED);
  };
  const onShareError = (errorMessage) => {
    getLogger().warn(`CourierPillButton:share error - ${errorMessage}`);
  };

  const getShareableData = () => {
    const navigateAction = props.componentData.getAction(ACTION_TYPE.NAVIGATE);

    if (navigateAction && navigateAction?.target_url) {
      return {
        text: navigateAction?.target_url,
        url: navigateAction?.target_url,
      };
    } else {
      return null;
    }
  };

  return (
    <ShareableElement
      shareableData={getShareableData}
      onShareSuccess={onShareSuccess}
      onShareError={onShareError}
    >
      <BasePillButton alchemistProps={props} />
    </ShareableElement>
  );
};

CourierPillButton.propTypes = {};

export default CourierPillButton;
