import React, { useEffect, useMemo, useState, memo } from 'react';
import PropTypes from 'prop-types';
import Renderer from './Renderer';
import { useSSR } from '@pedidosya/order-status-components';

function createComponentMap(componentList) {
  if (Array.isArray(componentList)) {
    let totalComponents = 1;
    const componentsMetadata = Object.values(componentList).reduce((accum, componentId, index) => {
      accum[componentId] = {
        index,
      };
      ++totalComponents;
      return accum;
    }, {});

    return {
      metadata: componentsMetadata,
      totalComponents: totalComponents,
    };
  }

  return {
    metadata: {},
    totalComponents: 0,
  };
}

const ComponentGroupRenderer = ({
  componentIdentifiers,
  componentList,
  renderAllIfNoIdentifiers,
  children,
}) => {
  const { ssr } = useSSR();

  const renderAlchemistComponents = (components) => {
    return components.map((component) => <Renderer key={component.id} component={component} />);
  };

  const renderAlchemistComponentsBasedOnIdentifiers = (components, allowedComponentsMap) => {
    if (allowedComponentsMap.totalComponents > 0) {
      const filteredComponents = components.filter(
        (c) => allowedComponentsMap.metadata[c.id] !== undefined,
      );
      return renderAlchemistComponents(
        filteredComponents.reduce((accum, component) => {
          const metadata = allowedComponentsMap.metadata[component.id];
          accum[metadata.index] = component;
          return accum;
        }, Array(filteredComponents.length)),
      );
    }

    return [];
  };

  const renderComponents = () => {
    if (componentList && Array.isArray(componentList) && componentList?.length > 0) {
      try {
        if (componentIdentifiers?.length > 0) {
          const componentsOutput = renderAlchemistComponentsBasedOnIdentifiers(
            componentList,
            allowedComponentsMap,
          );

          if (componentsOutput) {
            return { output: componentsOutput, error: null };
          }
        } else if (renderAllIfNoIdentifiers) {
          const componentsOutput = renderAlchemistComponents(componentList);

          if (componentsOutput) {
            return { output: componentsOutput, error: null };
          }
        }

        return { output: null, error: null };
      } catch (e) {
        return { output: null, error: e.message };
      }
    } else {
      return { output: null, error: 'No alchemist components provided' };
    }
  };

  const allowedComponentsMap = useMemo(
    () => createComponentMap(componentIdentifiers),
    [componentIdentifiers],
  );

  let output = null;
  let error = null;
  if (ssr) {
    const result = renderComponents();
    output = result?.output;
    error = result?.error;
  }

  const [componentsOutput, setComponentsOutput] = useState(output);
  const [renderError, setRenderError] = useState(error);

  useEffect(() => {
    const result = renderComponents();
    if (result.error) {
      setRenderError(result.error);
    } else {
      setComponentsOutput(result.output);
      setRenderError(null);
    }
  }, [allowedComponentsMap, componentIdentifiers, componentList]);

  return children({ componentsOutput, error: renderError });
};

ComponentGroupRenderer.protoTypes = {
  children: PropTypes.func.isRequired,
  componentList: PropTypes.array.isRequired,
  componentIdentifiers: PropTypes.array.isRequired,
  renderAllIfNoIdentifiers: PropTypes.bool,
};

ComponentGroupRenderer.defaultProps = {
  renderAllIfNoIdentifiers: false,
};

export default memo(ComponentGroupRenderer);
