import React, { useState } from 'react';
import AlchemistMessageData from './AlchemistMessageData';
import { Toast, Text, Image, Container, FLOW_DIRECTION } from '@pedidosya/order-status-components';
import SessionStorage from '@app/services/cache/SessionStorage';
const storage = new SessionStorage();

const DismissibleMessage = (props) => {
  const getMessageId = () =>
    (props?.globals?.alchemistContext?.getOrderId() || '$') + '_' + props?.id;
  const storeMessageData = (show, text) => {
    const messageId = getMessageId();
    storage.set(messageId, { id: messageId, show, text });
  };
  const getStoredMessageData = () => {
    return storage.get(getMessageId());
  };

  const messageStoredData = getStoredMessageData();

  const [showMessage, setShowMessage] = useState(
    messageStoredData && messageStoredData.show !== undefined ? messageStoredData.show : true,
  );
  const [lastMessageText, setLastMessageText] = useState(messageStoredData?.text);
  return (
    <AlchemistMessageData {...props}>
      {({
        titleProps,
        backgroundColor,
        margin,
        padding,
        borderColor,
        borderSize,
        borderPosition,
        icon,
        onMessageClose,
      }) => {
        const onCloseClicked = () => {
          onMessageClose();
          setShowMessage(false);
          setLastMessageText(titleProps?.text);
          storeMessageData(false, titleProps?.text);
        };

        if (lastMessageText && lastMessageText !== titleProps?.text) {
          setShowMessage(true);
          storeMessageData(true, titleProps?.text);
        }

        if (!showMessage) return null;

        return (
          <Toast
            margin={margin}
            bgColor={backgroundColor}
            borderSize={borderSize}
            borderColor={borderColor}
            borderPosition={borderPosition}
            onClose={onCloseClicked}
            expanded
            showCloseButton={showMessage}
            padding={padding}
          >
            <Container direction={FLOW_DIRECTION.ROW}>
              {icon && (
                <Image
                  src={icon.url}
                  fallback={icon.fallbackSrc}
                  width={icon.size}
                  height={icon.size}
                  fallbackRenderer={icon.fallbackComponent}
                  margin={icon.margin}
                  hideOnError={icon.hideOnError}
                />
              )}
              <Text {...titleProps}>{titleProps.text}</Text>
            </Container>
          </Toast>
        );
      }}
    </AlchemistMessageData>
  );
};

export default DismissibleMessage;
