import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { SCREEN_BREAKPOINTS } from '@pedidosya/order-status-components';
const SectionContentWrapper = styled.div`
  display: flex;
  height: auto;
  position: relative;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  /* background-color: #ffff; */

  width: 100%;
  max-width: 1200px;
  margin-top: theme.spacing(12);

  @media screen and (max-width: ${SCREEN_BREAKPOINTS.DESKTOP}px) {
    height: 100vh;
    justify-content: flex-start;
    width: 100%;
    border-radius: 0px;
    padding: 0;
    margin: 0;
  }
`;

const SectionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  & > * {
    width: 100%;
  }
`;

const SectionLayout = forwardRef(({ headerComponent, children, ...props }, ref) => {
  return (
    <SectionContainer ref={ref} {...props}>
      {headerComponent && headerComponent}
      <SectionContentWrapper>{children}</SectionContentWrapper>
    </SectionContainer>
  );
});

SectionLayout.displayName = 'SectionLayout';

export default SectionLayout;
