import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import useFetcher from '@app/hooks/fetcher/useFetcher';
import { fetchAlchemistOrder } from '@app/services/fetchOrder';

const DEFAULT_REFETCH_MS = 30000;
const DEFAULT_RETRY_INTERVAL = 15000;

function OrderStatusData({ children, initialOrderData, orderId, countryId }) {
  const [refetchMs, setRefetchMs] = useState(DEFAULT_REFETCH_MS);
  const orderServiceFetch = useCallback(
    () =>
      fetchAlchemistOrder({ countryId, orderId }).then((response) => {
        setRefetchMs(
          response?.data.order?.page_content?.context?.refresh_configuration?.update_rate_millis ||
            DEFAULT_REFETCH_MS,
        );
        return response;
      }),
    [countryId, orderId],
  );

  const {
    error,
    response: orderData,
    httpCode,
  } = useFetcher({
    fetchService: orderServiceFetch,
    initialData: initialOrderData,
    maxRetries: 3,
    retryMillisecondsInterval: DEFAULT_RETRY_INTERVAL,
    refetchMillisecondsInterval: refetchMs,
  });

  return children({
    error,
    orderData: { ...orderData, lastUpdate: Date.now() },
    httpCode,
  });
}

OrderStatusData.propTypes = {
  children: PropTypes.func.isRequired,
  countryId: PropTypes.number.isRequired,
  orderId: PropTypes.string.isRequired,
  initialOrderData: PropTypes.object.isRequired,
};

export default React.memo(OrderStatusData);
