import ROUTES from '@app/constants/routes';
import { sendNativeEvent } from '@app/services/native/eventManager';
import { historyBack } from '@app/services/native/events/historyBack';
import { nativeRedirection } from '@app/services/native/events/nativeRedirection';
import { goToUrl as redirectToUrl } from '@app/utils/url';
import { getDefaultPlatformEventContext } from './utils';

const isDeeplink = (url) => url && url.startsWith('pedidosya://');

class MobileEvents {
  context = {};

  setContext(contextData = getDefaultPlatformEventContext()) {
    this.context = contextData;
  }

  closePage() {
    sendNativeEvent(this.context.platformKey, historyBack());
  }

  goToUrl(url, eventName) {
    if (isDeeplink(url)) {
      redirectToUrl(url);
    } else {
      sendNativeEvent(this.context.platformKey, nativeRedirection(eventName, url));
    }
  }
}

export default MobileEvents;
