import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Header } from '@pedidosya/order-status-components';
import SectionLayout from './SectionContainer';

const OrderStatusViewContainer = forwardRef(
  ({ showHeader, children, headerTitle, onHistoryBack, ...props }, ref) => {
    return (
      <SectionLayout
        ref={ref}
        {...props}
        headerComponent={
          showHeader ? <Header title={headerTitle} onBack={onHistoryBack} /> : undefined
        }
      >
        {children}
      </SectionLayout>
    );
  },
);

OrderStatusViewContainer.displayName = 'OrderStatusViewContainer';
OrderStatusViewContainer.propTypes = {
  children: PropTypes.node.isRequired,
  headerTitle: PropTypes.string.isRequired,
  onHistoryBack: PropTypes.func.isRequired,
  columns: PropTypes.number,
  showHeader: PropTypes.bool,
};

OrderStatusViewContainer.defaultProps = {
  columns: 3,
  showHeader: true,
};

export default OrderStatusViewContainer;
