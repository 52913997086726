import React from 'react';
import { Image } from '@pedidosya/order-status-components';
import { ACTION_TYPE } from '@app/alchemist/utils/actions';
import { goToTargetUrl } from '@app/utils/url';
import { TRACKING_TRIGGERS } from '@app/tracking';
import { getLogger } from '@app/logger';

const Banner = (props) => {
  let imageData = props.componentData.getImageData('image', { size: 318 });
  const navigateAction = props.componentData.getAction(ACTION_TYPE.NAVIGATE);

  const onBannerClicked = () => {
    props.componentData.getTracking().trackAllByTrigger(TRACKING_TRIGGERS.CLICKED);
    getLogger().warn(`Banner targetUrl: ${navigateAction?.targetUrl}`);
    goToTargetUrl(navigateAction);
  };

  return imageData && imageData.hasImages()
    ? imageData
        .getImageList()
        .map((image) => (
          <Image
            onClick={onBannerClicked}
            key={image.url}
            src={image.url}
            width={318}
            height={88}
            fallbackRenderer={image.fallbackComponent}
            fallback={image.fallbackSrc}
            borderRadius={6}
          />
        ))
    : null;
};

export default Banner;
