import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { reloadPage } from '@app/utils/url';
import {
  Image,
  Button,
  useScreenSize,
  FONT_TOKEN,
  isWindowObjectDefined,
} from '@pedidosya/order-status-components';
import locationNotFoundImage from '@app/assets/icons/order-status/not-found-location.svg';
import ErrorPage from '../ErrorPage/ErrorPageLoader';
import messages from '@app/pages/OrderStatus/messages';
import { useTranslate } from '@app/providers/I18nProvider';

import {
  buildPageDescriptor,
  getStoredData,
  limitNumberRange,
  percentageToPx,
  pxToPercentage,
  storeData,
} from './utils';

import OrderStatusViewContainer from '@app/pages/OrderStatus/components/OrderStatusViewContainer';
import OSMobileTrackingOrderView from './views/mobile/OSMobileTrackingOrderView';
import OSDesktopTrackingOrderView from './views/desktop/OSDesktopTrackingOrderView';
import OSDesktopNonTrackingView from './views/desktop/OSDesktopNonTrackingView';
import OSMobileNonTrackingView from './views/mobile/OSMobileNonTrackingView';
import { getMobileOperatingSystem, isAndroid } from '@app/utils/userAgent/getMobileOS';
import { isEmpty } from '@app/utils/string';

const VIEW_DATA_CACHE_KEY = 'VIEW_DATA_CACHE_KEY';
const BS_BEHAVIOR_CACHE_KEY = 'BS_BEHAVIOR_CACHE_KEY';
const BS_MIN_SNAP_POINT_PERCENTAGE = 20;
const BS_MIN_SNAP_POINT_DISTANCE = 10;
function calculateTotalLayoutHeight(windowHeight, layoutHeight) {
  if (layoutHeight > 0) {
    return 100 - (windowHeight * 100) / layoutHeight + windowHeight;
  }

  return windowHeight;
}
function calculateMaxSnapPoint({ layoutHeight, bottomsheetFloatingComponentScreenHeightArea }) {
  return Math.floor(
    pxToPercentage(layoutHeight, layoutHeight - bottomsheetFloatingComponentScreenHeightArea),
  );
}

function calculateBsSnapPoints(
  currentSnapPoint,
  bsInitialHeight,
  bsMinHeightPercentage,
  bsMaxHeightPercentage,
) {
  const minSnapPoint =
    bsMinHeightPercentage > BS_MIN_SNAP_POINT_PERCENTAGE
      ? BS_MIN_SNAP_POINT_PERCENTAGE
      : limitNumberRange(
          bsMinHeightPercentage || BS_MIN_SNAP_POINT_PERCENTAGE,
          BS_MIN_SNAP_POINT_PERCENTAGE,
          100,
        );
  const bsInitial = limitNumberRange(
    bsInitialHeight || BS_MIN_SNAP_POINT_PERCENTAGE,
    BS_MIN_SNAP_POINT_PERCENTAGE,
    100,
  );
  const bsMin = limitNumberRange(
    bsMinHeightPercentage || BS_MIN_SNAP_POINT_PERCENTAGE,
    BS_MIN_SNAP_POINT_PERCENTAGE,
    100,
  );
  const bsMax = limitNumberRange(bsMaxHeightPercentage || 60, BS_MIN_SNAP_POINT_PERCENTAGE, 100);

  const snappoints = [minSnapPoint, bsMin, bsMax];

  if (
    Math.abs(currentSnapPoint - bsMax) >= BS_MIN_SNAP_POINT_DISTANCE &&
    Math.abs(bsInitial - bsMin) >= BS_MIN_SNAP_POINT_DISTANCE
  ) {
    snappoints.push(limitNumberRange(bsInitial, minSnapPoint, bsMax));
  }

  const map = new Map();
  return snappoints
    .filter((snapPoint, index) => {
      if (map.has(snapPoint)) {
        return false;
      } else {
        map.set(snapPoint, index);
        return true;
      }
    })
    .sort((a, b) => a - b);
}

function viewDataHasChanged(viewData, prevViewData) {
  const layout = viewData.layout;
  const bottomsheet = viewData.bottomsheet;
  return (
    layout.height != prevViewData.layout.height ||
    bottomsheet.floatingComponents.height != prevViewData.bottomsheet.floatingComponents.height ||
    bottomsheet.dockedComponents.height != prevViewData.bottomsheet.dockedComponents.height
  );
}

const OrderState = ({
  alchemistContext,
  platformName,
  countryId,
  showMap,
  hasNotch,
  isNative,
  onHistoryBack,
  viewType,
  isSSR,
}) => {
  const { translate } = useTranslate();
  const { isDesktop, windowHeight } = useScreenSize();
  const [isFirstPaint, setIsFirstPaint] = useState(true);
  const BSRef = useRef(null);
  const alchemistContextRef = useRef(alchemistContext);
  const prevViewData = getStoredData(VIEW_DATA_CACHE_KEY, {
    orderId: alchemistContextRef.current.getOrderId(),
    isDesktop: isDesktop(),
    defaultVal: {
      layout: {
        height: 0,
      },
      bottomsheet: {
        floatingComponents: {
          height: 0,
        },
        dockedComponents: {
          height: 0,
        },
      },
    },
  });

  const layoutRef = useRef(null);
  const viewDataRef = useRef(prevViewData);

  const hasHeader = alchemistContextRef.current.isHeaderActive();
  const layoutHeight = calculateTotalLayoutHeight(
    limitNumberRange(windowHeight, 400, 900),
    layoutRef.current?.offsetHeight,
  );
  const [pageDescriptor, setPageDescriptor] = useState(
    buildPageDescriptor({
      alchemistContext: alchemistContextRef.current,
      isNative,
      initialBSMinSnappointPercentage: BS_MIN_SNAP_POINT_PERCENTAGE,
      layoutHeight: layoutHeight,
      ssrMinBSAbsHeight: isDesktop() ? null : 600,
      bottomsheetDockedComponentScreenHeightArea: 0,
      bottomsheetFloatingComponentScreenHeightArea: 0,
      hasNotch,
      hasHeader,
      ssr: isFirstPaint,
      maxSnapPointPercentage: calculateMaxSnapPoint({
        layoutHeight: layoutHeight,
        bottomsheetFloatingComponentScreenHeightArea: 0,
      }),
    }),
  );

  const BSbehavior = getStoredData(BS_BEHAVIOR_CACHE_KEY, {
    orderId: alchemistContextRef.current.getOrderId(),
    isDesktop: isDesktop(),
    defaultVal: {
      sticky: false,
      maxSnapPoint: pageDescriptor.bottomSheet.screenHeight.max.percentage,
    },
  });

  const BSBehaviorRef = useRef(BSbehavior);

  const bsCurrentSnapPointRef = useRef(alchemistContextRef.current.getBottomSheetHeight());

  const [bsSnapPoints, setBSSnapPoints] = useState(
    calculateBsSnapPoints(
      bsCurrentSnapPointRef.current,
      alchemistContextRef.current.getBottomSheetHeight(),
      pageDescriptor?.bottomSheet?.screenHeight?.min?.percentage,
      pageDescriptor?.bottomSheet?.screenHeight?.max?.percentage,
    ),
  );

  const recalculateLayoutHeightChanges = (
    { BScontentHeight } = {
      BScontentHeight: 0,
    },
  ) => {
    const totalHeight = calculateTotalLayoutHeight(
      windowHeight,
      viewDataRef.current.layout.height || layoutHeight || 0,
    );
    if (totalHeight > 0) {
      let bottomsheetFloatingComponentScreenHeightArea = 0;
      let bottomsheetDockedComponentScreenHeightArea = 0;

      if (pageDescriptor?.bottomSheet?.topFloatingComponents?.length > 0) {
        if (viewDataRef.current.bottomsheet.floatingComponents.height > 0) {
          bottomsheetFloatingComponentScreenHeightArea = Math.ceil(
            viewDataRef.current.bottomsheet.floatingComponents.height + BScontentHeight,
          );
        }
      }

      if (pageDescriptor?.bottomSheet?.dockedComponentIds?.length > 0) {
        if (viewDataRef.current.bottomsheet.dockedComponents.height) {
          bottomsheetDockedComponentScreenHeightArea =
            viewDataRef.current.bottomsheet.dockedComponents.height;
        }
      }

      const maxSnapPointPercentage = calculateMaxSnapPoint({
        layoutHeight: totalHeight,
        bottomsheetFloatingComponentScreenHeightArea,
      });

      const descriptor = buildPageDescriptor({
        alchemistContext: alchemistContext,
        isNative,
        maxSnapPointPercentage,
        initialBSMinSnappointPercentage: bsSnapPoints[0],
        layoutHeight: totalHeight,
        bottomsheetDockedComponentScreenHeightArea,
        bottomsheetFloatingComponentScreenHeightArea,
        hasNotch,
        hasHeader: hasHeader,
        ssr: false,
      });

      return descriptor;
    }

    return null;
  };

  const onStateChange = () => {
    const descriptor = recalculateLayoutHeightChanges({
      BScontentHeight: 0,
    });

    if (descriptor) {
      const prevSnapPointToMax = bsSnapPoints[bsSnapPoints.length - 2];
      if (prevSnapPointToMax < bsCurrentSnapPointRef?.current) {
        BSBehaviorRef.current.sticky = true;
        bsCurrentSnapPointRef.current = descriptor?.bottomSheet?.screenHeight?.max?.percentage;
        storeData(BS_BEHAVIOR_CACHE_KEY, {
          orderId: alchemistContextRef.current.getOrderId(),
          isDesktop: isDesktop(),
          data: BSBehaviorRef.current,
        });
      }
      if (BSRef.current) {
        if (BSBehaviorRef.current.sticky) {
          if (prevSnapPointToMax < bsCurrentSnapPointRef?.current) {
            bsCurrentSnapPointRef.current = descriptor?.bottomSheet?.screenHeight?.max?.percentage;
            BSRef.current.goTop();
          }
        } else {
          bsCurrentSnapPointRef.current = descriptor?.bottomSheet?.screenHeight?.min?.percentage;
          BSRef.current.goTo(
            percentageToPx(layoutHeight, descriptor?.bottomSheet?.screenHeight?.min?.percentage),
          );
        }
      }

      return descriptor;
    }

    return null;
  };

  // Every time the order changes, the whole layout should be
  // recalculated, because it could be a totally new alchemist json.
  useEffect(() => {
    alchemistContextRef.current = alchemistContext;
    if (viewDataRef?.current.layout.height > 0 && layoutHeight) {
      const descriptor = onStateChange();
      if (descriptor) {
        setPageDescriptor(descriptor);
        setBSSnapPoints(
          calculateBsSnapPoints(
            bsCurrentSnapPointRef.current,
            alchemistContextRef.current.getBottomSheetHeight(),
            descriptor?.bottomSheet?.screenHeight?.min?.percentage,
            descriptor?.bottomSheet?.screenHeight?.max?.percentage,
          ),
        );
      }
    }
  }, [alchemistContext]);

  const isMapEnabled = showMap && alchemistContextRef.current.isMapActive();

  const buttonProps = {
    fontToken: FONT_TOKEN.FONT_LABEL_HIGHERCONTRAST_MEDIUM,
    margin: [20],
  };

  const defaultSnapPoint = bsCurrentSnapPointRef.current || 40;

  const onLayoutChange = (newViewData) => {
    if (viewDataRef?.current && viewDataHasChanged(newViewData, viewDataRef.current)) {
      viewDataRef.current.layout = newViewData.layout;
      viewDataRef.current.bottomsheet = newViewData.bottomsheet;
      storeData(VIEW_DATA_CACHE_KEY, {
        orderId: alchemistContextRef.current.getOrderId(),
        isDesktop: isDesktop(),
        data: viewDataRef.current,
      });

      if (newViewData?.layout?.height > 0 && layoutHeight) {
        const descriptor = onStateChange();
        if (descriptor) {
          setIsFirstPaint(false);
          setPageDescriptor(descriptor);
          setBSSnapPoints(
            calculateBsSnapPoints(
              bsCurrentSnapPointRef.current,
              alchemistContextRef.current.getBottomSheetHeight(),
              descriptor?.bottomSheet?.screenHeight?.min?.percentage,
              descriptor?.bottomSheet?.screenHeight?.max?.percentage,
            ),
          );
        }
      }
    }
  };
  let isDesktopView = !isEmpty(viewType) && viewType === 'mobile' ? false : isDesktop();

  if (pageDescriptor?.componentList?.length === 0) {
    const errorTitle = translate(messages.noDetailTitle);
    return (
      <ErrorPage
        anchoredToParent
        title={errorTitle}
        description={translate(messages.noDetailDescription)}
        IconComponent={<Image src={locationNotFoundImage} alt={errorTitle} />}
      >
        <Button onClick={reloadPage} {...buttonProps}>
          {translate(messages.retry)}
        </Button>
      </ErrorPage>
    );
  }

  return (
    <OrderStatusViewContainer
      ref={layoutRef}
      id="order-status-container"
      showHeader={hasHeader}
      headerTitle={alchemistContextRef.current.getHeaderData()?.title || ''}
      onHistoryBack={onHistoryBack}
    >
      {isDesktopView ? (
        isMapEnabled ? (
          <OSDesktopTrackingOrderView
            hasHeader={hasHeader}
            hasNotch={hasNotch}
            bsSnapPoints={bsSnapPoints}
            isNative={isNative}
            countryId={countryId}
            defaultSnapPoint={defaultSnapPoint}
            isMapEnabled={isMapEnabled}
            alchemistContext={alchemistContext}
            pageDescriptor={pageDescriptor}
            platformName={platformName}
            onLayoutChange={onLayoutChange}
          ></OSDesktopTrackingOrderView>
        ) : (
          <OSDesktopNonTrackingView
            hasHeader={hasHeader}
            hasNotch={hasNotch}
            bsSnapPoints={bsSnapPoints}
            isNative={isNative}
            countryId={countryId}
            defaultSnapPoint={defaultSnapPoint}
            isMapEnabled={isMapEnabled}
            alchemistContext={alchemistContext}
            pageDescriptor={pageDescriptor}
            platformName={platformName}
            onLayoutChange={onLayoutChange}
          />
        )
      ) : isMapEnabled ? (
        <OSMobileTrackingOrderView
          hasHeader={hasHeader}
          hasNotch={hasNotch}
          bsSnapPoints={bsSnapPoints}
          isNative={isNative}
          countryId={countryId}
          defaultSnapPoint={defaultSnapPoint}
          isMapEnabled={isMapEnabled}
          alchemistContext={alchemistContext}
          pageDescriptor={pageDescriptor}
          platformName={platformName}
          onLayoutChange={onLayoutChange}
        ></OSMobileTrackingOrderView>
      ) : (
        <OSMobileNonTrackingView
          hasHeader={hasHeader}
          hasNotch={hasNotch}
          bsSnapPoints={bsSnapPoints}
          isNative={isNative}
          countryId={countryId}
          defaultSnapPoint={defaultSnapPoint}
          isMapEnabled={isMapEnabled}
          alchemistContext={alchemistContext}
          pageDescriptor={pageDescriptor}
          platformName={platformName}
          onLayoutChange={onLayoutChange}
        />
      )}
    </OrderStatusViewContainer>
  );
};

OrderState.propTypes = {
  alchemistContext: PropTypes.object.isRequired,
  countryCode: PropTypes.string.isRequired,
  countryId: PropTypes.number.isRequired,
  platformName: PropTypes.string.isRequired,
  showMap: PropTypes.bool,
  viewType: PropTypes.string,
};

OrderState.defaultProps = {
  showMap: true,
};

export default React.memo(OrderState);
