import { ALIGNMENT, Container, FLOW_DIRECTION } from '@pedidosya/order-status-components';
import React, { forwardRef } from 'react';
import styled from 'styled-components';
const MapControlsContainer = styled(Container)`
  & > * {
    box-shadow: rgb(0 0 0 / 29%) 1px 9px 13px 1px;
  }
`;

const MapSection = forwardRef(
  (
    {
      children,
      topComponents,
      bottomComponents,
      mapBottomCoveredPercentage,
      controlComponents,
      heightPercentage,
      notchValue,
    },
    ref,
  ) => {
    const mapStyles = {
      position: 'relative',
      alignSelf: 'stretch',
      justifySelf: 'stretch',
      minHeight: `${heightPercentage || 60}%`,
      alignItems: 'center',
    };
    const mapFloatingContainerStyles = { position: 'absolute', zIndex: 2 };
    const mapTopContainerStyles = {
      ...mapFloatingContainerStyles,
      top: '0',
      padding: `${notchValue || 0} 2px 0 2px`,
    };
    const mapBottomContainerStyles = {
      ...mapFloatingContainerStyles,
      bottom: `${Math.min((mapBottomCoveredPercentage || 0) + 8, 50)}%`,
    };

    const mapControlsContainerProps = {
      margin: [32, 15, 0, 15],
      expanded: true,
    };

    return (
      <Container
        id="map-section"
        ref={ref}
        expanded
        direction={FLOW_DIRECTION.COLUMN}
        style={mapStyles}
      >
        <Container
          id="map-top-container"
          hAlignment={ALIGNMENT.CENTER}
          bgColor="transparent"
          style={mapTopContainerStyles}
          expanded
        >
          {controlComponents && (
            <MapControlsContainer
              id="map-controls-components"
              direction={FLOW_DIRECTION.ROW}
              {...mapControlsContainerProps}
            >
              {controlComponents}
            </MapControlsContainer>
          )}
          {topComponents && (
            <Container
              id="map-top-components"
              direction={FLOW_DIRECTION.COLUMN}
              hAlignment={ALIGNMENT.CENTER}
            >
              {topComponents}
            </Container>
          )}
        </Container>
        {children}
        {bottomComponents && (
          <Container
            id="map-bottom-container"
            hAlignment={ALIGNMENT.CENTER}
            bgColor="transparent"
            style={mapBottomContainerStyles}
            expanded
          >
            {bottomComponents}
          </Container>
        )}
      </Container>
    );
  },
);

MapSection.displayName = 'MapSection';

export default MapSection;
