import isObject from '@commons/utils/object/isObject';
import { getContentProp } from './content';

export function getStyles(alchemistProps) {
  return getContentProp(alchemistProps, 'styles');
}

export function getStylesProp(alchemistProps, property) {
  const styles = getStyles(alchemistProps);
  return styles ? styles[property] : null;
}

function alchemistPaddingToArray(alchemistPaddingObject, defaultValueList) {
  return alchemistPaddingObject && isObject(alchemistPaddingObject)
    ? [
        alchemistPaddingObject?.top ?? defaultValueList[0] ?? 0,
        alchemistPaddingObject?.right ?? defaultValueList[1] ?? defaultValueList[0] ?? 0,
        alchemistPaddingObject?.bottom ?? defaultValueList[2] ?? defaultValueList[0] ?? 0,
        alchemistPaddingObject?.left ?? defaultValueList[3] ?? defaultValueList[0] ?? 0,
      ]
    : defaultValueList;
}

function getObjectPropOrDefault(obj, prop, defaultVal) {
  return obj ? obj[prop] || defaultVal : defaultVal;
}

function _getPadding(styleObj, paddingProp = 'padding', defaultValue = [0, 0, 0, 0]) {
  const padding = getObjectPropOrDefault(styleObj, paddingProp, defaultValue);
  return alchemistPaddingToArray(padding, defaultValue);
}

function _getMargin(styleObj, marginProp = 'margin', defaultValue = []) {
  const margin = getObjectPropOrDefault(styleObj, marginProp, null);
  return margin
    ? [margin?.top || 0, margin?.right || 0, margin?.bottom || 0, margin?.left || 0]
    : defaultValue;
}

function _getDividerProps(styleObj, prop = 'dividers') {
  const divider = getObjectPropOrDefault(styleObj, prop, null);

  if (divider) {
    return Object.entries(divider).reduce(
      (accum, [prop, data]) => {
        accum.borderPosition.push(prop);
        if (data?.color) accum.borderColor.push(data.color);
        if (data?.height) accum.borderSize.push(data.height);
        if (data?.horizontal_padding)
          accum.padding = alchemistPaddingToArray(data.horizontal_padding, [0]);
        return accum;
      },
      { borderSize: [], borderColor: [], borderPosition: [], padding: [] },
    );
  }

  return null;
}

function _getBgColor(alchemistProps, defaultValue = 'transparent') {
  const bgColor = getContentProp(alchemistProps, 'background_color');
  return bgColor || defaultValue;
}

function _getBorderColor(alchemistProps, defaultValue = 'transparent') {
  const borderColor = getContentProp(alchemistProps, 'border_color');
  return borderColor || defaultValue;
}

export function extractStyles(alchemistProps) {
  const styles =
    getContentProp(alchemistProps, 'styles') || getContentProp(alchemistProps?.content, 'styles');
  return {
    getRawProp: (prop) => getContentProp(styles, prop),
    getMargin: (defaultValue, prop) => _getMargin(styles, prop, defaultValue),
    getPadding: (defaultValue, prop) => _getPadding(styles, prop, defaultValue),
    getDividerProps: (prop) => _getDividerProps(styles, prop),
    getBgColor: (defaultValue) => _getBgColor(alchemistProps, defaultValue),
    getBorderColor: (defaultValue) => _getBorderColor(alchemistProps, defaultValue),
    getCustomProp: (prop, defaultValue) => getObjectPropOrDefault(styles, prop, defaultValue),
  };
}
