import { isEmpty } from '@app/utils/string';
import { extractComponentIdentifiers } from '../../utils';

function getPageContent(order) {
  return order?.page_content;
}

class AlchemistContextBroker {
  constructor(order) {
    this.order = order;
    let bsBodyComponentIdList = extractComponentIdentifiers(
      this.getPageContext(this.order)?.bottom_sheet,
      'component_identifiers',
    );
    const bsDockedComponentIdList = extractComponentIdentifiers(
      this.getPageContext(this.order)?.bottom_sheet,
      'floating_component_identifiers',
    );
    const bsTopComponentIdList = extractComponentIdentifiers(
      this.getPageContext(this.order)?.bottom_sheet,
      'top_component_identifiers',
    );

    let mapTopComponentIdList = [];
    let mapBottomComponentIdList = [];
    if (this.isMapActive()) {
      mapTopComponentIdList = extractComponentIdentifiers(
        this.getPageContext(this.order)?.map,
        'top_component_identifiers',
      );

      mapBottomComponentIdList = extractComponentIdentifiers(
        this.getPageContext(this.order)?.map,
        'bottom_component_identifiers',
      );
    }

    this.componentIdList = {
      bs: {
        docked: bsDockedComponentIdList,
        top: bsTopComponentIdList,
        tabs: [],
        body: bsBodyComponentIdList,
        fixedTabBottom: [],
      },
      map: {
        top: mapTopComponentIdList,
        bottom: mapBottomComponentIdList,
      },
    };
  }

  static matchVersion(order) {
    const components = getPageContent(order)?.components;
    return components && Array.isArray(components);
  }

  getOrderId() {
    return this.order?.order_id || null;
  }

  getPageContext() {
    return getPageContent(this.order)?.context;
  }

  isOrderCancelledPageType() {
    return !isEmpty(this.order?.page_type) && this.order?.page_type === 'CANCELLED_ORDER_PAGE';
  }

  isOrderCompletedPageType() {
    return !isEmpty(this.order?.page_type) && this.order?.page_type === 'COMPLETED_ORDER_PAGE';
  }

  getPageType() {
    return this.order?.page_type || 'unknown';
  }

  isFloatingETAActive() {
    return Boolean(this.getPageContext(this.order)?.bottom_sheet?.top_component_identifiers);
  }

  hasTabs() {
    return Boolean(this.getPageContext(this.order)?.bottom_sheet?.top_tab);
  }

  isHeaderActive() {
    return !getPageContent(this.order)?.navigation_bar_behavior?.is_hidden || false;
  }

  getPageUrl() {
    return this.getPageContext(this.order)?.web_view_url || null;
  }

  getBottomSheetHeight() {
    return this.getPageContext(this.order)?.bottom_sheet?.height_percentage || 40;
  }

  getDockedComponentIdList() {
    return this.componentIdList.bs.docked;
  }

  getBottomSheetTopComponentIdList() {
    return this.componentIdList.bs.top;
  }

  getTabsComponentIdList() {
    return this.componentIdList.bs.tabs;
  }

  getTabFixedBottomComponentIdList(tabIndex) {
    if (this.getPageContext(this.order)?.bottom_sheet?.bottom_trigger_index === tabIndex) {
      return this.componentIdList.bs.fixedTabBottom;
    }

    return [];
  }

  getActiveTabIndex() {
    return this.getPageContext(this.order)?.bottom_sheet?.selected_tab?.index || 0;
  }

  getMapTopComponentIdList() {
    return this.componentIdList.map.top;
  }

  getMapBottomComponentIdList() {
    return this.componentIdList.map.bottom;
  }

  getBottomSheetComponentIdList() {
    return this.componentIdList.bs.body;
  }

  getComponentList() {
    return getPageContent(this.order)?.components || [];
  }

  isMapActive() {
    return Boolean(this.getPageContext(this.order)?.map);
  }

  isNonTrackingOrderPageType() {
    return this.order?.page_type === 'NON_TRACKING_ORDER_PAGE';
  }

  isTrackingOrderPageType() {
    return this.order?.page_type === 'TRACKING_ORDER_PAGE';
  }

  getOrder() {
    return this.order;
  }

  getHeaderData() {
    const navBar = getPageContent(this.order)?.navigation_bar_behavior;
    return {
      title: navBar?.title || '',
    };
  }
}
export default AlchemistContextBroker;
