import { isEmpty } from '@app/utils/string';
import { toFenixToken } from '@pedidosya/order-status-components';

const DEFAULT_COLOR = 'inherit';
const colorMap = {
  white: '#ffffff',
  black: '#000000',
  darkComplementary: '#585065',
  darkDefault: '#100423',
  darker3: '#002CC1',
  ratingHighest: '#268210',
  loud: '#2B1A46',
  surfaceTertiary: '#EA044E',
};

export function getColorByToken(alchemistColorToken) {
  return colorMap[alchemistColorToken];
}

/**
 * returns a mapped color ready to be used in components that support them (Text, etc)
 * @param {*} alchemistProps
 * @param {*} property
 * @returns
 */
export function getContentColor(contentElement) {
  if (!contentElement) {
    return DEFAULT_COLOR;
  }
  const colorToken =
    contentElement['typography_color'] ||
    contentElement['typographyColor'] ||
    toFenixToken(contentElement['color']);
  return colorToken && !isEmpty(colorToken)
    ? getColorByToken(colorToken) || colorToken || DEFAULT_COLOR
    : DEFAULT_COLOR;
}
