import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  Spinner,
  FLOW_DIRECTION,
  ALIGNMENT,
  useOnElementResize,
  useScreenSize,
  BottomSheet,
  COLORS,
} from '@pedidosya/order-status-components';

import { getLogger } from '@app/logger';
import ComponentGroupRenderer from '@app/alchemist/ComponentGroupRenderer';

import MapSection from '../../sections/MapSection';
import MicrositeErrorLoader from '@app/components/Microsite/MicrositeErrorLoader';

import {
  BS_FLOATING_COMPONENTS_SEPARATION_PX,
  MAP_COVERED_ZONE_PERCENTAGE,
  getNotchMargin,
  limitNumberRange,
} from '../../utils';
import { BottomSheetFloatingComponentsContainer, OSMobileLayout } from './OSMobileLayout';
import useIsFirstRender from '@app/hooks/useIsFirstRender';
import { getAlchemistContextData } from '@app/alchemist/utils/context-data/alchemist-context-data';
import styled from 'styled-components';

const MIN_MAP_MARKER_BOUNDS_PADDING = 30;
const MapComponent = React.lazy(() => {
  return import('@app/components/ui/organisms/OrderMap/OrderMap');
});

const BottomSheetContent = styled(Container).attrs(() => ({
  id: 'bs-content',
  direction: FLOW_DIRECTION.COLUMN,
  vAlignment: ALIGNMENT.START,
  hAlignment: ALIGNMENT.CENTER,
  bgColor: COLORS.WHITE,
}))`
  height: auto;
  width: 100%;
  background-color: #fff;

  & > * {
    max-width: 700px !important;
    width: 100%;
  }
`;
/**
 * these avoid recalculate the markers bounds when the eta is
 * collapsed / exnaded.
 */
const OSMobileTrackingOrderView = ({
  pageDescriptor,
  bsSnapPoints,
  defaultSnapPoint,
  alchemistContext,
  platformName,
  countryId,
  isMapEnabled,
  hasNotch,
  onLayoutChange,
  hasHeader,
  isNative,
  onBSSnapPointChanged,
}) => {
  const { isFirstRender } = useIsFirstRender();
  const { windowHeight } = useScreenSize();
  const bottomsheetDockedComponentsRef = useRef(null);
  const bottomsheetFloatingComponentsRef = useRef(null);
  const layoutRef = useRef(null);

  const calculateMarkersBoundPadding = () => {
    return [
      bottomsheetFloatingComponentsRef.current !== null
        ? bottomsheetFloatingComponentsRef.current?.offsetHeight +
          bottomsheetFloatingComponentsRef.current?.offsetTop +
          BS_FLOATING_COMPONENTS_SEPARATION_PX
        : MIN_MAP_MARKER_BOUNDS_PADDING,
      MIN_MAP_MARKER_BOUNDS_PADDING,
      MIN_MAP_MARKER_BOUNDS_PADDING,
      MIN_MAP_MARKER_BOUNDS_PADDING,
      MIN_MAP_MARKER_BOUNDS_PADDING,
    ];
  };

  const mapMarkerBoundsPaddingRef = useRef(calculateMarkersBoundPadding(pageDescriptor));
  const { height: BSFloatingComponentsHeight } = useOnElementResize(
    bottomsheetFloatingComponentsRef,
  );

  // counter used to control map padding recalculation when
  // Floating ETA is expanded to shift the map markers down only once
  const BSrecalculateTimes = useRef(0);

  const hasTopFloatingComponents =
    pageDescriptor?.bottomSheet?.topFloatingComponents &&
    pageDescriptor?.bottomSheet?.topFloatingComponents?.length > 0;
  // because in SSR is not possible to calculate the percentage
  // screen usage of this container.
  const hasDockedComponents =
    pageDescriptor?.bottomSheet?.dockedComponentIds &&
    pageDescriptor?.bottomSheet?.dockedComponentIds?.length > 0;

  const bottomSheetDockedComponentContainerStyles = { padding: [8] };

  const notchTopPadding = getNotchMargin(hasNotch, hasHeader);

  const getLayoutData = () => {
    return {
      layout: { height: limitNumberRange(layoutRef?.current?.offsetHeight || 0, 400, 900) },
      bottomsheet: {
        floatingComponents: {
          height: bottomsheetFloatingComponentsRef?.current
            ? bottomsheetFloatingComponentsRef.current.offsetTop +
              bottomsheetFloatingComponentsRef.current.offsetHeight
            : 0,
        },
        dockedComponents: {
          height: 0,
        },
      },
    };
  };

  useEffect(() => {
    if (layoutRef?.current) {
      if (pageDescriptor?.bottomSheet?.topFloatingComponents?.length > 0) {
        if (bottomsheetFloatingComponentsRef?.current !== null && BSrecalculateTimes.current < 3) {
          BSrecalculateTimes.current += 1;
          mapMarkerBoundsPaddingRef.current = calculateMarkersBoundPadding();
        }
        onLayoutChange(getLayoutData());
      } else {
        onLayoutChange(getLayoutData());
      }
    }
  }, [windowHeight, BSFloatingComponentsHeight]);

  const bsDockedComponents = hasDockedComponents ? (
    <Container
      ref={bottomsheetDockedComponentsRef}
      direction={FLOW_DIRECTION.COLUMN}
      hAlignment={ALIGNMENT.CENTER}
      padding={bottomSheetDockedComponentContainerStyles.padding}
    >
      <ComponentGroupRenderer
        componentList={pageDescriptor.componentList}
        componentIdentifiers={pageDescriptor.bottomSheet.dockedComponentIds}
      >
        {({ componentsOutput: bottomSheetFloatingChildren, error }) => {
          return error ? undefined : bottomSheetFloatingChildren;
        }}
      </ComponentGroupRenderer>
    </Container>
  ) : undefined;

  const bottomSheetContentStyles = {
    minHeight: pageDescriptor.bottomSheet.screenHeight.max.abs + 'px',
    padding: '2px',
  };
  const bottomSheetContainerStyles = {
    position: 'relative',
    width: '100%',
    minWidth: '320px',
    display: 'block',
    padding: '10px 0 0 0',
  };

  const dataContainerStyles = {
    width: '100%',
  };

  return (
    <OSMobileLayout ref={layoutRef} hasHeader={hasHeader} hasNotch={hasNotch}>
      {isMapEnabled && (
        <MapSection
          notchValue={notchTopPadding}
          heightPercentage={pageDescriptor.map.screenHeight.percentage}
          mapBottomCoveredPercentage={MAP_COVERED_ZONE_PERCENTAGE}
          topComponents={
            pageDescriptor.map.topFloatingComponentIds?.length > 0 ? (
              <ComponentGroupRenderer
                componentList={pageDescriptor.componentList}
                componentIdentifiers={pageDescriptor.map.topFloatingComponentIds}
              >
                {({ componentsOutput: mapTopChildren, error }) =>
                  error ? undefined : mapTopChildren
                }
              </ComponentGroupRenderer>
            ) : undefined
          }
          bottomComponents={
            pageDescriptor.map.bottomFloatingComponentIds?.length > 0 ? (
              <ComponentGroupRenderer
                componentList={pageDescriptor.componentList}
                componentIdentifiers={pageDescriptor.map.bottomFloatingComponentIds}
              >
                {({ componentsOutput: mapBottomChildren, error }) =>
                  error ? undefined : mapBottomChildren
                }
              </ComponentGroupRenderer>
            ) : undefined
          }
        >
          {!isFirstRender ? (
            <React.Suspense fallback={<Spinner />}>
              <MapComponent
                orderId={alchemistContext.getOrderId()}
                countryId={countryId}
                mapHeight={`${pageDescriptor.map.screenHeight.abs}px`}
                markersBoundPadding={mapMarkerBoundsPaddingRef.current}
              />
            </React.Suspense>
          ) : (
            <Spinner />
          )}
        </MapSection>
      )}
      <div id="data-container" style={dataContainerStyles}>
        {hasTopFloatingComponents && (
          <BottomSheetFloatingComponentsContainer
            ref={bottomsheetFloatingComponentsRef}
            style={{ paddingTop: notchTopPadding }}
          >
            <ComponentGroupRenderer
              componentList={pageDescriptor.componentList}
              componentIdentifiers={pageDescriptor.bottomSheet.topFloatingComponents}
            >
              {({ componentsOutput: mapTopChildren, error }) =>
                error ? undefined : mapTopChildren
              }
            </ComponentGroupRenderer>
          </BottomSheetFloatingComponentsContainer>
        )}
        <Container expanded={true} style={bottomSheetContainerStyles}>
          <BottomSheet
            staticPosition={false}
            lockDrag={!isMapEnabled}
            fullScreen={!isMapEnabled}
            considerDockedComponentsOnSnap={false}
            open={true}
            defaultSnapPointPercentage={defaultSnapPoint}
            snapPointPercentageList={bsSnapPoints}
            showOverlayAfterPercentage={80}
            dockedComponents={isMapEnabled ? bsDockedComponents : undefined}
            onSnapPointChange={onBSSnapPointChanged}
          >
            <BottomSheetContent style={bottomSheetContentStyles}>
              {!isMapEnabled && bsDockedComponents}
              <ComponentGroupRenderer
                componentList={pageDescriptor.componentList}
                renderAllIfNoIdentifiers={true}
                componentIdentifiers={pageDescriptor.bottomSheet.componentIds}
              >
                {({ componentsOutput: bottomSheetChildren, error }) => {
                  if (error) {
                    getLogger().error(
                      `[ORDER STATE][TRACKING_VIEW] ${getAlchemistContextData().getContextInfoString()} [countryId]: "${countryId}" [error]: ${error}`,
                    );
                    return <MicrositeErrorLoader />;
                  }
                  return bottomSheetChildren;
                }}
              </ComponentGroupRenderer>
            </BottomSheetContent>
          </BottomSheet>
        </Container>
      </div>
    </OSMobileLayout>
  );
};

OSMobileTrackingOrderView.propTypes = {
  onLayoutChange: PropTypes.func,
  pageDescriptor: PropTypes.shape({
    bottomSheet: PropTypes.shape({
      componentIds: PropTypes.array,
      dockedComponentIds: PropTypes.array.isRequired,
      screenHeight: PropTypes.shape({
        percentage: PropTypes.number,
      }),
    }),
    map: PropTypes.shape({
      screenHeight: PropTypes.shape({
        percentage: PropTypes.number,
      }),
    }),
    componentList: PropTypes.array.isRequired,
  }).isRequired,
  alchemistContext: PropTypes.object.isRequired,
  countryId: PropTypes.number.isRequired,
  platformName: PropTypes.string.isRequired,
  isMapEnabled: PropTypes.bool,
  bsSnapPoints: PropTypes.array.isRequired,
  defaultSnapPoint: PropTypes.number.isRequired,
};

OSMobileTrackingOrderView.defaultProps = {
  isMapEnabled: true,
  onLayoutChange: () => {},
};

export default React.memo(OSMobileTrackingOrderView);
