class ReactiveEvents {
  events = {};
  handlerIndex = {};

  ReactiveEvents() {}

  subscribe(event, handler) {
    if (!this.events[event]) {
      this.events[event] = [];
    }

    const eventList = this.events[event];
    this.handlerIndex[handler] = eventList.length;
    eventList.push(handler);
  }

  unsubscribe(event, handler) {
    if (this.events[event] && this.handlerIndex[handler]) {
      this.events[event].splice(this.handlerIndex[handler], 1);
    }
  }

  send(event, data) {
    if (this.events[event]) {
      const list = this.events[event];

      for (let i = 0; i < list.length; i++) {
        const handler = list[i];
        handler(data);
      }
    }
  }
}

const ReactiveEvent = new ReactiveEvents();

export default ReactiveEvent;
