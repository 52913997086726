export const FLAGS_COMPARE_FUNCTIONS = {
  BOOLEAN: (oldFlag, newFlag) => oldFlag.variation !== newFlag.variation,
};

export const FLAGS = {
  REDIRECT_ORDER_COMPLETED_ENABLED: {
    KEY: 'redirect-order-completed-enabled',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
    DEFAULT_VALUE: false,
  },
};

const mapFlagToKey = (flag) => flag.KEY;

export const NON_CACHEABLE_FLAGS_KEYS = Object.values(FLAGS)
  .filter((flag) => 'cacheable' in flag && flag.cacheable === false)
  .map(mapFlagToKey);

export const FLAGS_KEYS = Object.values(FLAGS).map(mapFlagToKey);
