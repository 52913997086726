import React from 'react';

import { Image } from '@pedidosya/order-status-components';

import BaseButton from '../BaseButton';
import buttonEvents from '../buttonEvents';
import { getLogger } from '@app/logger';

const PillComponent = (props) => {
  const buttonIcons = props.componentData.getImageData('image_container', { size: 20 });
  // Importante: este componente ignora los estilos de alchemist
  // tal y como hacen en apps (no tienen en cuenta paddings ni margin, son fijos)
  const buttonStyles = { minHeight: '29px' };
  const buttonPadding = [2, 10];
  const buttonMargin = [15, 0, 15, 0];

  //ACTIONS & TRACKING
  let clickEvent = null;
  const events = props?.events?.publish;
  if (events && Array.isArray(events)) {
    clickEvent = events.find((event) => event.trigger === 'CLICKED' && event.id) || null;
  }

  const onClick = () => {
    if (clickEvent) {
      const action = buttonEvents[clickEvent?.id];
      if (action) {
        action();
      } else {
        getLogger().warn(
          `PillComponent:onClick: Not mapped button event '${JSON.stringify(clickEvent)}'.`,
        );
      }
    }
  };

  return (
    <BaseButton
      componentData={props.componentData}
      alchemistProps={props}
      shadow
      padding={buttonPadding}
      margin={buttonMargin}
      style={buttonStyles}
      borderRadius={60}
      onClick={onClick}
    >
      {buttonIcons &&
        buttonIcons.hasImages() &&
        buttonIcons
          .getImageList()
          .map((image) => (
            <Image
              key={image.url}
              src={image.url}
              fallback={image.fallbackSrc}
              width={image.size}
              height={image.size}
              alt={image.alt || 'share'}
              fallbackRenderer={image.fallbackComponent}
            />
          ))}
    </BaseButton>
  );
};

PillComponent.propTypes = {};

export default PillComponent;
