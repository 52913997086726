import React from 'react';
import { ACTION_TYPE } from '@app/alchemist/utils/actions';
import crossPlatformEvents from '@app/services/event/platform/cross-platform-events';
import BasePillButton from './BasePillButton';
import { TRACKING_TRIGGERS } from '@app/tracking';
import { goToTargetUrl } from '@app/utils/url';

const DefaultPillButton = (props) => {
  const onClick = () => {
    props.componentData.getTracking().trackAllByTrigger(TRACKING_TRIGGERS.CLICKED);
    const navigateAction = props.componentData.getAction(ACTION_TYPE.NAVIGATE);
    goToTargetUrl(navigateAction, crossPlatformEvents.goToUrl);
  };

  return <BasePillButton alchemistProps={props} onClick={onClick} />;
};

DefaultPillButton.propTypes = {};

export default DefaultPillButton;
