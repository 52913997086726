import React from 'react';
import PropTypes from 'prop-types';
import { Image, ALIGNMENT, Button } from '@pedidosya/order-status-components';
import { ACTION_TRIGGER, ACTION_TYPE } from '@app/alchemist/utils/actions';
import crossPlatformEvents from '@app/services/event/platform/cross-platform-events';
import { TRACKING_TRIGGERS } from '@app/tracking';

function ImageBadgeButton(props) {
  const isEnabled = props.componentData.getContentProp('enabled', false);
  const bgColor = props.componentData.getStyles().getBgColor('transparent');
  const imgSize = 48;
  const imageData = props.componentData.getImageData('image', { dpi: 1, size: imgSize });
  const image = imageData?.hasImages() ? imageData.getFirstImage() : null;

  const padding = [0];
  const imgMargin = [0, 0, 0, 0];
  const onButtonClicked = () => {
    props.componentData.getTracking().trackAllByTrigger(TRACKING_TRIGGERS.CLICKED);

    if (props?.globals?.isNative && props?.globals?.platformKey) {
      const triggerEvent = props.componentData.getAction(ACTION_TYPE.TRIGGER);
      if (triggerEvent && triggerEvent?.event) {
        crossPlatformEvents.goToUrl(triggerEvent?.target_url, triggerEvent?.event);
      }
    } else {
      const navigateAction = props.componentData.getAction(
        ACTION_TYPE.NAVIGATE,
        ACTION_TRIGGER.CLICKED,
      );
      crossPlatformEvents.goToUrl(navigateAction?.target_url);
    }
  };

  const borderProps = {
    borderSize: [1],
    borderColor: ['transparent'],
  };

  return (
    <Button
      disabled={!isEnabled}
      bgColor={bgColor}
      padding={padding}
      vAlignment={ALIGNMENT.CENTER}
      onClick={onButtonClicked}
      {...borderProps}
    >
      {image && image?.url && (
        <Image
          src={image.url}
          fallback={image.fallbackSrc}
          width={imgSize}
          height={imgSize}
          fallbackRenderer={image.fallbackComponent}
          margin={imgMargin}
        />
      )}
    </Button>
  );
}

ImageBadgeButton.propTypes = {
  enabled: PropTypes.bool,
};

export default ImageBadgeButton;
