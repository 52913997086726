import React from 'react';
import { ALIGNMENT, Container, FLOW_DIRECTION } from '@pedidosya/order-status-components';

const LeftRightPlaceHolder = (props) => {
  // const [elements, setElements] = useState([[], []]);

  const list = Array.from(props.componentData.getChildren());
  const result = [[], []];
  for (let i = 0; i < list.length; i++) {
    result[i % 2 == 0 ? 0 : 1].push(list[i]);
  }

  const leftElements = result[0];
  const rightElements = result[1].length > 0 ? result[1] : [];

  const hasLeftItems = leftElements?.length > 0;
  const hasRightItems = rightElements?.length > 0;

  if (!hasLeftItems && !hasRightItems) {
    return null;
  }

  return (
    <Container
      direction={FLOW_DIRECTION.ROW}
      expanded
      padding={props.componentData.getStyles().getPadding([0, 16, 0, 16])}
    >
      {hasLeftItems && (
        <Container
          className="placeholder-left-children"
          direction={FLOW_DIRECTION.ROW}
          fitContent
          hAlignment={ALIGNMENT.START}
          fillSpace
          children={leftElements}
          expanded={!hasRightItems ? true : undefined}
        />
      )}
      {hasRightItems && (
        <Container
          className="placeholder-right-children"
          direction={FLOW_DIRECTION.ROW}
          fitContent
          hAlignment={ALIGNMENT.END}
          children={rightElements}
        />
      )}
    </Container>
  );
};

LeftRightPlaceHolder.displayName = 'LeftRightPlaceHolder';

export default LeftRightPlaceHolder;
