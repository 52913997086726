import React from 'react';
import PropTypes from 'prop-types';
import { usePublicEnv } from '@app/providers/PublicEnv';
import { ClickableElement, useModal } from '@pedidosya/order-status-components';
import { goToUrl } from '@app/utils/url';

const ShareableElement = ({ children, shareableData, onShareSuccess, onShareError }) => {
  const publicEnv = usePublicEnv();
  const { openModal } = useModal();
  const onSuccess = typeof onShareSuccess === 'function' ? onShareSuccess : () => {};
  const onError = typeof onShareError === 'function' ? onShareError : () => {};

  const share = () => {
    const data = typeof shareableData === 'function' ? shareableData() : shareableData;
    if (!data) {
      onError(`Invalid shareable data`);
      return;
    }
    const title = data.title || 'Compartí la url';
    if (publicEnv?.isNative) {
      //TODO: aca apps nos tiene que proporcionar un evento
      // para que le pasemos la url y puedan levantar el share nativo.
      goToUrl(data.url);
    } else if (typeof navigator?.share === 'function') {
      navigator
        .share({
          text: title,
          url: data.url,
        })
        .then(() => {
          onSuccess();
        })
        .catch((e) => {
          onError(e.message);
        });
    } else {
      openModal({
        title: title,
        content: <p style={{ userSelect: 'text' }}>{data.url}</p>,
        showFloatingCloseButton: false,
        showCloseButton: true,
      });
      onSuccess();
    }
  };

  return (
    <ClickableElement onClick={share} enabled>
      {children}
    </ClickableElement>
  );
};
ShareableElement.propTypes = {
  shareableData: PropTypes.oneOfType([
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      url: PropTypes.string,
    }),
    PropTypes.func,
  ]),
  children: PropTypes.node.isRequired,
};

export default ShareableElement;
