import SessionStorage from '@app/services/cache/SessionStorage';
const cache = new SessionStorage();
// extra percentage to render map under bottomsheet to avoid blank
// parts when dragged on the limits.
export const MAP_COVERED_ZONE_PERCENTAGE = 2;
export const HEADER_PX = 65;
export const DEFAULT_BS_NOTCH_ZONE_PX = 15;
// used to set the max-BS_NOTCH_ZONE_PX BS snap point to be expanded to.
export const BS_NOTCH_ZONE_PX = 40;
export const BS_DOCKED_COMPONENTS_TOP_MARGIN_PX = 20;
export const BS_FLOATING_COMPONENTS_SEPARATION_PX = 30;
// used to set the top margin for the layout
const LAYOUT_NOTCH_RELATIVE_VALUE = '3.6em';

export function getNotchMargin(hasNotch, hasHeader, valueIfNoActiveNotch = 0) {
  return hasNotch && !hasHeader ? LAYOUT_NOTCH_RELATIVE_VALUE : 0;
}

export function limitNumberRange(num, bottomLimit, topLimit) {
  return Math.min(topLimit, Math.max(bottomLimit, num));
}

export function pxToPercentage(totalPixels, pixelsToConvert) {
  return Number(((Math.abs(pixelsToConvert) * 100) / totalPixels).toFixed(2));
}

export function percentageToPx(totalPixels, percentageToConvert) {
  return Math.ceil((percentageToConvert * totalPixels) / 100);
}

function calculateSectionsHeightPercentage({
  initialBSPercentage,
  maxSnapPointPercentage,
  layoutHeight,
  headerPercentage,
}) {
  // this is the initial snap point were the bottom sheet spawns.
  const bottomSheetMinSnapPointPercentage = Math.min(
    initialBSPercentage,
    Math.min(100, maxSnapPointPercentage),
  );

  const mapScreenHeight =
    100 - (bottomSheetMinSnapPointPercentage + headerPercentage - MAP_COVERED_ZONE_PERCENTAGE);

  return {
    bottomSheet: {
      min: {
        abs: percentageToPx(layoutHeight, bottomSheetMinSnapPointPercentage),
        percentage: bottomSheetMinSnapPointPercentage,
      },
      max: {
        abs: percentageToPx(layoutHeight, maxSnapPointPercentage),
        percentage: maxSnapPointPercentage,
      },
    },
    map: {
      abs: (mapScreenHeight * layoutHeight) / 100,
      percentage: mapScreenHeight,
    },
  };
}

function calculateSSRSectionsHeightPercentage({ initialBSPercentage, initialBSAbsSize }) {
  return {
    bottomSheet: {
      min: {
        abs: initialBSAbsSize,
        percentage: initialBSPercentage,
      },
      max: {
        abs: initialBSAbsSize,
        percentage: initialBSPercentage,
      },
    },
    map: {
      abs: null,
      percentage: 100 - (initialBSPercentage - MAP_COVERED_ZONE_PERCENTAGE),
    },
  };
}

/**
 * @typedef {import('../../../../alchemist/utils/context-data/alchemist-context-data').default} AlchemistContextData
 */
/**
 * @typedef {Object} PageDescriptorOptions
 * @property {AlchemistContextData} alchemistContext - The alchemist context.
 * @property {boolean} isNative - Indicates if it is native.
 * @property {number} [ssrMinBSAbsHeight=200] - Minimum absolute height for SSR.
 * @property {number} [initialBSMinSnappointPercentage=20] - Initial BS minimum snappoint percentage.
 */
/**
 * Builds a page descriptor.
 * @param {PageDescriptorOptions} options - Options for building the page descriptor.
 * @returns {Object} The page descriptor.
 */
export function buildPageDescriptor({
  alchemistContext,
  isNative,
  ssrMinBSAbsHeight = 200,
  initialBSMinSnappointPercentage = 20,
  maxSnapPointPercentage,
  layoutHeight,
  hasNotch,
  hasHeader,
  bottomsheetDockedComponentScreenHeightArea,
  ssr,
}) {
  // Extract page data from alchemist.
  const dockedComponents = alchemistContext.getDockedComponentIdList();
  const bottomsheetTopComponents = alchemistContext.getBottomSheetTopComponentIdList();
  const mapTopComponents = alchemistContext.getMapTopComponentIdList();
  const initialBSPercentage = initialBSMinSnappointPercentage;

  let screnPercentages;

  if (ssr) {
    screnPercentages = calculateSSRSectionsHeightPercentage({
      initialBSPercentage,
      initialBSAbsSize: ssrMinBSAbsHeight,
    });
  } else {
    let dockedComponentsScreenPercentage = 0;
    let dockedComponentsMarginPercentage = 0;

    // the notch is calculated based on the px default +  the margin
    let notchPercentage = 0;
    // the header is calculated based on the px.
    let headerPercentage = 0;

    let floatingComponentsMarginPercentage = 0;
    if (bottomsheetTopComponents.length > 0) {
      // the extra margin is applied only if there are top floting components
      floatingComponentsMarginPercentage = pxToPercentage(
        layoutHeight,
        BS_FLOATING_COMPONENTS_SEPARATION_PX,
      );
    } else {
      // if there is floating components, is not necessary to apply the notch margin
      // only the default notch value for the native tool bar.
      notchPercentage = pxToPercentage(
        layoutHeight,
        hasNotch
          ? BS_NOTCH_ZONE_PX
          : isNative
          ? pxToPercentage(layoutHeight, DEFAULT_BS_NOTCH_ZONE_PX)
          : 0,
      );
    }

    // it should be added to the bs snapPoint limit (max snap point).
    if (hasHeader) {
      headerPercentage = pxToPercentage(layoutHeight, HEADER_PX);
    }

    if (dockedComponents?.length > 0) {
      // the extra docked component container height and the top margin
      // is applied only if there are docked components
      dockedComponentsScreenPercentage = pxToPercentage(
        layoutHeight,
        bottomsheetDockedComponentScreenHeightArea || 0,
      );

      if (floatingComponentsMarginPercentage === 0) {
        dockedComponentsMarginPercentage = pxToPercentage(
          layoutHeight,
          BS_DOCKED_COMPONENTS_TOP_MARGIN_PX,
        );
      }
    }

    const maxSnapPointTotalPercentage =
      (maxSnapPointPercentage || 100) -
      (notchPercentage +
        headerPercentage +
        (dockedComponentsScreenPercentage +
          dockedComponentsMarginPercentage +
          floatingComponentsMarginPercentage));

    screnPercentages = calculateSectionsHeightPercentage({
      headerPercentage,
      initialBSPercentage,
      maxSnapPointPercentage: maxSnapPointTotalPercentage,
      layoutHeight,
    });
  }

  return {
    bottomSheet: {
      dockedComponentIds: dockedComponents,
      topFloatingComponents: bottomsheetTopComponents,
      componentIds: alchemistContext.getBottomSheetComponentIdList(),
      screenHeight: screnPercentages.bottomSheet,
    },
    map: {
      topFloatingComponentIds: mapTopComponents,
      bottomFloatingComponentIds: alchemistContext.getMapBottomComponentIdList(),
      screenHeight: screnPercentages.map,
    },
    componentList: alchemistContext.getComponentList(),
  };
}

export function getStoredData(key, { orderId, isDesktop, defaultVal }) {
  const metadata = cache.get(key);

  if (metadata) {
    if (metadata?.orderId && metadata.orderId == orderId && metadata?.isDesktop == isDesktop) {
      return typeof metadata?.data === 'boolean' ? metadata?.data : metadata?.data || defaultVal;
    }
  }

  return defaultVal;
}

export function storeData(key, { orderId, isDesktop, data }) {
  cache.set(key, { orderId, isDesktop, data });
}
