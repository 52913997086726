import getInstance from '@app/services/axios';
import { customMetricsUrl } from '@commons/services/urls/serverServicesURLs';
import { isWindowObjectDefined } from '@pedidosya/order-status-components';

export const METRICS = {
  FALLBACK_COMPONENT_SHOWN: 'FALLBACK_COMPONENT_SHOWN',
};

const metricsMap = new Map();

export const initMetricsService = () => {
  metricsMap.clear();
};

export function sendMetricEvent(event, sendOncePerRefresh = false) {
  if (isWindowObjectDefined()) {
    if (sendOncePerRefresh && metricsMap.has(event)) {
      return;
    }
    metricsMap.set(event, true);
    getInstance()
      .post(customMetricsUrl, {
        event,
      })
      .catch((e) => console.error(`Error sending metric for event '${event}'`, e)); // ALWAYS use a catch here and avoid side effects for the user.
  }
}
