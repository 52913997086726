/**
 * @description Method to remove special chars from the string
 * @author Martin Pastorino 10/07/2018
 * @param {String} str Value to be processed to replace strange chars.
 */
export default function removeSpecialChars(str) {
  return str
    .replace(/[àáâãäå]/gi, 'a')
    .replace(/[èéêë]/gi, 'e')
    .replace(/[ìíîï]/gi, 'i')
    .replace(/[òóôõö]/gi, 'o')
    .replace(/[ùúûü]/gi, 'u')
    .replace(/[ñ]/gi, 'n')
    .replace(/[ç]/gi, 'c')
    .replace(/[\'`´\"]/gi, ' ')
    .replace(/[\(\)]/gi, '');
}
