import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import MicrositeWrapper from '@app/components/Microsite/MicrositeWrapper';
import { getPlaform } from '@commons/utils/platform';
import RootProviders from '@app/root.providers.component';
import AlchemistContext from './AlchemistContext';
import getRegistry from '@app/pages/OrderStatus/alchemist-component-registry';
import { getLogger } from '@app/logger';

export const Wrapper = ({ id, style, children, html, ...rest }) => {
  if (html) {
    return <div id={id} style={style} {...rest} dangerouslySetInnerHTML={{ __html: html }}></div>;
  }
  return (
    <div id={id} style={style} {...rest}>
      {children}
    </div>
  );
};

function _render() {
  const { children, fallback, publicEnv, id, globals, style } = this.props;
  try {
    const html = renderToStaticMarkup(
      <MicrositeWrapper device={publicEnv?.context?.device} locale={publicEnv?.context?.locale}>
        <RootProviders publicEnv={publicEnv}>
          <AlchemistContext
            registry={getRegistry({
              publicEnv: publicEnv,
              globals,
            })}
            tracker={publicEnv?.externalServices?.tracker}
            globals={globals}
          >
            {children}
          </AlchemistContext>
        </RootProviders>
      </MicrositeWrapper>,
    );
    return <Wrapper id={id} style={style} html={html} />;
  } catch (e) {
    return (
      <Wrapper id={id} style={style}>
        {fallback()}
      </Wrapper>
    );
  }
}
class AlchemistErrorBoundary extends React.Component {
  state = {
    hasError: false,
  };

  static defaultProps = {
    publicEnv: {},
    globals: {},
    fallback: () => null,
    id: 'unknown',
    style: {},
  };

  componentDidCatch(error, errorInfo) {
    // set an error once the component catches an error.
    this.setState({ hasError: true });
    getLogger().warn(`AlchemistErrorBoundary: ${this.props.id}: ${error}, ${errorInfo}`);
  }

  render() {
    if (this.props.ssr) {
      return _render.apply(this, null);
    } else {
      return (
        <Wrapper id={this.props.id} style={this.props.style}>
          {this.state.hasError ? this.props.fallback() : this.props.children}
        </Wrapper>
      );
    }
  }
}

export default AlchemistErrorBoundary;
