import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  Text,
  Image,
  Container,
  ALIGNMENT,
  FLOW_DIRECTION,
  FONT_TOKEN,
} from '@pedidosya/order-status-components';
import errorImage from '@app/assets/icons/error.svg';

const ErrorWrapper = styled(Container)`
  position: ${({ anchoredToParent }) => (anchoredToParent ? 'relative' : 'absolute')};
  width: 100%;
  min-height: ${({ contentHeight }) => (contentHeight ? 'auto' : '100vh')};
  background-color: #fff;
  padding: 20px;
`;

const ErrorTitle = styled(Text)`
  margin: 12px 0;
  text-align: center;
`;
const ErrorDescription = styled(Text)`
  margin: 6px 0;
  max-width: 400px;
  text-align: center;
`;
const ErrorExtra = styled.div`
  margin: 12px 0;
`;

const ErrorPage = ({
  title,
  description,
  IconComponent,
  children,
  anchoredToParent,
  contentHeight,
}) => {
  const titleProps = {
    fontToken: FONT_TOKEN.FONT_HEADLINE_HIGHCONTRAST_SMALL,
  };
  const descriptionProps = {
    fontToken: FONT_TOKEN.FONT_BODY_MIDCONTRAST_SENTENCE_LARGE,
  };

  return (
    <ErrorWrapper
      anchoredToParent={anchoredToParent}
      contentHeight={contentHeight}
      direction={FLOW_DIRECTION.COLUMN}
      vAlignment={ALIGNMENT.CENTER}
      hAlignment={ALIGNMENT.CENTER}
    >
      {IconComponent ? IconComponent : <Image src={errorImage} alt={description} />}
      {title && <ErrorTitle {...titleProps}>{title}</ErrorTitle>}
      {description && <ErrorDescription {...descriptionProps}>{description}</ErrorDescription>}
      <ErrorExtra>{children}</ErrorExtra>
    </ErrorWrapper>
  );
};

ErrorPage.propTypes = {
  IconComponent: PropTypes.object,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  children: PropTypes.node,
  anchoredToParent: PropTypes.bool,
  contentHeight: PropTypes.bool,
};

ErrorPage.defaultProps = {
  anchoredToParent: false,
  contentHeight: false,
};

export default ErrorPage;
