import React from 'react';
import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import { SSRProvider, ThemeProvider } from '@pedidosya/order-status-components';
import I18nProvider from '@app/providers/I18nProvider';
import GlobalStyle from './GlobalStyle';
import CrossDeviceRoutesProvider from '@app/providers/CrossDeviceRoutesProvider';
import ErrorBoundary from './ErrorBoundary';

const MicrositeWrapper = ({ children, locale, messages, device, platform, hasNotch }) => {
  return (
    <ThemeProvider platform={platform} injectFonts={true}>
      <Sentry.ErrorBoundary
        fallback={({ error, componentStack }) => (
          <ErrorBoundary error={error} componentStack={componentStack} />
        )}
      >
        <div id="microsite-wrapper">
          <I18nProvider locale={locale} messages={messages}>
            <GlobalStyle />
            <SSRProvider>
              <CrossDeviceRoutesProvider device={device}>{children}</CrossDeviceRoutesProvider>
            </SSRProvider>
          </I18nProvider>
        </div>
      </Sentry.ErrorBoundary>
    </ThemeProvider>
  );
};

MicrositeWrapper.propTypes = {
  locale: PropTypes.string,
  device: PropTypes.string,
  messages: PropTypes.object,
  platform: PropTypes.string,
};

MicrositeWrapper.defaultProps = {
  locale: 'es',
  messages: {},
  device: 'WEBVIEW',
  platform: 'ios',
};

export default MicrositeWrapper;
