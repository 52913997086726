const { isEmpty } = require('@app/utils/string');

/**
 * Elimina el punto del country shortname en caso de estar presente
 * @param {string} countryShortName
 * @returns {string}
 */
function normalizeCountryShortName(countryShortName) {
  return isEmpty(countryShortName) ? '' : countryShortName.trim().replace(/\./g, '').toLowerCase();
}

module.exports = { normalizeCountryShortName };
