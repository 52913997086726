import { isEmpty } from '@app/utils/string';

export const getDefaultPlatformEventContext = () => ({
  country: { id: 0, shortName: '' },
  platformKey: 'web',
});

export const isValidPlatformEventContext = (context) => {
  return (
    context &&
    context?.country?.id &&
    !isNaN(Number(context?.country?.id)) &&
    context?.country?.shortName &&
    !isEmpty(context?.country?.shortName) &&
    !isEmpty(context?.platformKey)
  );
};
