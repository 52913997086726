import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { textPropTypes } from '@app/alchemist/types/props';
import {
  ALIGNMENT,
  Button,
  Container,
  FLOW_DIRECTION,
  HorizontalSeparator,
  Stepper,
  Text,
  Toast,
  CircleCheck,
} from '@pedidosya/order-status-components';
import SimpleDetailLayout from '../ImageDetails/SimpleDetailLayout';
import { createTips, updateTips } from '@app/services/tipsService';
import { ACTION_TRIGGER, ACTION_TYPE } from '@app/alchemist/utils/actions';

import SessionStorage from '@app/services/cache/SessionStorage';
import isObject from '@commons/utils/object/isObject';
import { isEmpty } from '@app/utils/string';

const ACTION_STATE = {
  IDLE: 'IDLE',
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
};

const TIPS_CACHE_KEY = 'tips:status';
const cache = new SessionStorage();

function Tips(props) {
  const titleProps = props.componentData.getTextProps('title');
  const enabled = Boolean(props.componentData.getContentProp('enabled', false));
  const options = props.componentData.getContentProp('selectable_values', []);
  const configurationId =
    props.componentData.getContentProp('configuration_id') ||
    props.componentData.getContentProp('configurationId');
  const actualValueObj = props.componentData.getContentProp('actual_value', null);
  const selectedCheckoutValue = actualValueObj?.value || null;

  const successAction = props.componentData.getAction(
    ACTION_TYPE.SHOW_SNACKBAR,
    ACTION_TRIGGER.SUCCEEDED,
  );
  let successRequestMessageProps = {};
  if (successAction) {
    successRequestMessageProps = props.componentData.getTextPropsFrom(successAction, 'message');
  }

  let failedRequestMessageProps = {};
  const failedAction = props.componentData.getAction(
    ACTION_TYPE.SHOW_SNACKBAR,
    ACTION_TRIGGER.FAILED,
  );
  if (failedAction) {
    failedRequestMessageProps = props.componentData.getTextPropsFrom(failedAction, 'message');
  }

  const tipExists = (prevTipsData) => {
    return (
      Boolean(isObject(prevTipsData) && prevTipsData?.amount && prevTipsData?.configurationId) &&
      !isEmpty(prevTipsData?.configurationId)
    );
  };

  const loadTipData = () => {
    const savedTips = cache.get(TIPS_CACHE_KEY);
    if (
      tipExists(savedTips?.tipsData) &&
      (props === undefined ||
        (savedTips?.tipsData?.orderId === props?.globals?.alchemistContext?.getOrderId() &&
          (!selectedCheckoutValue ||
            selectedCheckoutValue == 0 ||
            selectedCheckoutValue == savedTips?.prevValue) &&
          savedTips?.tipsData?.amount > 0))
    ) {
      return savedTips.tipsData;
    } else {
      return {
        orderId: props?.globals?.alchemistContext?.getOrderId(),
        amount: selectedCheckoutValue,
        configurationId,
        countryId: props?.globals?.countryId,
      };
    }
  };
  const tipRef = useRef(loadTipData());

  const isValidOptionValue = (value) =>
    value != null && !Number.isNaN(Number(value)) && Number(value) > 0;

  const [editable, setEditable] = useState(!isValidOptionValue(tipRef.current.amount));
  const [actionState, setActionState] = useState(ACTION_STATE.IDLE);

  let buttonProps;

  const confirmButtonProps = props.componentData.getTextProps('confirm_button_title');
  const editButtonProps = props.componentData.getTextProps('edit_button_title');

  const editButtonClick = () => {
    setEditable(true);
    props.componentData.getTracking().trackById('tip_clicked');
  };

  const closeMessagoBox = () => {
    setActionState(ACTION_STATE.IDLE);
  };

  const confirmButtonClick = () => {
    if (!isValidOptionValue(tipRef.current.amount)) {
      return;
    }
    let prevTips = cache.get(TIPS_CACHE_KEY);

    let prevTipsExists =
      tipExists(prevTips?.tipsData) &&
      prevTips?.tipsData?.orderId === props?.globals?.alchemistContext?.getOrderId();

    let service = null;
    const checkoutValue = Number(selectedCheckoutValue);
    // si el tip fue guardado en cache previamente
    if (prevTipsExists) {
      // si esta intentando guardar un valor ya guardado (== actual)
      if (
        tipRef.current.amount === prevTips?.tipsData?.amount &&
        tipRef.current.configurationId === configurationId
      ) {
        setEditable(false);
        return;
      }

      service = updateTips;
    } else if (selectedCheckoutValue && !Number.isNaN(checkoutValue) && checkoutValue >= 0) {
      // si esta intentando guardar un valor igual, se evita
      if (tipRef.current.amount === checkoutValue) {
        setEditable(false);
        return;
      }
      // si no existe un tips guardado en cache pero el valor actual del backend
      // es >= 0 entonces es que existe el tip previo.
      service = updateTips;
    } else {
      service = createTips;
    }

    const body = { ...tipRef.current };

    setActionState(ACTION_STATE.PENDING);
    service(tipRef.current)
      .then(() => {
        setActionState(ACTION_STATE.SUCCESS);
        setEditable(false);
        tipRef.current = body;
        cache.set(TIPS_CACHE_KEY, {
          tipsData: tipRef.current,
          lastUpdate: props?.globals?.order?.lastUpdate,
          prevValue: selectedCheckoutValue,
        });
        props.componentData.getTracking().trackById('tip_updated');
      })
      .catch((e) => {
        setActionState(ACTION_STATE.FAILED);
        setEditable(true);
      });
  };

  let stepperStyleProps = {
    margin: [8, 0, 0, 0],
  };
  let buttonClickHandler;

  if (editable) {
    buttonProps = confirmButtonProps;
    buttonClickHandler = confirmButtonClick;
  } else {
    buttonProps = editButtonProps;
    buttonClickHandler = editButtonClick;
    // readonly styles
    stepperStyleProps.bgColor = '#EFEDF0';
    stepperStyleProps.borderSize = [0];
  }

  const onTipValueChange = ({ value }) => {
    tipRef.current = {
      orderId: props?.globals?.alchemistContext?.getOrderId(),
      amount: value,
      configurationId,
      countryId: props?.globals?.countryId,
    };
  };

  const dividerProps = props.componentData.getStyles().getDividerProps();

  const padding = [16, 24];

  const TitleComponent = <Text {...titleProps}>{titleProps.text}</Text>;
  const SubtitleComponent =
    options?.length > 0 ? (
      <Stepper
        readonly={!editable}
        initialValue={tipRef.current.amount}
        currencySymbol={'$'}
        options={options}
        disabled={!enabled || actionState !== ACTION_STATE.IDLE}
        onValueChange={onTipValueChange}
        {...stepperStyleProps}
      />
    ) : undefined;

  let DividerComponent = dividerProps ? (
    <Container expanded padding={dividerProps?.padding}>
      <HorizontalSeparator
        color={dividerProps?.borderColor?.length > 0 ? dividerProps.borderColor[0] : undefined}
        height={dividerProps?.height}
      />
    </Container>
  ) : undefined;

  let toastMessagProps = null;
  let toastBgColor = '#FFF';
  if (actionState === ACTION_STATE.SUCCESS) {
    toastMessagProps = successRequestMessageProps;
    toastBgColor = '#60F6C0';
  } else if (actionState === ACTION_STATE.FAILED) {
    toastMessagProps = failedRequestMessageProps;
    toastBgColor = '#fb9986';
  }

  return (
    <div style={{ position: 'relative' }}>
      {toastMessagProps && toastMessagProps?.text && (
        <Toast
          data-testid="tips-toast"
          bgColor={toastBgColor}
          onClose={closeMessagoBox}
          expanded
          showCloseButton={true}
          padding={[16]}
          borderRadius={4}
          style={{ position: 'absolute', height: '91%', margin: '9px 0', zIndex: 1 }}
        >
          <Container direction={FLOW_DIRECTION.ROW} vAlignment={ALIGNMENT.CENTER} fitContent>
            <div style={{ padding: '14px' }}>
              <CircleCheck filled />
            </div>
            <Text {...toastMessagProps} padding={[0, 40, 0, 0]}>
              {toastMessagProps?.text}
            </Text>
          </Container>
        </Toast>
      )}
      <SimpleDetailLayout
        padding={padding}
        titleComponent={TitleComponent}
        subtitleComponent={SubtitleComponent}
        dividerComponent={DividerComponent}
        style={{ minHeight: '96px' }}
      >
        <Button
          bgColor={'transparent'}
          onClick={buttonClickHandler}
          disabled={actionState !== ACTION_STATE.IDLE || !enabled}
        >
          <Text {...buttonProps}>{buttonProps.text}</Text>
        </Button>
      </SimpleDetailLayout>
    </div>
  );
}

Tips.propTypes = {};

export default Tips;
