import { getLogger } from '@app/logger';
import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';

export const setupSentry = (environment) => {
  const sentryConfig = {
    dsn: 'https://144831f3e21a67eb2f8eac4afcad6051@o4504046939799552.ingest.us.sentry.io/4507176790261760',
    isEnabled: environment !== 'local',
    environment,
  };

  async function initializeSentry() {
    try {
      await initSentry(sentryConfig);
    } catch (error) {
      getLogger().warn('Error initializing sentry hub', error.message);
    }
  }
  initializeSentry();
};

async function initSentry(sentryConfig) {
  if (!sentryConfig) return null;
  const { dsn, isEnabled, environment } = sentryConfig;

  if (isEnabled) {
    try {
      Sentry.init({
        dsn,
        integrations: [new Integrations.BrowserTracing()],
        environment,
        // eslint-disable-next-line no-undef
        release: `${APP_NAME}@${APP_VERSION}`,
        tracesSampleRate: 0.1,
      });
    } catch (error) {
      getLogger().warn(`Sentry not initialized, error: ${error.message}`);
    }
  }
}

export default initSentry;
