const TRACKING_TRIGGERS = {
  MODAL: {
    DELIVERY_TEXT: 'DeliveryText',
    ACTION: 'close',
    CLICK_LOCATION: 'button',
  },
  SWIPED: 'SWIPED',
  LOADED: 'LOADED',
  CLICKED: 'CLICKED',
};

export default TRACKING_TRIGGERS;
