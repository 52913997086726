/**
 *
 * @param {import("./alchemist-context-data").BottomSheet} contextObj
 * @param {string} prop
 * @returns {string[]}
 */
export function extractComponentIdentifiers(contextObj, prop) {
  if (contextObj && contextObj[prop]) {
    const identifiers = contextObj[prop];
    return Array.isArray(identifiers) ? identifiers : [];
  }
  return [];
}
