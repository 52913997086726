import React from 'react';
import { FLOW_DIRECTION, Image, ImageList } from '@pedidosya/order-status-components';

const MAX_VISIBLE_PRODUCT_IMAGES = 3;

function getProductImagesInformation(alchemistProps) {
  let imagesCounterProps = {};
  const imagesContainer = alchemistProps.componentData.getImageData('images_container');
  if (imagesContainer) {
    imagesCounterProps = imagesContainer.getImageContainerTextProps('remaining_counter');
  }

  return { productImages: imagesContainer?.getImageList() ?? [], imagesCounterProps };
}

function ProductImageList(props) {
  let productImageInfo = getProductImagesInformation(props);
  const imageListStyle = { width: 'max-content' };
  const productImagesProps = {
    borderSize: [1],
    borderColor: ['#FFF'],
    borderRadius: 8,
    width: 40,
    height: 40,
  };
  return productImageInfo?.productImages?.length > 0 ? (
    <ImageList
      data-testid="product-images-container"
      style={imageListStyle}
      overlapped
      imageList={productImageInfo.productImages}
      maxVisibleImages={MAX_VISIBLE_PRODUCT_IMAGES}
      imageCounterLabel={productImageInfo?.imagesCounterProps?.text}
      {...productImageInfo?.imagesCounterProps}
      direction={FLOW_DIRECTION.ROW}
      fitContent
      imageRenderer={(image) => {
        return (
          <li key={image.id}>
            <Image
              {...productImagesProps}
              src={image.url}
              fallback={image.fallbackSrc}
              fallbackRenderer={image.fallbackComponent}
            />
          </li>
        );
      }}
    />
  ) : undefined;
}

export default ProductImageList;
